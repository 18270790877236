import { AppElement, AppFC } from '../../../interfaces';
import { StepsProps } from './types';

import { Step } from '../../Atoms';

export const Steps: AppFC<StepsProps> = ({
  stepCount,
  currentStep,
}): AppElement => {
  const steps = Array.from({ length: stepCount });
  return (
    <div className="flex items-center gap-2">
      {steps.map((step, index) => (
        <>
          {index > 0 && <div className={`w-4 h-[1px] ${index <= currentStep - 1 ? 'bg-primary' : 'bg-[#202737]'}`} />}
          <Step
            stepNumber={index + 1}
            completed={index < currentStep - 1}
            isCurrent={index === currentStep - 1}
          />
        </>
      ))}
    </div>
  );
};
