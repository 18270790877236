import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const CloseIcon: AppFC<HTMLAttributes<HTMLDivElement> & { size?: string }> = ({ size, className, ...rest }): AppElement => (
  <div className={`${className} cursor-pointer ml-2`} {...rest}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none">
      <path d="M6 18L18 6M6 6L18 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);
