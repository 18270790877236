import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const AttachmentIcon: AppFC<HTMLAttributes<HTMLDivElement>> = (props): AppElement => (
  <div {...props}>
    <svg className="w-full h-full" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.4375 14.8625L12.4624 23.8376C11.4778 24.8222 10.1424 25.3753 8.75003 25.3753C7.35764 25.3753 6.02227 24.8222 5.0377 23.8376C4.05313 22.8531 3.5 21.5177 3.5 20.1253C3.5 18.7329 4.05313 17.3975 5.0377 16.413L17.801 3.64963C18.1261 3.32467 18.5121 3.06694 18.9368 2.89113C19.3615 2.71532 19.8167 2.62489 20.2764 2.625C20.736 2.62511 21.1912 2.71575 21.6158 2.89176C22.0404 3.06777 22.4262 3.32569 22.7512 3.6508C23.0762 3.97591 23.3339 4.36184 23.5097 4.78655C23.6855 5.21127 23.7759 5.66646 23.7758 6.12612C23.7757 6.58579 23.6851 7.04093 23.5091 7.46556C23.3331 7.8902 23.0751 8.27601 22.75 8.60096L9.97737 21.3736C9.64601 21.6913 9.20312 21.8649 8.74414 21.8591C8.28515 21.8532 7.84683 21.6673 7.52363 21.3414C7.20043 21.0154 7.01824 20.5755 7.01632 20.1165C7.0144 19.6575 7.19291 19.2161 7.51337 18.8875L16.625 9.7758M9.98787 21.362L9.9762 21.3736" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);
