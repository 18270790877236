import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAuth } from '../../../../stores/hooks';

import { AppElement, AppFC } from '../../../../interfaces';
import { SignInProps } from './types';
import {
  AlertCategory,
  AuthPaths,
  ButtonCategory,
  Paths,
} from '../../../../constants';

import {
  Button,
  Alert,
} from '../../../Atoms';
import { FormInput } from '../../../Molecules';
import { generateErrorAlert } from '../../helpers';
import { checkString } from '../../../../utils';
import { SignInDataProps } from '../../../../services/httpRequest';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter your email or username'),
  password: yup
    .string()
    .required('Please enter your password'),
});

export const SignIn: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();

  const { alertMessage, emailAddress = '' } = location.state || {};

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: emailAddress as string || '',
      password: '',
    },
  });

  console.log('location.state', location.state);
  useEffect(() => {
    if (alertMessage) {
      setAlert(
        <Alert
          category={AlertCategory.Success}
          title={alertMessage}
        />,
      );
      setTimeout(() => {
        setAlert(null);
        navigate(location.pathname, { state: { emailAddress } });
      }, 7000);
    } else {
      setAlert(null);
    }
  }, []);

  const userEmail = useWatch({
    control,
    name: 'email',
    defaultValue: emailAddress as string || '', // default value before the render
  });
  const onSubmit: SubmitHandler<SignInProps> = (data) => {
    setIsLoading(true);
    const { email, password } = data;
    let newData = data as SignInDataProps;
    if (!checkString('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$', userEmail)) {
      newData = { userName: email, password };
    }
    signIn(newData)
      .then(() => {
        setIsLoading(false);
        navigate(Paths.PersonalInfo);
      })
      .catch((reason) => {
        setIsLoading(false);
        generateErrorAlert(reason, 'Sign in failed!', setAlert);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 h-full justify-center">
      {alert}
      <h4>Log in</h4>
      <div className="flex flex-col gap-6 justify-center w-full">
        <FormInput
          label="Email or Username"
          name="email"
          control={control}
          placeholder="Enter email or username"
          error={errors.email}
        />
        <FormInput
          label="Password"
          name="password"
          type="password"
          control={control}
          placeholder="Enter password"
          error={errors.password}
        />
        <NavLink
          to={AuthPaths.ForgotPassword}
          state={{ emailAddress: userEmail }}
          className="underline text-tertiary"
        >
          Forgot password?
        </NavLink>
      </div>
      <Button
        className="w-[200px]"
        type="submit"
        category={ButtonCategory.Filled}
        isLoading={isLoading}
      >
        Log in
      </Button>
      <div className="flex items-center gap-2 text-disabled">
        <span>Don&apos;t have an account?</span>
        <NavLink to={AuthPaths.SignUp} className="underline text-tertiary">
          Sign up
        </NavLink>
      </div>
    </form>

  );
};
