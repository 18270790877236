import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { OffersUpdateDataProps } from '../../../../services/httpRequest';

import {
  OfferContext,
  PaymentContext,
  ViewContext,
  ViewModalActionTypes,
} from '../../../../stores/contexts';
import { useOffer } from '../../../../stores/hooks';

import { AppElement, AppFC, OfferInterface } from '../../../../interfaces';
import { OfferDataProps } from './types';
import {
  createOfferOptions,
  // currencyMarketOptions,
  currencyOptions,
  tradeTimes,
  ButtonCategory,
  CryptoCurrencyTypes,
  MarketTypes,
  OfferStatusTypes,
  OfferTypes,
  Paths, PaymentMethodTypes,
} from '../../../../constants';
import {
  getCountries, getCurrencies, getPaymentMethodOptions, isEmpty,
} from '../../../../utils';

import {
  FormInput,
  FormPercentInput,
  FormSearchSelect,
  // FormSelect,
  FormSwitch,
  FormTextArea,
} from '../../../Molecules';
import { Button, Line } from '../../../Atoms';

import percentIcon from '../../../../assets/icons/percent.svg';
import countries from '../../../../utils/countries.json';

const validationSchema = yup.object().shape({
  offerType: yup.string().oneOf(Object.values(OfferTypes)).required('Offer type is required.'),
  offeredCurrency: yup.string().oneOf(Object.values(CryptoCurrencyTypes)).required('Offered currency is required.'),
  demandedCurrency: yup.string().required('Demanded currency is required.'),
  country: yup.string().required('Country is required.'),
  tradeTimeMinutes: yup.number().required('Trade time is required.'),
  paymentMethodTypes: yup.string().required('At least one payment method need to be selected.'),
  currencyReferenceMarket: yup.string().required('Reference market is required.'),
  offerMargin: yup.number().transform((value, originalValue) => (originalValue === '' ? undefined : value)).typeError('Margin must be a number.').min(0)
    .required('Margin for the offer is required.'),
  marketType: yup.string().required(),
  minTradeSize: yup.number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .typeError('Margin must be a number.')
    .nullable()
    .min(0)
    .test(
      'is-number-length-valid',
      'Min. trade size must have at most 15 digits',
      (value) => {
        const stringValue = String(value);
        return stringValue.length <= 15;
      },
    ),
  maxTradeSize: yup.number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .typeError('Margin must be a number.').nullable()
    .min(yup.ref('minTradeSize'))
    .test(
      'is-number-length-valid',
      'Max. trade size must have at most 15 digits',
      (value) => {
        const stringValue = String(value);
        return stringValue.length <= 15;
      },
    ),
  description: yup.string(),
});

export const OfferForm: AppFC<{ offer?: OfferInterface }> = ({ offer }): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [draftIsLoading, setDraftIsLoading] = useState(false);

  const { paymentState: { methods = [] } } = useContext(PaymentContext);
  const { updateViewModal } = useContext(ViewContext);
  const { offerState: { filters: { country: currentCountry, currencyCode } } } = useContext(OfferContext);
  const {
    createOffer,
    publishDraftOffer,
    updateDraftOffer,
  } = useOffer();
  const navigate = useNavigate();

  const getPaymentMethods = (methodType: PaymentMethodTypes) => methods.filter(({ type }) => type === methodType);
  const methodsBlocks = [
    {
      label: 'Bank Transfer',
      methods: getPaymentMethods(PaymentMethodTypes.BankTransfer),
    },
    {
      label: 'Cash Payments',
      methods: getPaymentMethods(PaymentMethodTypes.CashPayment),
    },
    {
      label: 'Online Wallets',
      methods: getPaymentMethods(PaymentMethodTypes.OnlineWallet),
    },

    {
      label: 'Prepaid Gift Card',
      methods: getPaymentMethods(PaymentMethodTypes.PrepaidGiftCard),
    },
    {
      label: 'Retails Gift Card',
      methods: getPaymentMethods(PaymentMethodTypes.RetailsGiftCard),
    },
  ];

  const {
    control,
    handleSubmit,
    formState: {
      errors,
      defaultValues,
      dirtyFields,
    },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      offerType: offer?.offerType || OfferTypes.Buy,
      offeredCurrency: offer?.offeredCurrency || CryptoCurrencyTypes.BTC,
      demandedCurrency: countries.find(({ name, currency }) => currency.code + currency.name + name === offer?.demandedCurrency)?.currency.code || currencyCode || '',
      country: offer?.country || currentCountry || '',
      offerMargin: Math.abs(offer?.margin as number) || 0,
      paymentMethodTypes: offer?.paymentMethodTypes[0] || '',
      tradeTimeMinutes: offer?.tradeTimeMinutes || 30,
      marketType: Number(offer?.margin) < 0 ? MarketTypes.Below : MarketTypes.Above,
      currencyReferenceMarket: 'Coinbase',
      minTradeSize: offer?.minTrade,
      maxTradeSize: offer?.maxTrade,
      description: offer?.description || '',
    },
  });

  const currentOfferType = watch('offerType');
  const currentCurrency = watch('demandedCurrency') || offer?.demandedCurrency;

  const currentDemandedCurrency = countries.find(({ name, currency }) => currency.code + currency.name + name === currentCurrency)?.currency.code || '';

  const handlePublishOffer = (id: string) => {
    publishDraftOffer(id)
      .then(() => {
        setIsLoading(false);
        navigate(Paths.MyOffers);
        updateViewModal({
          type: ViewModalActionTypes.SuccessModal,
          payload: {
            description: 'Offer published successfully!',
          },
        });
      })
      .catch((reason) => {
        setIsLoading(false);
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Offer publish failed.',
            description: reason.detail,
          },
        });
      });
  };

  const onSubmit: SubmitHandler<OfferDataProps> = (data, event) => {
    const isDirty = !isEmpty(dirtyFields);
    const isDraft = (event?.nativeEvent as any).submitter.name === 'draft';

    const {
      offerType,
      offeredCurrency,
      demandedCurrency,
      currencyReferenceMarket,
      minTradeSize,
      maxTradeSize,
      offerMargin,
      country,
      tradeTimeMinutes,
      paymentMethodTypes,
      marketType,
      description,
    } = data;

    const newData: OffersUpdateDataProps = {
      offerStatus: isDraft ? OfferStatusTypes.Draft : OfferStatusTypes.Open,
      offerType: offerType === OfferTypes.Buy ? OfferTypes.Sell : OfferTypes.Buy,
      offeredCurrency,
      demandedCurrency: countries.find(({ name, currency }) => currency.code + currency.name + name === demandedCurrency)?.currency.code || '',
      currencyReferenceMarket,
      minTrade: Number(minTradeSize),
      maxTrade: Number(maxTradeSize),
      margin: marketType === MarketTypes.Above ? offerMargin : -offerMargin,
      country,
      tradeTimeMinutes,
      paymentMethodTypes: [paymentMethodTypes],
      description,
    };

    if (offer) {
      delete newData.offerStatus;

      if (isDraft) {
        if (isDirty) {
          setDraftIsLoading(true);
          updateDraftOffer(newData, offer.id)
            .then(() => {
              setDraftIsLoading(false);
              navigate(Paths.MyOffers);
              updateViewModal({
                type: ViewModalActionTypes.SuccessModal,
                payload: {
                  description: 'Offer updated successfully!',
                },
              });
            })
            .catch((reason) => {
              setDraftIsLoading(false);
              updateViewModal({
                type: ViewModalActionTypes.FailModal,
                payload: {
                  title: 'Offer update failed.',
                  description: reason.detail,
                },
              });
            });
        }
      } else if (isDirty) {
        setIsLoading(true);
        updateDraftOffer(newData, offer.id)
          .then(() => {
            handlePublishOffer(offer.id);
          })
          .catch((reason) => {
            setIsLoading(false);
            updateViewModal({
              type: ViewModalActionTypes.FailModal,
              payload: {
                title: 'Offer update failed.',
                description: reason.detail,
              },
            });
          });
      } else {
        setIsLoading(true);
        handlePublishOffer(offer.id);
      }
    } else {
      createOffer(newData)
        .then(() => {
          isDraft ? setDraftIsLoading(false) : setIsLoading(false);
          navigate(Paths.MyOffers);
          updateViewModal({
            type: ViewModalActionTypes.SuccessModal,
            payload: {
              description: `Offer ${isDraft ? 'saved as draft.' : 'created successfully!'}`,
            },
          });
        })
        .catch((reason) => {
          isDraft ? setDraftIsLoading(false) : setIsLoading(false);
          updateViewModal({
            type: ViewModalActionTypes.FailModal,
            payload: {
              title: `Offer ${isDraft ? 'saved as draft.' : 'create'} failed.`,
              description: reason.detail,
            },
          });
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col self-center gap-10 w-full py-20 px-[3%] xl:px-[10%]">
      <div>
        <h3>{`${offer ? 'Edit the trade' : 'Create a new trade'} offer`}</h3>
        <p className="mt-2">Buy or sell cryptocurrencies, worldwide and locally!</p>
      </div>
      <FormSwitch
        name="offerType"
        control={control}
        itemClass="font-semibold"
        items={createOfferOptions}
        setValue={(value) => {
          setValue('offerType', value as OfferTypes, { shouldValidate: true, shouldDirty: true });
        }}
        height="h-12"
      />

      <div className="relative flex flex-col gap-6">
        <h5>
          {`I want to ${currentOfferType?.toLowerCase()}`}
        </h5>
        <div className="flex flex-col gap-8">
          {
            methodsBlocks.map(({ label, methods: paymentMethods }) => {
              if (methods.length) {
                return (
                  <div className="flex flex-col gap-4">
                    <h6 className="font-semibold text-[13px]">{label}</h6>
                    <div className="flex flex-wrap gap-4 max-w-[1000px]">
                      <FormSwitch
                        name="paymentMethodTypes"
                        control={control}
                        items={getPaymentMethodOptions(paymentMethods)}
                        inOneLine={false}
                        width="w-auto"
                        height="h-9"
                        itemClass="p-[14px] text-white text-[13px] font-normal"
                        activeItemClass="bg-[#3D6EFF33]"
                        setValue={(methodValue) => {
                          setValue('paymentMethodTypes', methodValue as string, { shouldValidate: true, shouldDirty: true });
                        }}
                        error={errors.offeredCurrency}
                        seperated
                      />
                    </div>
                  </div>
                );
              }
              return null;
            })
          }
        </div>
        {errors.paymentMethodTypes && <p className="absolute w-full text-[10px] z-0 leading-6 text-error -bottom-6">{errors.paymentMethodTypes.message}</p>}
      </div>
      <Line />
      <div className="flex flex-col gap-6">
        <div className="flex max-lg:flex-col lg:items-center gap-3">
          <h5>
            {`How would you like ${currentOfferType === OfferTypes.Buy ? 'to pay' : 'to get paid'}?`}
          </h5>
          <FormSwitch
            name="offeredCurrency"
            control={control}
            className="max-[720px]:overflow-y-hidden max-[720px]:ml-[-6%] max-[720px]:w-screen max-[720px]:px-[6%]"
            items={currencyOptions}
            width="w-[120px]"
            itemClass="font-semibold"
            activeItemClass="bg-[#3D6EFF33]"
            setValue={(value) => {
              setValue('offeredCurrency', value as CryptoCurrencyTypes, { shouldValidate: true, shouldDirty: true });
            }}
            error={errors.offeredCurrency}
            seperated
          />
        </div>
        <div className="grid grid-cols-2 max-[700px]:grid-cols-1 items-center gap-3 min-[700px]:w-fit">
          <div className="flex items-center gap-3 max-md:flex-wrap">
            <h5>for</h5>
            <FormSearchSelect
              name="demandedCurrency"
              className="relative min-[700px]:min-w-[280px] w-full"
              options={getCurrencies()}
              control={control}
              placeholder="Select"
              error={errors.demandedCurrency}
            />
          </div>
          <div className="flex items-center gap-3 max-md:flex-wrap">
            <h5>in</h5>
            <FormSearchSelect
              name="country"
              className="relative min-[700px]:min-w-[280px] w-full"
              options={getCountries()}
              control={control}
              placeholder="Select"
              error={errors.country}
            />
          </div>
        </div>
      </div>

      <Line />
      <div className="flex flex-col gap-4 w-fit">
        <h5>Set trade time</h5>
        <FormSwitch
          name="tradeTimeMinutes"
          control={control}
          items={tradeTimes}
          width="w-16"
          itemClass="font-semibold"
          setValue={(value) => {
            setValue('tradeTimeMinutes', Number(value), { shouldValidate: true, shouldDirty: true });
          }}
          error={errors.tradeTimeMinutes}
          seperated
        />
      </div>
      <Line />
      <div className=" grid grid-cols-3 max-[1500px]:grid-cols-2 max-[550px]:grid-cols-1 gap-4">
        {/* <FormSelect */}
        {/*   name="currencyReferenceMarket" */}
        {/*   label="Select the reference market" */}
        {/*   control={control} */}
        {/*   options={currencyMarketOptions} */}
        {/*   placeholder="Select reference market" */}
        {/*   error={errors.currencyReferenceMarket} */}
        {/* /> */}
        <FormInput
          name="minTradeSize"
          label="Min. trade size (optional)"
          control={control}
          placeholder="0.00"
          prefixIcon={currentDemandedCurrency === 'USD' ? '$' : ''}
          suffixIcon={currentDemandedCurrency === 'USD' ? '' : currentDemandedCurrency || ''}
          error={errors.minTradeSize}
        />
        <FormInput
          name="maxTradeSize"
          label="Max. trade size (optional)"
          control={control}
          placeholder="0.00"
          // value={watch('maxTradeSize') as number}
          prefixIcon={currentDemandedCurrency === 'USD' ? '$' : ''}
          suffixIcon={currentDemandedCurrency === 'USD' ? '' : currentDemandedCurrency || ''}
          error={errors.maxTradeSize}
        />
        <FormPercentInput
          name="offerMargin"
          label="Margin you want to use for the offer"
          control={control}
          placeholder="0 or more"
          setValue={setValue}
          defaultMarketType={defaultValues?.marketType as string}
          suffixIcon={<img src={percentIcon} alt="" />}
          error={errors.offerMargin}
        />
      </div>
      <Line />
      <div className="flex flex-col gap-1">
        <FormTextArea
          className="text-[14px]"
          name="description"
          control={control}
          label="Offer Terms"
          placeholder="For a successful trade, clearly list your expectations. Do not share personal information here."
          error={errors.description}
        />
      </div>

      <div className="flex gap-4 flex-wrap justify-between">
        <div className="flex gap-2 flex-wrap [&>button]:w-[180px] [&>button]:p-0 max-[640px]:w-full max-[640px]:[&>button]:w-full ">
          <Button
            category={ButtonCategory.Filled}
            type="submit"
            name="publish"
            isLoading={isLoading}
          >
            Publish offer
          </Button>
          <Button
            category={ButtonCategory.Default}
            onClick={() => { navigate(-1); }}
          >
            Cancel
          </Button>
        </div>
        <Button
          className="w-[180px] p-0 max-[640px]:w-full"
          category={ButtonCategory.Default}
          type="submit"
          name="draft"
          isLoading={draftIsLoading}
        >
          {`Save${offer ? ' ' : ' as '}draft`}
        </Button>
      </div>
    </form>
  );
};
