import { NavLink } from 'react-router-dom';

import { MouseEvent as ReactMouseEvent, useEffect, useRef } from 'react';
import { AuthToken } from '../../../../services';
import { AppElement, AppFC } from '../../../../interfaces';
import { MenuProps } from './types';

import {
  AuthPaths, ButtonCategory, HeaderCategory, Paths,
} from '../../../../constants';
import { AppLogo, CloseIcon, PlusIcon } from '../../../Atoms/icons';
import { Button } from '../../../Atoms';

export const Menu: AppFC<MenuProps> = ({
  className,
  category = HeaderCategory.App,
  toggleMenu,
  menuIsOpen,
}): AppElement => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const authToken: AuthToken = new AuthToken();
  const isAuth = authToken.getAccessToken() && authToken.getRefreshToken();
  const handleCloseClick = (event: ReactMouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu(!menuIsOpen);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
      if (menuIsOpen) {
        toggleMenu(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuIsOpen]);

  return (
    <div ref={menuRef} className={`absolute w-full h-screen bg-black ${isAuth && category === HeaderCategory.App ? 'px-[3%]' : 'px-[7%]'} pb-16 top-0 right-0  lg:hidden flex flex-col justify-between items-center gap-6 z-40 ${className}`}>
      <div className="w-full h-24 flex justify-between items-center">
        <NavLink to={Paths.Offers}>
          <AppLogo />
        </NavLink>
        <Button
          className="h-auto px-0"
          onClick={handleCloseClick}
        >
          <CloseIcon size="30" />
        </Button>
      </div>
      <div className="flex flex-col items-center gap-12 lg:hidden">
        <NavLink to={Paths.Offers}>
          Buy and Sell
        </NavLink>
        <NavLink to={Paths.Offers}>
          Guides
        </NavLink>
        {isAuth && category === HeaderCategory.App && (
        <NavLink to={Paths.OfferCreate}>
          <Button className="h-auto px-0 flex gap-3 items-center">
            <PlusIcon />
            New Offer
          </Button>
        </NavLink>
        )}
      </div>
      { !isAuth ? (
        <div className="flex flex-wrap justify-center gap-4 lg:hidden">
          <NavLink to={AuthPaths.SignUp}>
            <Button
              className="w-[240px] h-10 bg-secondary hover:bg-secondary"
              category={ButtonCategory.Filled}
            >
              Sign up
            </Button>
          </NavLink>
          <NavLink to={AuthPaths.SignIn}>
            <Button
              className="w-[240px] h-10"
              category={ButtonCategory.Filled}
            >
              Log in
            </Button>
          </NavLink>
        </div>
      ) : <div />}
    </div>

  );
};
