import { AppElement, AppFC } from '../../../interfaces';
import { StepProps } from './types';

import checkedIcon from '../../../assets/icons/checked.svg';

export const Step: AppFC<StepProps> = ({
  className,
  stepNumber,
  isCurrent,
  completed,
  ...rest
}): AppElement => (
  <div {...rest} className={`flex items-center justify-center h-6 w-6 rounded-full cursor-default ${completed ? 'bg-primary' : `border ${isCurrent ? 'border-blue bg-transparent' : 'border-[#B8C3FF14] bg-[#1F2A4099]'}`} ${className}`}>
    {completed ? <img src={checkedIcon} alt="checked" /> : <span className={`${isCurrent ? 'text-tertiary' : 'text-[#7B8BA5]'}`}>{stepNumber}</span>}
  </div>
);
