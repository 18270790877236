export enum AuthPaths {
  SignIn = '/signin',
  SignUp = '/signup',
  VerifyEmailConfirm = '/auth/confirm-email',
  ForgotPassword = '/forgot-password',
  ResetPassword = 'auth/reset-password',
}

export enum Paths {
  PersonalInfo = '/personal-info',
  OfferCreate = '/offer/create',
  OfferEdit = '/offers/:offerId',
  OfferBuy = '/offers/buy/:offerId',
  OfferSell = '/offers/sell/:offerId',
  MyOffers = '/my-offers',
  Offers = '/offers',
  Trades = '/trades',
  Trade = '/trades/:tradeId',
  Wallets = '/wallets',
  ProfileEdit = '/profile-edit',
  ChangePassword = '/change-password',
  PrivacyPolicy = '/privacy-policy',
  TermsOfUse = '/terms',
  Guides = '/guides',
  GuideCreateOffer = '/guides/how-to-create-offer',
  GuideBuyCrypto = '/guides/how-to-buy-crypto',
  GuideSellCrypto = '/guides/how-to-sell-crypto',
  Home = '/',
}

export enum AdminPaths {
  Admin = '/admin',
  Disputes = '/admin/disputes',
  Dispute = '/admin/trades/:tradeId/dispute',
  Messages = '/admin/messages',
  SignIn = '/admin/signin',
}
