import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';

import {
  AuthToken,
  initAxiosHeaders,
  initAxiosInterceptors,
} from './services/httpRequest';

import {
  useOffer,
  usePayment,
  useProfile,
  useWallet,
} from './stores/hooks';

import {
  AdminPaths,
  AuthPaths,
  CryptoCurrencyTypes,
  OfferTypes,
  Paths,
} from './constants';

import { AuthLayout, Layout } from './components/Templates/Layout';
import { DefaultLayout } from './components/Pages/admin/layout';
import {
  EmailVerification,
  Landing,
  PasswordForgot,
  PasswordReset,
  SignIn,
  SignUp,
} from './components/Pages';
import { AppModal } from './components/Organisms';
import { Loader } from './components/Atoms';
import {
  CreateTrade,
  GuideBuyCrypto,
  GuideCreateOffer,
  GuideSellCrypto,
  MyOffers,
  OfferCreateForm,
  OfferEditForm,
  Offers,
  PasswordChange,
  PersonalInfo,
  PrivacyPolicy,
  ProfileEdit,
  TermsOfUse,
  Trade,
  Trades,
  Wallets,
} from './components/Pages/app';
import { getNetwork } from './utils';

import Disputes from './components/Pages/admin/adminPages/Dashboard/Disputes';
import AdminSignIn from './components/Pages/admin/adminPages/Authentication/SignIn';
import Dispute from './components/Pages/admin/adminPages/Dashboard/Dispute';
import useColorMode from './components/Pages/admin/hooks/useColorMode';

export const App = () => {
  initAxiosHeaders();
  initAxiosInterceptors();

  const authToken: AuthToken = new AuthToken();
  const accessToken = authToken.getAccessToken();
  const [isLoading, setIsLoading] = useState(true);

  const { getProfile, getIP } = useProfile();
  const { getCountryCodeByIP } = useOffer();
  const {
    getWallets, createWalletUser, createWallet, // createAllWallets,
  } = useWallet();
  const { getPaymentMethods } = usePayment();

  const [setColorMode] = useColorMode();

  useEffect(() => {
    if (accessToken) {
      Promise.all([
        getProfile(),
        getPaymentMethods(),
        getWallets(),
        getIP(),
      ])
        .then((res) => {
          if (!res[0].walletUserExist) {
            Promise.all([createWalletUser(), getCountryCodeByIP(res[3])]).then(() => {
              if (!res[2].length) {
                createWallet({
                  cryptoCurrencyType: CryptoCurrencyTypes.ETH,
                  walletSecretType: getNetwork(CryptoCurrencyTypes.ETH),
                }).then(() => {
                  Promise.all([createWallet({
                    cryptoCurrencyType: CryptoCurrencyTypes.BTC,
                    walletSecretType: getNetwork(CryptoCurrencyTypes.BTC),
                  }), createWallet({
                    cryptoCurrencyType: CryptoCurrencyTypes.WBTC,
                    walletSecretType: getNetwork(CryptoCurrencyTypes.WBTC),
                  }), createWallet({
                    cryptoCurrencyType: CryptoCurrencyTypes.USDT,
                    walletSecretType: getNetwork(CryptoCurrencyTypes.USDT),
                  }), createWallet({
                    cryptoCurrencyType: CryptoCurrencyTypes.USDC,
                    walletSecretType: getNetwork(CryptoCurrencyTypes.USDC),
                  })]).then(() => {
                    setIsLoading(false);
                  }).catch(() => {
                    setIsLoading(false);
                  });
                }).catch(() => {
                  setIsLoading(false);
                });
              }
            }).catch(() => {
              setIsLoading(false);
            });
          } else if (!res[2].length) {
            Promise.all([
              createWallet({
                cryptoCurrencyType: CryptoCurrencyTypes.BTC,
                walletSecretType: getNetwork(CryptoCurrencyTypes.BTC),
              }), createWallet({
                cryptoCurrencyType: CryptoCurrencyTypes.ETH,
                walletSecretType: getNetwork(CryptoCurrencyTypes.ETH),
              }),
              getCountryCodeByIP(res[3]),
            ]).then(() => {
              Promise.all([createWallet({
                cryptoCurrencyType: CryptoCurrencyTypes.WBTC,
                walletSecretType: getNetwork(CryptoCurrencyTypes.WBTC),
              }), createWallet({
                cryptoCurrencyType: CryptoCurrencyTypes.USDT,
                walletSecretType: getNetwork(CryptoCurrencyTypes.USDT),
              }), createWallet({
                cryptoCurrencyType: CryptoCurrencyTypes.USDC,
                walletSecretType: getNetwork(CryptoCurrencyTypes.USDC),
              })]).then(() => {
                setIsLoading(false);
              }).catch(() => {
                setIsLoading(false);
              });
            }).finally(() => {
              setIsLoading(false);
            });
          } else {
            getCountryCodeByIP(res[3]).finally(() => {
              setIsLoading(false);
            });
          }
        }).catch(() => {
          setIsLoading(false);
        });
    } else {
      Promise.all([getIP(), getPaymentMethods(),
      ]).then((res) => {
        getCountryCodeByIP(res[0]).then(() => {
          setIsLoading(false);
        }).catch(() => {
          setIsLoading(false);
        });
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (typeof setColorMode === 'function') {
      setColorMode('dark');
    }
  });

  return (
    <Router>
      <AppModal />
      {!isLoading ? (
        <Routes>
          <Route path={AuthPaths.SignIn} element={<AuthLayout><SignIn /></AuthLayout>} />
          <Route path={AuthPaths.SignUp} element={<AuthLayout><SignUp /></AuthLayout>} />
          <Route path={AuthPaths.VerifyEmailConfirm} element={<AuthLayout><EmailVerification /></AuthLayout>} />
          <Route path={AuthPaths.ForgotPassword} element={<AuthLayout><PasswordForgot /></AuthLayout>} />
          <Route path={AuthPaths.ResetPassword} element={<AuthLayout><PasswordReset /></AuthLayout>} />
          <Route path={Paths.PersonalInfo} element={<AuthLayout><PersonalInfo /></AuthLayout>} />
          <Route path={Paths.OfferCreate} element={<Layout><OfferCreateForm /></Layout>} />
          <Route path={Paths.OfferEdit} element={<Layout><OfferEditForm /></Layout>} />
          <Route path={Paths.OfferBuy} element={<Layout><CreateTrade tradeType={OfferTypes.Buy} /></Layout>} />
          <Route path={Paths.OfferSell} element={<Layout><CreateTrade tradeType={OfferTypes.Sell} /></Layout>} />
          <Route path={Paths.Trade} element={<Layout><Trade /></Layout>} />
          <Route path={Paths.Trades} element={<Layout><Trades /></Layout>} />
          <Route path={Paths.MyOffers} element={<Layout><MyOffers /></Layout>} />
          <Route path={Paths.Offers} element={<Layout><Offers /></Layout>} />
          <Route path={Paths.ProfileEdit} element={<Layout><ProfileEdit /></Layout>} />
          <Route path={Paths.Wallets} element={<Layout><Wallets /></Layout>} />
          <Route path={Paths.ChangePassword} element={<Layout><PasswordChange /></Layout>} />

          <Route path={Paths.PrivacyPolicy} element={<Layout><PrivacyPolicy /></Layout>} />
          <Route path={Paths.TermsOfUse} element={<Layout><TermsOfUse /></Layout>} />
          <Route path={Paths.GuideCreateOffer} element={<Layout><GuideCreateOffer /></Layout>} />
          <Route path={Paths.GuideBuyCrypto} element={<Layout><GuideBuyCrypto /></Layout>} />
          <Route path={Paths.GuideSellCrypto} element={<Layout><GuideSellCrypto /></Layout>} />
          <Route path={Paths.Guides} element={<Layout><Navigate to={Paths.GuideCreateOffer} /></Layout>} />

          <Route path={Paths.Home} element={<Layout><Landing /></Layout>} />
          <Route path="*" element={<Layout><Navigate to={Paths.Offers} /></Layout>} />

          <Route path={AdminPaths.SignIn} element={(<DefaultLayout><AdminSignIn /></DefaultLayout>)} />
          <Route path={AdminPaths.Disputes} element={<DefaultLayout><Disputes /></DefaultLayout>} />
          <Route path={AdminPaths.Dispute} element={<DefaultLayout><Dispute /></DefaultLayout>} />
          <Route path={`${AdminPaths.Admin}/*`} element={<Navigate to={AdminPaths.Disputes} />} />
        </Routes>
      ) : (
        <div className="flex bg-black items-center justify-center h-screen w-screen">
          <Loader />
        </div>
      )}
    </Router>
  );
};
