import { AppElement, AppFC } from '../../../interfaces';
import { OfferTypeBoxProps } from './types';
import { OfferTypes } from '../../../constants';

import arrowIcon from '../../../assets/icons/arrow.svg';

export const OfferTypeBox: AppFC<OfferTypeBoxProps> = ({
  offerType,
  offeredCurrency,
  demandedCurrency,
  className,
  ...rest
}): AppElement => (
  <div {...rest} className={`flex flex-col gap-2 ${className || ''}`}>
    <div className="flex items-center gap-1">
      <img className={`${offerType === OfferTypes.Sell ? '' : 'transform rotate-180'}`} src={arrowIcon} alt="" />
      <span className="font-semibold leading-none text-tertiary">{offerType === OfferTypes.Buy ? OfferTypes.Sell : OfferTypes.Buy}</span>
    </div>
    <div className="flex items-center flex-wrap gap-1.5">
      {offeredCurrency}
      <h6 className="text-secondary leading-none">for</h6>
      {demandedCurrency}
    </div>
  </div>
);
