import React from 'react';

import { AppElement, AppFC } from '../../../interfaces';

import { Card, ProgressBox, Stars } from '../../Atoms';
import { UserRatingsProps } from './types';
import { getRatingDetails } from '../../../utils';

export const UserRatingCard: AppFC<UserRatingsProps> = ({ ratings, className }): AppElement => {
  const {
    totalAmount,
    fiveStarAmount,
    fourStarAmount, threeStarAmount,
    twoStarAmount,
    oneStarAmount,
    averageRate,
  } = getRatingDetails(ratings);

  return (
    <Card
      onClick={(event) => {
        event.preventDefault();
      }}
      className={`w-[300px] max-xsm:w-[250px] absolute z-50 bg-default border border-white/[0.08] p-6 ${className || ''}`}
    >
      <div className="w-full flex flex-col gap-4">
        <h5 className="text-[12px] leading-none">Average Rating</h5>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Stars size={18} rating={Number(averageRate)} />
            <span className="text-[10px] leading-none mt-0.5">{`${Number(averageRate)} out of 5`}</span>
          </div>
          <span className="text-[#596983] text-[10px] leading-none mt-0.5">{`${ratings.length} vote`}</span>
        </div>
        <div className="flex flex-col gap-4 mt-1">
          <ProgressBox stars={5} persons={fiveStarAmount} totalAmount={totalAmount} currentAmount={fiveStarAmount} />
          <ProgressBox stars={4} persons={fourStarAmount} totalAmount={totalAmount} currentAmount={fourStarAmount} />
          <ProgressBox stars={3} persons={threeStarAmount} totalAmount={totalAmount} currentAmount={threeStarAmount} />
          <ProgressBox stars={2} persons={twoStarAmount} totalAmount={totalAmount} currentAmount={twoStarAmount} />
          <ProgressBox stars={1} persons={oneStarAmount} totalAmount={totalAmount} currentAmount={oneStarAmount} />
        </div>
      </div>
    </Card>
  );
};
