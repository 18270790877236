import { httpRequest } from '../../services';

import { CHAT_API, RequestTypes } from '../../constants';
import { HttpRequestHeaderTypes } from '../../services/httpRequest';

export const useChat = () => {
  const { CREATE_FILE } = CHAT_API;

  const createChatFile = async (data: FormData) => httpRequest({
    url: CREATE_FILE,
    method: RequestTypes.Post,
    data,
    headerType: HttpRequestHeaderTypes.MultiPart,
  });

  return ({
    createChatFile,
  });
};
