import { useState } from 'react';
import ReactSelect from 'react-select';

import { AppElement, AppFC } from '../../../interfaces';
import { SelectProps } from './types';

import { DropdownIcon } from '../icons';

export const Select: AppFC<SelectProps> = ({
  className,
  name,
  options,
  value,
  defaultValue,
  placeholder,
  disabled,
  blocked,
  onChange,
  isMulti,
  error,
}): AppElement => {
  const [menuOpen, setMenuOpen] = useState(false);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: error ? '#401F1F99' : '#1F2A4099', // bg-error : bg-input
      borderRadius: '0.5rem',
      borderColor: error ? 'rgba(255, 40, 40, 0.8)' : '#B8C3FF14', // border-default
      height: isMulti ? 'auto' : '3rem',
      width: '100%',
      paddingLeft: '14px',
      paddingRight: '1rem',
      paddingTop: isMulti ? '8px' : '',
      paddingBottom: isMulti ? '8px' : '',
      cursor: 'pointer',
      boxShadow: 'none',
      ':hover': {
        borderColor: error ? 'rgba(255, 40, 40, 0.8)' : '#B8C3FF14', // border-default
      },
      ':focus': {
        backgroundColor: '#1B2C4CCC', // bg-inputFocus
        borderColor: error ? 'rgba(255, 40, 40, 0.8)' : '#B8C3FF14', // border-default
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: placeholder === 'All' ? 'white' : '#7B8BA5', // text-disabled
    }),
    input: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#0f1625', // bg-input
      overflow: 'hidden',
      padding: 0,
      borderRadius: '0.5rem',
      border: '1px #B8C3FF14 solid',
      marginTop: '6px',
      marginBottom: '6px',
      zIndex: 10,
      width: '100%',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3D6EFF' : 'transparent', // bg-primary
      '&:hover': {
        backgroundColor: !state?.isSelected ? '#2955D6' : '#3D6EFF', // bg-primaryHover : bg-primary
      },
      fontSize: '14px',
      color: 'white',
      width: '100%',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      fontSize: '14px',
      height: '100%',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#3D6EFF', // bg-input
      // padding: 0,
      borderRadius: '0.5rem',
      border: '1px #B8C3FF14 solid',
      color: 'white',
      // marginTop: '6px',
      // marginBottom: '6px',
      // zIndex: 10,
      // width: '100%',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'white',
      // marginTop: '6px',
      // marginBottom: '6px',
      // zIndex: 10,
      // width: '100%',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
      // marginTop: '6px',
      // marginBottom: '6px',
      // zIndex: 10,
      // width: '100%',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: blocked || disabled ? '#7B8BA5' : 'white', // text-disabled
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      height: '100%',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
  };

  const dropdownIndicator = () => (<DropdownIcon className={menuOpen ? 'transform rotate-180' : ''} />);
  if (isMulti) {
    return (
      <div className={`relative ${className} ${blocked || disabled ? 'text-disabled' : 'text-white'}`}>
        <ReactSelect
          name={name}
          options={options}
          placeholder={placeholder}
          components={{ DropdownIndicator: dropdownIndicator }}
          styles={customStyles}
          isDisabled={blocked || disabled}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          menuPlacement="auto"
          isMulti
          defaultValue={options.filter((option) => (defaultValue as Array<string>)?.includes(option.value))}
          value={options.filter((option) => (value as Array<string>)?.includes(option.value))}
          menuShouldScrollIntoView
          onChange={(newValue) => {
            if (onChange) {
              const values = newValue.map((item) => item.value);
              onChange(values);
            }
          }}
        />
        {!!error && <p className="absolute w-full text-[10px] leading-6 text-end text-error">{error.message}</p>}
      </div>
    );
  }
  return (
    <div className={`relative ${className} ${blocked || disabled ? 'text-disabled' : 'text-white'}`}>
      <ReactSelect
        name={name}
        options={options}
        placeholder={placeholder}
        components={{ DropdownIndicator: dropdownIndicator }}
        styles={customStyles}
        isDisabled={blocked || disabled}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        menuPlacement="auto"
        defaultValue={options.find((option) => option.value === defaultValue)}
        value={options.find((option) => option.value === value)}
        menuShouldScrollIntoView
        onChange={(newValue) => {
          if (onChange) {
            onChange(newValue!.value);
          }
        }}
      />
      {!!error && <p className="absolute w-full text-[10px] leading-6 text-end text-error">{error.message}</p>}
    </div>
  );
};
