import { RequestInterface } from './interfaces';
import { RequestStatusTypes } from '../../../constants';
import { getCancelToken } from '../../../services/httpRequest/axios';

export const requestInitialState: RequestInterface = {
  status: RequestStatusTypes.Waiting,
  successMessage: '',
  errorMessage: '',
  responseData: {},
  cancelTokenSource: getCancelToken(),
};
