import { httpRequest } from '../../services';

import { NOTIFICATION_API, RequestTypes } from '../../constants';

/** The hook returns trade handlers * */
export const useNotification = () => {
  const getNotifications = async () => {
    const response = await httpRequest({
      url: NOTIFICATION_API().GET_NOTIFICATIONS,
      method: RequestTypes.Get,
    });
    return response.data;
  };

  const getNotificationById = async (id: string) => httpRequest({
    url: NOTIFICATION_API(id).GET_NOTIFICATION,
    method: RequestTypes.Get,
  });

  const markAsRead = async (id: string) => {
    const response = await httpRequest({
      url: NOTIFICATION_API(id).MARK_AS_READ,
      method: RequestTypes.Patch,
    });

    return response.data;
  };

  /** * */

  return ({
    getNotifications,
    getNotificationById,
    markAsRead,
  });
};
