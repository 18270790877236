import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const Label: AppFC<HTMLAttributes<HTMLDivElement>> = ({
  children, className, ...props
}): AppElement => (
  <div {...props} className={`relative w-full flex flex-col gap-1 ${className || ''}`}>
    {children}
  </div>
);
