import { useMemo, useReducer, createContext } from 'react';

import { requestReducer } from './reducer';
import { AppElement, AppFC } from '../../../interfaces';
import {
  ContextProviderPropsInterface,
  UseReducerType,
} from '../../interfaces';
import {
  RequestActionType,
  RequestContextInterface,
  RequestInterface,
} from './interfaces';

import { requestInitialState } from './initialState';

export const RequestContext = createContext<RequestContextInterface>({} as RequestContextInterface);

export const RequestContextProvider: AppFC<ContextProviderPropsInterface> = ({ children }): AppElement => {
  const [requestState, updateRequestState] = useReducer<
  UseReducerType<RequestInterface, RequestActionType>
  >(requestReducer, requestInitialState);

  const value = useMemo(() => ({
    requestState,
    updateRequestState,
  }), [requestState]);

  return (
    <RequestContext.Provider value={value}>{children}</RequestContext.Provider>
  );
};
