import { CountdownCircleTimer, useCountdown } from 'react-countdown-circle-timer';
import { useEffect, useState } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { StepTimerProps } from './types';
import { convertSecondsToHMS } from '../../../utils';

export const StepTimer: AppFC<StepTimerProps> = ({
  className,
  duration,
  currentTimeBySeconds,
  description,
}): AppElement => {
  const {
    remainingTime,
  } = useCountdown({ isPlaying: true, duration, colors: '#0BF007' });
  const [time, setTime] = useState(remainingTime - currentTimeBySeconds);

  useEffect(() => {
    setTimeout(() => {
      if (time === 0) {
        return;
      }
      setTime(time - 1);
    }, 1000);
  }, [time]);
  return (
    <div className={`relative flex items-center gap-2 ${className || ''}`}>
      <CountdownCircleTimer
        size={24}
        isPlaying
        strokeWidth={6}
        strokeLinecap="butt"
        duration={duration}
        initialRemainingTime={remainingTime - currentTimeBySeconds >= 0 ? remainingTime - currentTimeBySeconds : 0}
        trailColor="#D9D9D929"
        colors={['#0BF007', '#0BF007']}
        colorsTime={[duration, 0]}
      />
      <h5 className="leading-normal">{convertSecondsToHMS(time)}</h5>
      {!!description && <p className="text-end">{description}</p>}
    </div>
  );
};
