import React from 'react';
import {
  Controller, FieldError, FieldValue, FieldValues,
} from 'react-hook-form';

import { AppElement, AppFC } from '../../../interfaces';
import { FormPercentInputProps } from './types';

import { Button, Input, Label } from '../../Atoms';
import { AboveIcon } from '../../Atoms/icons';
import { FormSwitch } from '../FormSwitch';
import { MarketTypes } from '../../../constants';

const switchItems = [{
  value: MarketTypes.Above,
  label: (
    <span className="text-[11px] flex items-center gap-1">
      <AboveIcon />
      above market
    </span>
  ),
},
{
  value: MarketTypes.Below,
  label: (
    <span className="text-[11px] flex items-center gap-1">
      <AboveIcon className="transform rotate-180" />
      <p>below market</p>
    </span>
  ),
}];
export const FormPercentInput: AppFC<FormPercentInputProps> = ({
  label,
  control,
  error,
  name,
  className,
  setValue,
  defaultMarketType,
  children,
  ...rest
}): AppElement => {
  const percents: FieldValues = [0.5, 1, 2, 5];

  return (
    <Label className={className}>
      {!!label && <h6 className={`${error ? 'text-error' : ''}`}>{label}</h6>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div>
            <div className="relative flex items-center gap-2 w-full">
              <Input
                className="flex-1"
                error={error ? { message: '' } as FieldError : undefined}
                {...field}
                {...rest}
                type="number"
                min={0}
              />
              <FormSwitch
                control={control}
                name="marketType"
                items={switchItems}
                itemClass="font-semibold"
                setValue={(value) => {
                  setValue('marketType', value as MarketTypes, { shouldValidate: true, shouldDirty: true });
                }}
                width="min-w-[104px]"
                height="h-5"
                direction="vertical"
              />
              {!!error && <p className="absolute w-full text-[10px] leading-6 text-error -bottom-6">{error.message}</p>}
            </div>
            <div className={`flex gap-2 ${error ? 'mt-5' : 'mt-2'}`}>
              {percents.map((percent: FieldValue<any>) => <Button onClick={() => { setValue(field.name, percent, { shouldValidate: true }); }} className={`w-7 h-6 text-[11px] text-grey bg-input hover:bg-inputHover border ${percent === rest.value ? 'border-blue' : 'border-default'} rounded-md p-0`}>{percent}</Button>)}
            </div>
          </div>
        )}
      />
    </Label>
  );
};
