import React, { useContext, useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { AppElement, AppFC } from '../../../interfaces';
import { OfferBoxProps } from './types';
import { ButtonCategory, cryptoCurrencyNames, MarketTypes } from '../../../constants';

import {
  generateDemandedCurrencyBox,
  generateOfferedCurrencyBox, numberWithCommas,
} from '../../../utils';

import { Button, Card, Loader } from '../../Atoms';
import {
  CountryBox, CurrencyPriceBox, OfferTypeBox, PaymentMethodBox,
} from '../../Atoms/offerItems';
import { ViewContext, ViewModalActionTypes } from '../../../stores';
import { useCurrency } from '../../../stores/hooks';

export const DraftOfferCard: AppFC<OfferBoxProps> = ({
  offer,
  onDeleteClick,
  isDeleting,
}): AppElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [rate, setRate] = useState<{ rate: string, currency: string }>();
  const { updateViewModal } = useContext(ViewContext);

  // const { offerState: { currencyRate } } = useContext(OfferContext);
  // const { rate, currency } = currencyRate;

  const { getCryptocurrencyRate } = useCurrency();

  const {
    id: offerId,
    offerType,
    offeredCurrency,
    demandedCurrency,
    margin,
    paymentMethodTypes,
  } = offer;

  useEffect(() => {
    getCryptocurrencyRate(demandedCurrency, offeredCurrency).then((res) => {
      setRate(res);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, []);

  const price = numberWithCommas((Number(rate?.rate) * (100 + margin)) / 100, demandedCurrency, 2);

  const handleDelete = () => {
    updateViewModal({
      type: ViewModalActionTypes.DeleteModal,
      payload: {
        title: 'Delete offer',
        description: 'Are you sure you want to delete this offer?',
        isClosable: false,
        onDelete: () => onDeleteClick(offerId),
      },
    });
  };

  if (!offerId) {
    return null;
  }

  const marketType = (margin > 0 && MarketTypes.Above) as MarketTypes
      || (margin < 0 && MarketTypes.Below) as MarketTypes
      || (margin === 0 && MarketTypes.None) as MarketTypes;

  return (
    <Card className="flex flex-col gap-6 p-6">
      <div className="grid grid-cols-2 gap-4 w-full self-start">
        <OfferTypeBox
          offerType={offerType}
          offeredCurrency={generateOfferedCurrencyBox(offeredCurrency)}
          demandedCurrency={generateDemandedCurrencyBox(demandedCurrency)}
        />
        {!isLoading ? (
          <CurrencyPriceBox
            price={price}
            margin={Math.abs(margin)}
            marketType={marketType}
            info={`This offer is ${margin === 0 ? 'equal to' : `${margin}% ${marketType}`} ${cryptoCurrencyNames[offeredCurrency]} current market price.`}
          />
        ) : <Loader />}
        <CountryBox offer={offer} />
        <PaymentMethodBox
          methods={paymentMethodTypes}
        />
      </div>
      <div className="w-full flex gap-2 justify-between items-center">
        <NavLink to={`/offers/${offerId}`}><Button category={ButtonCategory.Filled}><span>Details</span></Button></NavLink>
        <Button className="w-fit text-red-800 underline p-0" onClick={handleDelete} isLoading={isDeleting}><span>Delete</span></Button>
      </div>
    </Card>
  );
};
