import axios from 'axios';
import { AuthToken } from './AuthToken';
import {
  CustomHeaderProps,
  HttpRequestHeaderTypes,
  RequestHeaderProps,
} from './interfaces';

const jsonHeader: CustomHeaderProps = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};

const multiPartHeader: CustomHeaderProps = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

export const initAxiosHeaders = () => {
  axios.defaults.headers.post.Accept = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.defaults.headers.put.Accept = 'application/json';
  axios.defaults.headers.put['Content-Type'] = 'application/json';

  axios.defaults.headers.delete.Accept = 'application/json';
  axios.defaults.headers.delete['Content-Type'] = 'application/json';

  axios.defaults.headers.get.Accept = 'application/json';
  axios.defaults.headers.get['Content-Type'] = 'application/json';
};

export const httpHeader = (params: RequestHeaderProps): CustomHeaderProps => {
  const authToken = new AuthToken();
  const defaultParams = params.withoutToken
    ? {}
    : { Authorization: `Bearer ${authToken.getAccessToken()}` };

  switch (params.headerType) {
    case HttpRequestHeaderTypes.MultiPart:
      return {
        ...multiPartHeader,
        ...defaultParams,
      };
    default:
      return {
        ...jsonHeader,
        ...defaultParams,
      };
  }
};
