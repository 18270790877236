import { viewHeaderInitialState } from './initialState';

import { ViewHeaderActionTypes } from './actions';
import { ViewHeaderInterface, ViewHeaderReducerInterface } from './interfaces';

export const viewHeaderReducer: ViewHeaderReducerInterface = (
  state = { ...viewHeaderInitialState },
  action,
): ViewHeaderInterface => {
  switch (action.type) {
    case ViewHeaderActionTypes.UpdateDesktopHeader:
      return { ...state, isDesktopOpen: action.payload };

    case ViewHeaderActionTypes.UpdateMobileHeader:
      return { ...state, isMobileOpen: action.payload };

    case ViewHeaderActionTypes.CloseAll:
      return { ...state, isMobileOpen: false, isDesktopOpen: false };

    default:
      return state;
  }
};
