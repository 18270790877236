import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const AboveIcon: AppFC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }): AppElement => (
  <div className={`cursor-pointer ${className || ''}`} {...props}>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.66802 0.964927L1.68525 3.83125C1.50221 4.00715 1.50221 4.29232 1.68525 4.46822C1.86829 4.64411 2.16505 4.64411 2.34809 4.46822L4.53074 2.37077L4.53074 6.68821C4.53074 6.93695 4.74058 7.13861 4.99944 7.13861C5.25829 7.13861 5.46814 6.93695 5.46814 6.68821V2.37077L7.65079 4.46822C7.83383 4.64411 8.13059 4.64411 8.31363 4.46822C8.49667 4.29232 8.49667 4.00715 8.31363 3.83125L5.33086 0.964927C5.14782 0.789035 4.85106 0.789035 4.66802 0.964927Z" fill="currentColor" />
      <path d="M1.7187 8.2646C1.45984 8.2646 1.25 8.46626 1.25 8.715C1.25 8.96375 1.45984 9.1654 1.7187 9.1654H2.65609C2.91495 9.1654 3.12479 8.96375 3.12479 8.715C3.12479 8.46626 2.91495 8.2646 2.65609 8.2646H1.7187Z" fill="currentColor" />
      <path d="M4.06218 8.715C4.06218 8.46626 4.27203 8.2646 4.53088 8.2646H5.46828C5.72713 8.2646 5.93697 8.46626 5.93697 8.715C5.93697 8.96375 5.72713 9.1654 5.46828 9.1654H4.53088C4.27203 9.1654 4.06218 8.96375 4.06218 8.715Z" fill="currentColor" />
      <path d="M7.34306 8.2646C7.08421 8.2646 6.87437 8.46626 6.87437 8.715C6.87437 8.96375 7.08421 9.1654 7.34306 9.1654H8.28046C8.53931 9.1654 8.74916 8.96375 8.74916 8.715C8.74916 8.46626 8.53931 8.2646 8.28046 8.2646H7.34306Z" fill="currentColor" />
    </svg>
  </div>
);
