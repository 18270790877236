import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const EyeIcon: AppFC<HTMLAttributes<HTMLDivElement> & { error:boolean }> = ({ error, ...rest }): AppElement => (
  <div {...rest}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5C7.22727 5 3.15136 7.90267 1.5 12C3.15136 16.0973 7.22727 19 12 19C16.7727 19 20.8486 16.0973 22.5 12C20.8486 7.90267 16.7727 5 12 5ZM12 16.6667C9.36545 16.6667 7.22727 14.576 7.22727 12C7.22727 9.424 9.36545 7.33333 12 7.33333C14.6345 7.33333 16.7727 9.424 16.7727 12C16.7727 14.576 14.6345 16.6667 12 16.6667ZM12 9.2C10.4155 9.2 9.13636 10.4507 9.13636 12C9.13636 13.5493 10.4155 14.8 12 14.8C13.5845 14.8 14.8636 13.5493 14.8636 12C14.8636 10.4507 13.5845 9.2 12 9.2Z" fill={error ? 'rgba(255, 40, 40, 0.8)' : '#7B8BA5'} />
    </svg>
  </div>
);
