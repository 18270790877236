import { useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import {
  ViewContext,
  ViewModalActionTypes,
} from '../../../stores';
import { AppElement, AppFC } from '../../../interfaces';

import closeIcon from '../../../assets/icons/close.svg';
import printIcon from '../../../assets/icons/print.svg';

export const Modal: AppFC = ({ className, children }): AppElement => {
  const { viewModal, updateViewModal } = useContext(ViewContext);
  const {
    content,
    isClosable,
    isPrintable,
    isReload,
    ref,
  } = viewModal;

  const contentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => contentRef?.current,
  });

  return (
    <div ref={ref} className={`relative flex justify-center rounded-lg max-md:w-full ${className || ''}`}>
      <div className="absolute z-10 top-4 right-4 p-0 flex gap-2 [&>button]:h-auto [&>button]:p-0">
        {isPrintable && (
        <button onClick={handlePrint}>
          <img src={printIcon} alt="print" />
        </button>
        )}
        {isClosable && (
        <button
          onClick={() => {
            if (isReload) {
              window.location.reload();
            }
            updateViewModal({ type: ViewModalActionTypes.Close });
          }}
        >
          <img src={closeIcon} alt="close" />
        </button>
        )}
      </div>
      <div ref={contentRef} className="w-full h-full flex justify-center">{content || children}</div>
    </div>
  );
};
