import btcIcon from '../assets/icons/currencies/BTC.svg';
import wbtcIcon from '../assets/icons/currencies/WBTC.svg';
import ethIcon from '../assets/icons/currencies/ETH.svg';
import usdcIcon from '../assets/icons/currencies/USDC.svg';
import usdtIcon from '../assets/icons/currencies/USDT.svg';
import { CryptoCurrencyTypes } from './types';

export const currencyIcons: { [key: string]: string } = {
  BTC: btcIcon,
  WBTC: wbtcIcon,
  ETH: ethIcon,
  USDC: usdcIcon,
  USDT: usdtIcon,
};

export const currencies: CryptoCurrencyTypes[] = [
  CryptoCurrencyTypes.BTC,
  CryptoCurrencyTypes.WBTC,
  CryptoCurrencyTypes.ETH,
  CryptoCurrencyTypes.USDC,
  CryptoCurrencyTypes.USDT,
];
