import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppElement, AppFC } from '../../../../../interfaces';
import { TableProps } from './types';
import { AdminPaths, OfferTypes } from '../../../../../constants';
import arrowIcon from '../../../../../assets/icons/arrow.svg';
import { formattedDate } from '../../../../../utils';

export const TableOne: AppFC<TableProps> = ({ title, disputes }): AppElement => {
  const navigate = useNavigate();
  useEffect(() => {
    // getDisputes().then((res) => { setDisputes(res); });
  }, []);

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      {title && (
      <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
        {title}
      </h4>
      )}

      <div className="flex flex-col">
        <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-transparent sm:grid-cols-3">
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Currency
            </h5>
          </div>
          {/* <div className="p-2.5 text-center xl:p-5"> */}
          {/*   <h5 className="text-sm font-medium uppercase xsm:text-base"> */}
          {/*     Buyer */}
          {/*   </h5> */}
          {/* </div> */}
          {/* <div className="p-2.5 text-center xl:p-5"> */}
          {/*   <h5 className="text-sm font-medium uppercase xsm:text-base"> */}
          {/*     Seller */}
          {/*   </h5> */}
          {/* </div> */}
          <div className=" p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Trade Date
            </h5>
          </div>
          <div className=" p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Dispute Date
            </h5>
          </div>
        </div>

        {disputes?.map((dispute, key) => (
          <div
            className={`cursor-pointer dark:hover:bg-meta-4 grid grid-cols-3 sm:grid-cols-3 ${
              key === disputes.length - 1
                ? ''
                : 'border-b border-stroke dark:border-strokedark'
            }`}
            key={key}
            onClick={() => { navigate(`${AdminPaths.Admin}/trades/${dispute.tradeId}/dispute`); }}
          >
            <div className="flex flex-col gap-3 p-2.5 xl:p-5">
              <div className="flex items-center gap-1">
                <img className="transform rotate-180" src={arrowIcon} alt="" />
                <span className="font-semibold leading-none text-tertiary">{OfferTypes.Sell}</span>
              </div>
              <div className="text-sm xsm:text-base flex items-center flex-wrap gap-1.5">
                {dispute.offeredAmount}
                <h6 className="text-secondary leading-none">for</h6>
                {dispute.demandedAmount}
              </div>
            </div>

            {/* <div className="flex items-center justify-center p-2.5 xl:p-5"> */}
            {/*   <p className="text-black dark:text-white"> */}
            {/*     {dispute.buyerUserId} */}
            {/*   </p> */}
            {/* </div> */}

            {/* <div className="flex items-center justify-center p-2.5 xl:p-5"> */}
            {/*   <p className="text-meta-3"> */}
            {/*     {dispute.sellerUserId} */}
            {/*   </p> */}
            {/* </div> */}

            <div className=" items-center justify-center p-2.5 sm:flex xl:p-5">
              <p className="text-sm xsm:text-base text-black dark:text-white">
                {formattedDate(dispute.tradeCreatedAt, 'hh:mm, DD MMMM, YYYY')}
              </p>
            </div>

            <div className="text-sm xsm:text-base items-center justify-center p-2.5 sm:flex xl:p-5">
              <p className="text-meta-5">
                {formattedDate(dispute.disputeCreatedAt, 'hh:mm, DD MMMM, YYYY')}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
