import { ProfileActionTypes } from './actions';
import { profileInitialState } from './initialState';
import { UseReducerType } from '../../interfaces';
import { ProfileActionType, ProfileInterface } from './interfaces';

const updateProfile = (data: ProfileInterface, oldData: ProfileInterface) => ({
  ...oldData,
  ...data,
});

export const profileReducer: UseReducerType<ProfileInterface, ProfileActionType> = (
  state,
  action,
): ProfileInterface => {
  switch (action.type) {
    case ProfileActionTypes.UpdateProfile:
      return { ...state, ...updateProfile(action.payload, state) };

    case ProfileActionTypes.ClearProfile:
      return profileInitialState;

    default:
      return state;
  }
};
