import React, {
  MouseEvent, useContext, useEffect, useState,
} from 'react';
import { Link, NavLink } from 'react-router-dom';

import { ProfileContext } from '../../../../stores';

import { AppElement, AppFC, NotificationInterface } from '../../../../interfaces';
import { HeaderProps } from './types';

import {
  AuthPaths, ButtonCategory, HeaderCategory, Paths,
} from '../../../../constants';

import { AppLogo, HamburgerIcon, PlusIcon } from '../../../Atoms/icons';
import { Avatar, Button } from '../../../Atoms';

import { Menu } from './Menu';
import { ProfileMenu } from './ProfileMenu';
import notificationIcon from '../../../../assets/icons/notifcations.svg';
import { NotificationMenu } from './NotificationMenu';
import { useNotification } from '../../../../stores/hooks/useNotification';

export const Header: AppFC<HeaderProps> = ({
  className,
  category = HeaderCategory.App,
  toggleMenu,
  menuIsOpen,
  toggleProfileMenu,
  profileMenuIsOpen,
  toggleNotificationMenu,
  notificationMenuIsOpen,
  isAuth,
}): AppElement => {
  const { profileState: { userName, profileImage } } = useContext(ProfileContext);
  const [notifications, setNotifications] = useState<NotificationInterface[]>([]);

  const { getNotifications } = useNotification();

  useEffect(() => {
    if (isAuth) {
      getNotifications().then((res) => {
        if (Array.isArray(res)) {
          const readNotifications = res.filter(({ read }) => !read);
          setNotifications(readNotifications);
        }
      });
      const timer = setInterval(() => {
        getNotifications().then((res) => {
          if (Array.isArray(res)) {
            const readNotifications = res.filter(({ read }) => !read);
            setNotifications(readNotifications);
          }
        });
      }, 10000);
      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [isAuth]);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (profileMenuIsOpen) {
      toggleProfileMenu(false);
    }
    if (notificationMenuIsOpen) {
      toggleNotificationMenu(false);
    }
    event.stopPropagation();
    toggleMenu(!menuIsOpen);
  };
  const handleProfileMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (menuIsOpen) {
      toggleMenu(false);
    }
    if (notificationMenuIsOpen) {
      toggleNotificationMenu(false);
    }
    event.stopPropagation();
    toggleProfileMenu(!profileMenuIsOpen);
  };
  const handleNotificationMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (menuIsOpen) {
      toggleMenu(false);
    }
    if (profileMenuIsOpen) {
      toggleProfileMenu(false);
    }
    event.stopPropagation();
    toggleNotificationMenu(!notificationMenuIsOpen);
  };

  return (
    <header className={`absolute top-0 w-full ${isAuth && category === HeaderCategory.App ? 'px-[3%] shadow-header bg-header border-header border-b' : 'px-[7%] bg-transparent'} flex items-center justify-between gap-3 h-24 z-30 ${className || ''}`}>
      <NavLink to={isAuth ? Paths.Offers : Paths.Home}>
        <AppLogo />
      </NavLink>
      <div className="flex items-center gap-12 max-lg:hidden">
        <NavLink to={Paths.Offers}>
          Buy and Sell
        </NavLink>
        <NavLink to={Paths.GuideCreateOffer}>
          Guides
        </NavLink>
        {category === HeaderCategory.App
                && (
                <div className="flex gap-2">
                    {!isAuth ? (
                      <div className="flex gap-2 h-10">
                        <NavLink to={AuthPaths.SignUp}>
                          <Button
                            className="w-[120px] h-full bg-secondary hover:bg-secondary"
                            category={ButtonCategory.Filled}
                          >
                            Sign up
                          </Button>
                        </NavLink>
                        <NavLink to={AuthPaths.SignIn}>
                          <Button
                            className="w-[120px] h-full"
                            category={ButtonCategory.Filled}
                          >
                            Log in
                          </Button>
                        </NavLink>
                      </div>
                    ) : (
                      <div className="flex gap-6 items-center">
                        <Link to={Paths.OfferCreate}>
                          <Button
                            className="px-4 h-10 flex gap-3 items-center"
                            category={ButtonCategory.Filled}
                          >
                            <PlusIcon />
                            New Offer
                          </Button>
                        </Link>
                        <Button
                          className="p-0"
                          // category={profileImage ? ButtonCategory.Default : ButtonCategory.Filled}
                          onClick={handleNotificationMenuClick}
                        >
                          <div className="relative">
                            <img src={notificationIcon} alt="" />

                            {!!notifications.length && (
                            <div className="absolute p-1 h-4 min-w-4 cursor-pointer font-normal flex text-white text-[9px]  items-center justify-center rounded-full w-auto -top-1 left-3 bg-primary border-1 border-white ">
                              {notifications.length > 0 ? notifications.length : ''}
                            </div>
                            )}
                          </div>
                        </Button>
                        <Button
                          className={`h-10 w-10 p-0 rounded-[50%] overflow-hidden ${profileImage ? 'bg-black' : ''}`}
                          category={profileImage ? ButtonCategory.Default : ButtonCategory.Filled}
                          onClick={handleProfileMenuClick}
                        >
                          {!profileImage ? userName?.substring(0, 1).toUpperCase()
                            : <Avatar src={profileImage} size={10} />}
                        </Button>
                      </div>
                    )}
                </div>
                )}
      </div>
      <div className="flex gap-4 items-center lg:hidden p-0">
        {isAuth && category === HeaderCategory.App && (
        <div className="flex gap-4 items-center">
          <Button
            className="p-0"
              // category={profileImage ? ButtonCategory.Default : ButtonCategory.Filled}
            onClick={handleNotificationMenuClick}
          >
            <div className="relative">
              <img src={notificationIcon} alt="" />

              {!!notifications.length && (
              <div className="absolute p-1 h-4 min-w-4 cursor-pointer font-normal flex text-white text-[9px]  items-center justify-center rounded-full w-auto -top-1 left-3 bg-primary border-1 border-white ">
                {notifications.length > 0 ? notifications.length : ''}
              </div>
              )}
            </div>
          </Button>
          <Button
            className={`w-10 h-10 p-0 rounded-[50%] overflow-hidden ${profileImage ? 'bg-black' : ''}`}
            category={profileImage ? ButtonCategory.Default : ButtonCategory.Filled}
            onClick={handleProfileMenuClick}
          >
            {!profileImage ? userName?.substring(0, 1).toUpperCase()
              : <Avatar src={profileImage} size={10} />}
          </Button>
        </div>
        )}
        <button
          className="p-0"
          onClick={handleMenuClick}
        >
          <HamburgerIcon />
        </button>
      </div>
      {menuIsOpen && (
      <Menu menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} category={category} />
      )}
      {profileMenuIsOpen && (
      <ProfileMenu menuIsOpen={profileMenuIsOpen} toggleMenu={toggleProfileMenu} category={category} />
      )}
      {notificationMenuIsOpen && (
      <NotificationMenu notifications={notifications} menuIsOpen={notificationMenuIsOpen} toggleMenu={toggleNotificationMenu} category={category} />
      )}
    </header>
  );
};
