import { NavLink, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import React, { useState } from 'react';
import { AppElement, AppFC } from '../../../../interfaces';
import { ForgotPasswordProps } from './types';
import { AlertCategory, AuthPaths, ButtonCategory } from '../../../../constants';
import { Alert, Button } from '../../../Atoms';
import { useAuth } from '../../../../stores/hooks';
import { FormInput } from '../../../Molecules';
import { generateErrorAlert } from '../../helpers';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is invalid')
    .required('Please enter your email'),
});
export const PasswordForgot: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const location = useLocation();
  const { emailAddress = '' } = location.state || {};

  console.log('emailAddress', emailAddress);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: emailAddress as string,
    },
  });

  const { passwordReset } = useAuth();

  const onSubmit: SubmitHandler<ForgotPasswordProps> = (data) => {
    setIsLoading(true);
    passwordReset(data)
      .then((res) => {
        setIsLoading(false);
        setAlert(
          <Alert
            category={AlertCategory.Success}
            title={res.data.detail}
            description="Please check your email."
          />,
        );
        setTimeout(() => {
          setAlert(null);
        }, 5000);
      })
      .catch((reason) => {
        setIsLoading(false);
        generateErrorAlert(reason, 'Email sending failed!', setAlert);
        setTimeout(() => {
          setAlert(null);
        }, 5000);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 h-full justify-center">
      {alert}
      <div className="flex flex-col gap-4">
        <h4>Reset your password</h4>
        <p className="text-[15px]">
          Enter your email address and we will send you a password reset link.
        </p>
      </div>
      <FormInput
        label="Email"
        name="email"
        control={control}
        placeholder="example@gmail.com"
        error={errors.email}
      />
      <Button
        className="w-[200px]"
        type="submit"
        category={ButtonCategory.Filled}
        isLoading={isLoading}
      >
        Reset Password
      </Button>
      <div className="flex items-center gap-2 text-disabled">
        <span>Back to</span>
        <NavLink
          to={AuthPaths.SignIn}
          className="underline text-tertiary"
        >
          Log in
        </NavLink>
      </div>
    </form>
  );
};
