import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { AppElement, AppFC } from '../../interfaces';
import {
  AuthPaths, ButtonCategory, currencies, currencyIcons, Paths,
} from '../../constants';

import { Button } from '../Atoms';
import { OfferFilters } from '../Organisms';
import { AuthToken } from '../../services';
import { DropdownIcon } from '../Atoms/icons';
import landing1Image from '../../assets/images/landing/landing-1.png';
import landing2Image from '../../assets/images/landing/landing-2.png';
import logosImage from '../../assets/images/landing/logos.svg';

const Dropdown: AppFC<{ title: string; content: string }> = ({ title, content, className }): AppElement => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`flex flex-col gap-2 w-full border-listItem/40 ${className || ''} py-6`}>
      <Button className="flex items-center justify-between gap-4 p-0 h-auto w-full" onClick={toggleDropdown}>
        <h5 className="text-[18px] text-left">{title}</h5>
        <DropdownIcon className={isOpen ? 'transform rotate-180' : ''} />
      </Button>
      {isOpen && (
        <p className="leading-[28px]">{content}</p>
      )}
    </div>
  );
};

export const Landing: AppFC = (): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = !!authToken.getAccessToken() && !!authToken.getRefreshToken();

  const [activeIndex, setActiveIndex] = useState<number | null>();

  const buySellFlowList = [{
    title: 'Find or Create an Offer',
    description: 'Search for available offers in your country or create your own. With 50+ payment methods and a wide range of cryptocurrencies, Cryptolocally is the one-stop destination for secure and fast P2P trading.',
  },
  {
    title: 'Open a Trade',
    description: 'Open a trade on a selected offer and communicate your terms to the fellow trader. Once you have found an offer that suits you, simply open a trade and discuss terms with the other trader.',
  },
  {
    title: 'Escrow and Settlement',
    description: 'The seller will fund the smart contract escrow by sending crypto to it. Those funds are locked until the end of the trade and nobody can access them during the process, not even Cryptolocally’s team.',
  },
  {
    title: 'Completion',
    description: 'Congratulations! You have successfully traded crypto.',
  },
  ];

  const questionList = [{
    title: 'Is Cryptolocally safe?',
    content: 'All transactions made on CryptoLocally are completed by a non-custodial smart contract on-chain. This means that trades are as secure as they can get. No third party, including hackers, can interfere or lay a hand on your crypto at any stage of the trade. The seller\'s crypto is secured by the smart escrow until the seller verifies that the buyer completed the transaction on their end. The buyer won\'t get the crypto until he/she has paid. Similarly, the seller won\'t be paid until the crypto has been sent to the escrow, which cannot be withdrawn by the seller.',
  },
  {
    title: 'What does P2P mean and why should I trade P2P?',
    content: 'P2P stands for Peer-to-Peer. With P2P trading, users buy and sell cryptocurrency between themselves without a third party or intermediary. Since there is no involvement of any third party, users can directly trade with each other more efficiently, profitably, and securely. Peer-to-peer platforms like CryptoLocally are considered the most secure way of trading between users.',
  },
  {
    title: 'What’s an escrow? Are my cryptos safe?',
    content: 'An escrow is a legal concept describing a financial instrument, whereby assets are held by a third party on behalf of two other parties (buyer and seller) that are in the process of completing a transaction. Our non-custodial smart contract escrow protects all the trades on CryptoLocally until the end of each transaction. The seller\'s fund will be held by an escrow on behalf of CryptoLocally. You do not need to deposit any money on our platform. The buyer sends the money directly to the seller only once the escrow has been funded; the fund is held in the escrow in the name of the user temporarily. After the seller confirms that they have received the payment, the escrow is released and the tokens are sent to the buyer\'s wallet. This process allows buyers and sellers to complete trades safely and trustfully.',
  },
  ];

  return (
    <div className="bg-black py-24">
      {/* filters */}
      <div className={`${!isAuth ? 'bg-landingLayout bg-cover' : ''} bg-black`}>
        <div className={`flex max-md:flex-col gap-14 justify-between h-full items-center ${isAuth ? '' : 'pt-48 -mt-48'} pb-48 px-[7%]`}>
          <div className="max-w-[600px] h-full flex flex-col justify-center max-md:items-center">
            <h3 className="text-[3rem] leading-[67px] max-md:text-center">Buy & sell crypto safely, with your local currency.</h3>
            <p className="text-[1.25rem] leading-[2rem] mt-4 mb-10 max-md:text-center">Secure, non-custodial trading with 50+ payment methods in 180 countries.</p>
            <NavLink to={isAuth ? Paths.Offers : AuthPaths.SignUp}>
              <Button
                className="w-[160px]"
                category={ButtonCategory.Filled}
              >
                Start Trading
              </Button>
            </NavLink>
          </div>
          <OfferFilters />
        </div>
        <div className=" inset-0 bg-gradient-to-b from-transparent to-black flex flex-wrap justify-center gap-[12%] py-24 [&_span]:text-[64px] [&_span]:max-md:text-[48px] px-[7%]">
          <div className="flex flex-col gap-5 items-center mt-[40px]">
            <span className="font-extralight leading-none">
              15K+
            </span>
            <p className="text-center">
              Trade Offers Worldwide
            </p>
          </div>
          <div className="flex flex-col gap-5 items-center mt-[40px]">
            <span className="font-extralight leading-none">
              $10M+
            </span>
            <p className="text-center">
              In Trades
            </p>
          </div>
          <div className="flex flex-col gap-5 items-center mt-[40px]">
            <span className="font-extralight leading-none">
              50+
            </span>
            <p className="text-center">
              Payment Methods
            </p>
          </div>
        </div>
      </div>

      {/* currencies */}
      <div className="flex flex-col gap-15 items-center py-24 px-[7%]">
        <div className="flex flex-wrap justify-center gap-[7%] w-full">
          {currencies.map((currency) => (
            <div className="flex gap-4 items-center mt-[40px]">
              <img className="h-10 w-10" src={currencyIcons[currency]} alt="" />
              <span className="leading-none text-[24px]">{currency}</span>
            </div>
          ))}
        </div>
        <span className="text-[18px]">More to come!</span>
      </div>

      {/* Buy and Sell Crypto Transparently */}
      <div className="relative flex max-md:flex-col gap-[13%] max-md:gap-10 py-24 justify-center h-full items-center px-[7%]">
        <div className="absolute inset-0" style={{ background: 'radial-gradient(circle at calc(30vw) center, #3D6EFF66, black calc(450px))' }} />
        <img className="w-[50%] max-md:w-[80%] max-w-[450px] z-10" src={landing1Image} alt="" />
        <div className="flex flex-col gap-5 flex-1 max-w-[440px] z-10">
          <h3 className="mb-3">Buy and Sell Crypto Transparently</h3>
          {buySellFlowList.map(({ title, description }, index) => (
            <div className="flex flex-col gap-2">
              <Button className="flex items-center gap-4 p-0 h-auto" onClick={() => { index === activeIndex ? setActiveIndex(null) : setActiveIndex(index); }}>
                <h5 className="rounded-full bg-primary w-6 h-6 text-center text-[15px]">{index + 1}</h5>
                <h5 className="text-[18px]">{title}</h5>
              </Button>
              {activeIndex === index && (
                <p className="pl-10">
                  {description}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Revolutionary smart contract, non-custodial escrow technology */}
      <div className="relative flex max-md:flex-col max-md:gap-10 gap-[13%] pb-56 pt-32 justify-center items-center px-[7%]">
        <div className="absolute inset-0" style={{ background: 'radial-gradient(circle at calc(65vw) center, #3D6EFF66, black calc(400px))' }} />

        <div className="flex-1 max-w-md z-10">
          <h3 className="mb-3">Revolutionary smart contract, non-custodial escrow technology</h3>
          <p className="mb-8">Our patent-pending, secure escrow solution ensures we never hold your funds. Your keys, your crypto, your control.</p>
          <NavLink to={isAuth ? Paths.Offers : AuthPaths.SignUp}>
            <Button className="w-max" category={ButtonCategory.Filled}>Trade now</Button>
          </NavLink>
        </div>
        <img className="w-[50%] max-md:w-[80%] max-w-[600px] z-10" src={landing2Image} alt="" />
      </div>

      {/* Fully decentralized P2P crypto exchange, guided by the community. */}
      <div className="flex flex-col items-center px-[7%]">
        <div className="flex flex-col items-center max-w-lg [&_*]:text-center">
          <h3 className="mb-3">Fully decentralized P2P crypto exchange, guided by the community.</h3>
          <p className="mb-8 leading-[28px]">
            Trade securely and autonomously on a fully decentralized P2P crypto exchange, driven by community decisions. No intermediaries, just direct, transparent transactions and arbitrage opportunities where your voice shapes the future.
          </p>
          <NavLink to={Paths.Offers}>
            <Button className="w-max" category={ButtonCategory.Filled}>Find an Offer</Button>
          </NavLink>
        </div>
      </div>

      {/* Pay and Get Paid */}
      <div className="relative flex max-md:flex-col max-md:gap-10 gap-[13%] py-56 justify-center h-full items-center px-[7%]">
        <div className="absolute inset-0" style={{ background: 'radial-gradient(circle at calc(70vw) center, #3D6EFF66, black calc(450px)' }} />

        <div className="flex flex-col gap-5 flex-1 max-w-lg z-10">
          <h3 className="mb-3">Pay and Get Paid</h3>
          <p className="mb-8 leading-[28px]">
            50+ secure and convenient payment methods to choose from, including gift cards, digital money, and crypto.
          </p>
        </div>
        <img className="w-[50%] max-md:w-[80%] max-w-[450px] z-10" src={logosImage} alt="" />
      </div>

      {/* Frequently Asked Questions */}
      <div id="faq" className="flex max-md:flex-col gap-14 justify-center h-full items-center px-[7%]">
        <div className="flex flex-col items-center w-full max-w-4xl">
          <h3 className="mb-20">Frequently Asked Questions</h3>
          {questionList.map(({ title, content }, index) => (
            <Dropdown title={title} content={content} className={`flex flex-col gap-2 w-full border-listItem/40 ${questionList.length !== index + 1 ? 'border-b' : ''} py-6`} />
          ))}
        </div>
      </div>

    </div>
  );
};
