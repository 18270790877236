import { AppElement, AppFC } from '../../../interfaces';
import { TextAreaProps } from './types';

export const TextArea: AppFC<TextAreaProps> = ({
  className,
  error,
  onChange,
  ...rest
}): AppElement => (
  <div className={`relative text-primary ${className}`}>
    <textarea
      className={`relative px-4 py-3 w-full h-24 resize-none rounded-xl focus:outline-none placeholder-red text-primary border ${error ? 'border-error bg-error focus:bg-error' : 'border-default bg-input/40 focus:bg-inputFocus/40'} ${className}`}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
      }}
      {...rest}
    />
    {!!error && <p className="absolute w-full text-[10px] leading-6 text-end text-error">{error.message}</p>}
  </div>
);
