import { AppElement, AppFC } from '../../../interfaces';

export const AppLogo: AppFC = (props): AppElement => (
  <svg {...props} width="176" height="32" viewBox="0 0 176 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10_780)">
      <path d="M23.3333 10.3218C23.3333 10.4891 23.5536 10.5686 23.6724 10.4505C24.0854 10.0397 24.5813 9.72137 25.1602 9.49542C25.8769 9.20634 26.6868 9.05301 27.5898 9.03543C27.6872 9.03354 27.7663 9.11257 27.7663 9.20982V12.9914C27.7663 13.0966 27.6743 13.1782 27.5694 13.1676C27.2344 13.1332 26.9508 13.1161 26.719 13.1161C25.7284 13.1161 24.949 13.3834 24.3807 13.9179C23.8286 14.4526 23.5526 15.2706 23.5526 16.3723V22.2468C23.5526 22.3441 23.4735 22.4229 23.3761 22.4229H19.1012C19.0038 22.4229 18.9248 22.3441 18.9248 22.2468V9.42852C18.9248 9.33126 19.0038 9.25242 19.1012 9.25242H23.1568C23.2543 9.25242 23.3333 9.33126 23.3333 9.42852V10.3218Z" fill="white" />
      <path d="M43.1869 9.25244C43.2844 9.25244 43.3635 9.33128 43.3635 9.42854V20.0172C43.3635 22.4634 42.7058 24.294 41.3905 25.5089C40.0915 26.7402 38.216 27.3557 35.7641 27.3557C34.4813 27.3557 33.2797 27.2099 32.1593 26.9184C31.1127 26.642 30.2121 26.2419 29.4573 25.7182C29.3857 25.6685 29.3634 25.5734 29.4037 25.4961L30.9127 22.5942C30.9649 22.4939 31.0967 22.4679 31.1867 22.5364C31.6753 22.908 32.2675 23.2024 32.963 23.4192C33.7587 23.6784 34.5462 23.8079 35.3256 23.8079C36.4948 23.8079 37.3554 23.5406 37.9075 23.0061C38.4595 22.4715 38.7357 21.6777 38.7357 20.6247C38.7357 20.5147 38.6023 20.457 38.5184 20.5283C37.5666 21.3377 36.4048 21.7425 35.0333 21.7425C33.3284 21.7425 31.9643 21.2403 30.9414 20.236C29.9347 19.2315 29.4312 17.725 29.4312 15.7161V9.42854C29.4312 9.33128 29.5103 9.25244 29.6077 9.25244H33.8826C33.98 9.25244 34.059 9.33128 34.059 9.42854V14.9629C34.059 15.9673 34.2458 16.6963 34.6192 17.1498C34.9928 17.6034 35.5368 17.8302 36.2512 17.8302C36.9819 17.8302 37.5746 17.571 38.0292 17.0526C38.5002 16.5343 38.7357 15.7567 38.7357 14.7199V9.42854C38.7357 9.33128 38.8147 9.25244 38.9121 9.25244H43.1869Z" fill="white" />
      <path d="M54.4064 9.03369C55.608 9.03369 56.6959 9.31719 57.6702 9.88418C58.6607 10.435 59.4401 11.2287 60.0085 12.2655C60.593 13.2862 60.8853 14.4768 60.8853 15.8376C60.8853 17.1984 60.593 18.3972 60.0085 19.4339C59.4401 20.4545 58.6607 21.2483 57.6702 21.8153C56.6959 22.3662 55.608 22.6415 54.4064 22.6415C53.0361 22.6415 51.9157 22.2773 51.0452 21.5487C50.9241 21.4474 50.7285 21.5293 50.7285 21.687V26.9608C50.7285 27.0581 50.6494 27.137 50.552 27.137H46.2771C46.1797 27.137 46.1006 27.0581 46.1006 26.9608V9.42849C46.1006 9.33122 46.1797 9.25238 46.2771 9.25238H50.3327C50.4302 9.25238 50.5092 9.33122 50.5092 9.42849V10.0817C50.5092 10.246 50.722 10.3267 50.8427 10.2149C51.6922 9.42743 52.8802 9.03369 54.4064 9.03369ZM53.4321 18.9723C54.244 18.9723 54.9097 18.6969 55.4294 18.1461C55.9489 17.579 56.2087 16.8096 56.2087 15.8376C56.2087 14.8656 55.9489 14.1042 55.4294 13.5534C54.9097 12.9865 54.244 12.703 53.4321 12.703C52.6202 12.703 51.9545 12.9865 51.4348 13.5534C50.9152 14.1042 50.6553 14.8656 50.6553 15.8376C50.6553 16.8096 50.9152 17.579 51.4348 18.1461C51.9545 18.6969 52.6202 18.9723 53.4321 18.9723Z" fill="white" />
      <path d="M71.2463 21.7552C71.2738 21.833 71.2438 21.9198 71.1719 21.9607C70.8054 22.1686 70.367 22.3308 69.8564 22.4473C69.3042 22.5769 68.7116 22.6417 68.0783 22.6417C66.3571 22.6417 65.0336 22.2204 64.1082 21.378C63.1988 20.5356 62.7441 19.2802 62.7441 17.6117V6.48836C62.7441 6.3911 62.8231 6.31226 62.9207 6.31226H67.1954C67.2929 6.31226 67.372 6.3911 67.372 6.48836V9.56241C67.372 9.65968 67.451 9.73852 67.5485 9.73852H70.1914C70.2889 9.73852 70.3679 9.81736 70.3679 9.91462V13.0129C70.3679 13.1102 70.2889 13.189 70.1914 13.189H67.5485C67.451 13.189 67.372 13.2679 67.372 13.3652V17.563C67.372 18.049 67.4937 18.4216 67.7373 18.6808C67.9809 18.94 68.3137 19.0696 68.736 19.0696C69.188 19.0696 69.5938 18.9716 69.9533 18.7756C70.0549 18.7203 70.1864 18.763 70.225 18.8719L71.2463 21.7552Z" fill="white" />
      <path d="M79.0695 22.6415C77.6568 22.6415 76.3821 22.35 75.2455 21.7668C74.1088 21.1835 73.2157 20.3735 72.5661 19.3368C71.9329 18.3 71.6162 17.1255 71.6162 15.8133C71.6162 14.5173 71.9329 13.351 72.5661 12.3141C73.2157 11.2773 74.1006 10.4755 75.2211 9.90848C76.3577 9.32528 77.6406 9.03369 79.0695 9.03369C80.4984 9.03369 81.7812 9.32528 82.9179 9.90848C84.0545 10.4755 84.9395 11.2773 85.5728 12.3141C86.2061 13.3348 86.5228 14.5011 86.5228 15.8133C86.5228 17.1255 86.2061 18.3 85.5728 19.3368C84.9395 20.3735 84.0545 21.1835 82.9179 21.7668C81.7812 22.35 80.4984 22.6415 79.0695 22.6415ZM79.0695 18.9723C79.8814 18.9723 80.5471 18.6969 81.0668 18.1461C81.5864 17.579 81.8461 16.8015 81.8461 15.8133C81.8461 14.8413 81.5864 14.0799 81.0668 13.5292C80.5471 12.9783 79.8814 12.703 79.0695 12.703C78.2576 12.703 77.5919 12.9783 77.0722 13.5292C76.5526 14.0799 76.2928 14.8413 76.2928 15.8133C76.2928 16.8015 76.5526 17.579 77.0722 18.1461C77.5919 18.6969 78.2576 18.9723 79.0695 18.9723Z" fill="white" />
      <path d="M93.5622 22.6417C91.9383 22.6417 90.6718 22.2286 89.7624 21.4024C88.8693 20.56 88.4229 19.3612 88.4229 17.806V4.56868C88.4229 4.47142 88.5019 4.39258 88.5994 4.39258H92.8741C92.9717 4.39258 93.0507 4.47142 93.0507 4.56868V17.563C93.0507 18.049 93.1724 18.4216 93.4159 18.6808C93.6758 18.94 94.033 19.0696 94.4878 19.0696C94.7684 19.0696 95.0353 19.0238 95.2885 18.9324C95.4078 18.8892 95.5429 18.9677 95.5501 19.094L95.7223 22.1661C95.7268 22.2459 95.6769 22.3189 95.6003 22.3422C94.9424 22.5418 94.263 22.6417 93.5622 22.6417Z" fill="white" />
      <path d="M103.533 22.6415C102.121 22.6415 100.846 22.35 99.7092 21.7668C98.5725 21.1835 97.6795 20.3735 97.03 19.3368C96.3966 18.3 96.0801 17.1255 96.0801 15.8133C96.0801 14.5173 96.3966 13.351 97.03 12.3141C97.6795 11.2773 98.5644 10.4755 99.6849 9.90848C100.821 9.32528 102.104 9.03369 103.533 9.03369C104.962 9.03369 106.245 9.32528 107.382 9.90848C108.518 10.4755 109.403 11.2773 110.037 12.3141C110.67 13.3348 110.986 14.5011 110.986 15.8133C110.986 17.1255 110.67 18.3 110.037 19.3368C109.403 20.3735 108.518 21.1835 107.382 21.7668C106.245 22.35 104.962 22.6415 103.533 22.6415ZM103.533 18.9723C104.345 18.9723 105.011 18.6969 105.531 18.1461C106.05 17.579 106.31 16.8015 106.31 15.8133C106.31 14.8413 106.05 14.0799 105.531 13.5292C105.011 12.9783 104.345 12.703 103.533 12.703C102.721 12.703 102.056 12.9783 101.536 13.5292C101.016 14.0799 100.757 14.8413 100.757 15.8133C100.757 16.8015 101.016 17.579 101.536 18.1461C102.056 18.6969 102.721 18.9723 103.533 18.9723Z" fill="white" />
      <path d="M119.634 22.6415C118.173 22.6415 116.865 22.35 115.712 21.7668C114.56 21.1835 113.659 20.3735 113.01 19.3368C112.376 18.3 112.059 17.1255 112.059 15.8133C112.059 14.5011 112.376 13.3348 113.01 12.3141C113.659 11.2773 114.56 10.4755 115.712 9.90848C116.865 9.32528 118.173 9.03369 119.634 9.03369C121.128 9.03369 122.419 9.35769 123.507 10.0057C124.547 10.6096 125.298 11.4431 125.758 12.5059C125.795 12.5895 125.758 12.686 125.677 12.7274L122.398 14.3966C122.311 14.4409 122.205 14.4058 122.157 14.3205C121.56 13.2421 120.711 12.703 119.61 12.703C118.781 12.703 118.091 12.9783 117.539 13.5292C117.004 14.0799 116.736 14.8413 116.736 15.8133C116.736 16.8015 117.004 17.579 117.539 18.1461C118.091 18.6969 118.781 18.9723 119.61 18.9723C120.711 18.9723 121.56 18.4331 122.157 17.3547C122.205 17.2694 122.311 17.2344 122.398 17.2787L125.677 18.9478C125.758 18.9892 125.795 19.0858 125.758 19.1693C125.298 20.2329 124.547 21.0744 123.507 21.6938C122.419 22.3256 121.128 22.6415 119.634 22.6415Z" fill="white" />
      <path d="M140.956 9.25238C141.054 9.25238 141.133 9.33122 141.133 9.42849V22.2467C141.133 22.344 141.054 22.4228 140.956 22.4228H136.901C136.803 22.4228 136.724 22.344 136.724 22.2467V21.5935C136.724 21.4292 136.511 21.3485 136.391 21.4603C135.542 22.2478 134.354 22.6415 132.828 22.6415C131.626 22.6415 130.529 22.3662 129.54 21.8153C128.565 21.2483 127.785 20.4465 127.2 19.4097C126.633 18.3728 126.349 17.1741 126.349 15.8133C126.349 14.4525 126.633 13.2618 127.2 12.2413C127.785 11.2045 128.565 10.4107 129.54 9.85988C130.529 9.30909 131.626 9.03369 132.828 9.03369C134.209 9.03369 135.329 9.39006 136.188 10.1028C136.31 10.2036 136.506 10.1215 136.506 9.96387V9.42849C136.506 9.33122 136.584 9.25238 136.682 9.25238H140.956ZM133.802 18.9723C134.614 18.9723 135.279 18.6969 135.798 18.1461C136.318 17.579 136.579 16.8015 136.579 15.8133C136.579 14.8413 136.318 14.0799 135.798 13.5292C135.279 12.9783 134.614 12.703 133.802 12.703C132.99 12.703 132.324 12.9783 131.804 13.5292C131.285 14.0799 131.025 14.8413 131.025 15.8133C131.025 16.8015 131.285 17.579 131.804 18.1461C132.324 18.6969 132.99 18.9723 133.802 18.9723Z" fill="white" />
      <path d="M149.008 22.6417C147.385 22.6417 146.118 22.2286 145.209 21.4024C144.315 20.56 143.869 19.3612 143.869 17.806V4.56868C143.869 4.47142 143.948 4.39258 144.045 4.39258H148.321C148.418 4.39258 148.497 4.47142 148.497 4.56868V17.563C148.497 18.049 148.618 18.4216 148.863 18.6808C149.122 18.94 149.48 19.0696 149.934 19.0696C150.215 19.0696 150.482 19.0238 150.735 18.9324C150.854 18.8892 150.99 18.9677 150.996 19.094L151.169 22.1661C151.174 22.2459 151.123 22.3189 151.047 22.3422C150.389 22.5418 149.709 22.6417 149.008 22.6417Z" fill="white" />
      <path d="M157.732 22.6417C156.109 22.6417 154.842 22.2286 153.933 21.4024C153.039 20.56 152.593 19.3612 152.593 17.806V4.56868C152.593 4.47142 152.672 4.39258 152.769 4.39258H157.044C157.141 4.39258 157.221 4.47142 157.221 4.56868V17.563C157.221 18.049 157.343 18.4216 157.587 18.6808C157.845 18.94 158.204 19.0696 158.658 19.0696C158.939 19.0696 159.205 19.0238 159.459 18.9324C159.578 18.8892 159.713 18.9677 159.72 19.094L159.893 22.1661C159.897 22.2459 159.848 22.3189 159.77 22.3422C159.113 22.5418 158.433 22.6417 157.732 22.6417Z" fill="white" />
      <path d="M175.024 9.25244C175.121 9.25244 175.2 9.33128 175.2 9.42854V20.0172C175.2 22.4634 174.542 24.294 173.228 25.5089C171.929 26.7402 170.053 27.3557 167.6 27.3557C166.318 27.3557 165.117 27.2099 163.996 26.9184C162.949 26.642 162.049 26.2419 161.294 25.7182C161.223 25.6685 161.201 25.5734 161.24 25.4961L162.75 22.5942C162.802 22.4939 162.934 22.4679 163.023 22.5364C163.512 22.908 164.104 23.2024 164.799 23.4192C165.595 23.6784 166.383 23.8079 167.162 23.8079C168.331 23.8079 169.193 23.5406 169.745 23.0061C170.297 22.4715 170.572 21.6777 170.572 20.6247C170.572 20.5147 170.439 20.457 170.355 20.5283C169.403 21.3377 168.242 21.7425 166.87 21.7425C165.165 21.7425 163.801 21.2403 162.778 20.236C161.771 19.2315 161.268 17.725 161.268 15.7161V9.42854C161.268 9.33128 161.347 9.25244 161.444 9.25244H165.719C165.816 9.25244 165.896 9.33128 165.896 9.42854V14.9629C165.896 15.9673 166.082 16.6963 166.455 17.1498C166.829 17.6034 167.373 17.8302 168.087 17.8302C168.819 17.8302 169.411 17.571 169.866 17.0526C170.336 16.5343 170.572 15.7567 170.572 14.7199V9.42854C170.572 9.33128 170.651 9.25244 170.748 9.25244H175.024Z" fill="white" />
      <path d="M8.81586 17.8102C10.593 17.8102 12.0964 16.6398 12.5947 15.0288C12.6267 14.9251 12.7342 14.8621 12.839 14.8905L17.1546 16.0627C17.2576 16.0907 17.319 16.1968 17.2895 16.2993C16.2309 19.9749 12.838 22.664 8.81586 22.664C6.98189 22.664 5.27871 22.1049 3.86801 21.1482C3.81389 21.1113 3.68433 21.1277 3.68433 21.1277L0.564215 22.664C0.405509 22.7273 0.100908 22.3371 0.164323 22.1786L1.47832 18.8956C1.50229 18.8356 1.4948 18.7679 1.45915 18.7141C0.536985 17.3232 0 15.6555 0 13.8625C0 9.00159 3.94699 5.06104 8.81586 5.06104C12.7931 5.06104 16.1553 7.69059 17.2532 11.3033C17.2845 11.4065 17.2233 11.5142 17.1191 11.5425L12.8002 12.7156C12.6977 12.7434 12.5923 12.6837 12.5579 12.5834C12.0256 11.0309 10.5513 9.91479 8.81586 9.91479C6.63203 9.91479 4.86169 11.6823 4.86169 13.8625C4.86169 16.0428 6.63203 17.8102 8.81586 17.8102Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_10_780">
        <rect width="175.2" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
