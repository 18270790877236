import moment from 'moment/moment';
import { AppElement, AppFC } from '../../../interfaces';
import { FundingStepsBoxProps } from './types';

import { Steps, StepTimer } from '../../Molecules';
import { Button, Card } from '../../Atoms';

import {
  ButtonCategory,
  TradeStatusTypes,
  buyerDisputeTime,
  sellerDisputeTime,
  TradeUserTypes,
} from '../../../constants';
import { ExclamationIcon } from '../../Atoms/icons';
import { getDurationBySeconds } from '../../../utils';

export const FundingSteps: AppFC<FundingStepsBoxProps> = ({
  tradeStatus,
  stepCount,
  currentStepInfo,
  startTime,
  endTime,
  type,
  stepAction,
  isLoading,
  disputeIsLoading,
}): AppElement => {
  const currentTime = new Date().toISOString();
  const currentTimeBySeconds = getDurationBySeconds(startTime, currentTime);
  const { title, description, step } = currentStepInfo;

  const userDisputeTime = type === TradeUserTypes.Buyer ? buyerDisputeTime : sellerDisputeTime;

  const currentDisputeTimeBySeconds = getDurationBySeconds(endTime, currentTime);
  const duration = getDurationBySeconds(startTime, endTime);
  const { label, action, openDispute } = stepAction;

  const isExpired = moment(currentTime).isAfter(moment(endTime));

  return (
    <Card className={`p-8 gap-5 w-full min-h-64 ${tradeStatus === TradeStatusTypes.Disputed ? 'bg-[#1B1514]' : ''}`}>
      {!isExpired && step && <Steps stepCount={stepCount} currentStep={step} />}
      <div className="flex flex-col items-center gap-2">
        <h3 className="text-center">{title}</h3>
        <span className="max-w-sm text-center">{description}</span>
      </div>
      {(type === TradeUserTypes.Buyer && step === 1) && (
      <div className="flex items-center gap-1 px-2 py-1 text-[#C56D73] border border-[#C56D73] rounded-lg">
        <ExclamationIcon className="h-4 w-4" />
        <h6>Don&apos;t send any funds before this status changes.</h6>
      </div>
      )}

      {
          currentDisputeTimeBySeconds <= userDisputeTime
          && tradeStatus === TradeStatusTypes.Paid
          && (
          <StepTimer
            description="left to open dispute"
            duration={userDisputeTime}
            currentTimeBySeconds={currentDisputeTimeBySeconds}
          />
          )
      }

      {!isExpired && currentTimeBySeconds <= duration && <StepTimer description="left to this stop" duration={duration} currentTimeBySeconds={currentTimeBySeconds} />}
      <div className="flex gap-2 [&>button]:min-w-[180px] max-xsm:flex-col">
        {
          label
          && (!isExpired || type === TradeUserTypes.Seller) && (
          <Button
            category={ButtonCategory.Filled}
            onClick={action}
            isLoading={isLoading}
          >
            {label}
          </Button>
          )
}
        {
          tradeStatus === TradeStatusTypes.Paid
          && currentDisputeTimeBySeconds > userDisputeTime
          && (
          <Button
            className="bg-status hover:bg-status"
            category={ButtonCategory.Filled}
            onClick={openDispute}
            isLoading={disputeIsLoading}
          >
            Open dispute
          </Button>
          )
}
      </div>
    </Card>
  );
};
