import React, { useEffect, useState } from 'react';
import {
  AppElement, AppFC, UserInterface, UserRatingInterface,
} from '../../../interfaces';
import { SellerInfoProps } from './types';
import { formattedDate, getCountryFlag, getRatingDetails } from '../../../utils';
import { useUser } from '../../../stores/hooks';
import { UserRatingCard } from '../UserRatingCard';
import filledStarIcon from '../../../assets/icons/stars/filledStar.svg';

export const UserInfo: AppFC<SellerInfoProps> = ({
  offer,
  className,
  ...rest
}): AppElement => {
  // const [isLoading, setIsLoading] = useState(true);
  const [ratings, setRatings] = useState<UserRatingInterface[]>([]);
  const [user, setUser] = useState<UserInterface>();
  const { getUserById, getUserRatingById } = useUser();

  const { country, userId } = offer;

  const { averageRate } = getRatingDetails(ratings);

  // const sellerTradesInfo = [{
  //   label: 'Successful trades',
  //   value: '400+',
  // },
  // {
  //   label: 'Overall volume',
  //   value: '~$1M',
  // },
  // {
  //   label: 'Trading partners',
  //   value: '50+',
  // }];

  useEffect(() => {
    Promise.all([getUserById(userId), getUserRatingById(userId)]).then((response) => {
      setUser(response[0]);
      setRatings(response[1]);
      // setIsLoading(false);
    }).catch(() => {
      // setIsLoading(false);
      // error handler
    });
  }, []);

  return (
    <>
      {user && (
      <div className={`relative flex flex-col gap-8 p-6 rounded-2xl border border-[#282F41] ${className || ''}`} {...rest}>
        <div className="flex items-center gap-3 h-10">
          <div className="flex items-center justify-center bg-blue rounded-full w-10 h-full">
            {user.userName?.substring(0, 1).toUpperCase()}
          </div>
          <div className="flex flex-col justify-between h-full">
            <div className="flex items-center gap-2">
              <h4 className="text-[18px] text-blue leading-none">{user.userName}</h4>
              {getCountryFlag(country, 'h-3')}
            </div>
            <div className="flex items-center gap-2 leading-none">
              <div className="group relative">
                <div className="flex items-start gap-1">
                  <img
                    src={filledStarIcon}
                    height={12}
                    width={12}
                    alt="filled"
                  />
                  <span className="leading-none text-white text-[13px]">{Number(averageRate).toFixed(1)}</span>
                </div>
                <UserRatingCard
                  className="scale-0 mt-2 group-hover:scale-100 transform transition-transform duration-100"
                  ratings={ratings}
                />
              </div>
              <span className="h-1 w-1 rounded-full bg-[#637592]" />
              <span className="text-secondary">
                since
                {' '}
                {formattedDate(user.createdAt)}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="flex items-center justify-between gap-2 text-secondary"> */}
        {/*   {sellerTradesInfo.map(({ label, value }) => ( */}
        {/*     <div className="flex flex-col"> */}
        {/*       <h4>{value}</h4> */}
        {/*       <span className="leading-none">{label}</span> */}
        {/*     </div> */}
        {/*   ))} */}
        {/* </div> */}
      </div>
      )}
    </>
  );
};
