import { ViewModalInterface } from './interfaces';

export const viewModalInitialState: ViewModalInterface = {
  isOpen: false,
  error: false,
  errorMessage: '',
  disabled: false,
  isClosable: true,
  isReload: false,
  isPrintable: false,
};
