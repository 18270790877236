import { useContext, useEffect, useState } from 'react';
import { Navigate, NavLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { jwtDecode } from 'jwt-decode';
import { AuthToken } from '../../../services';

import { AppElement, AppFC } from '../../../interfaces';
import {
  authRole, HeaderCategory, OfferStatusTypes, Paths,
} from '../../../constants';

import { Header } from './Header';
import { Footer } from './Footer';
import { Loader } from '../../Atoms';
import { useOffer, useProfile } from '../../../stores/hooks';
import { ProfileContext } from '../../../stores';

export const Layout: AppFC = ({ className, children }): AppElement => {
  const authToken: AuthToken = new AuthToken();
  let isAuth = !!authToken.getAccessToken() && !!authToken.getRefreshToken();
  if (authToken.getAccessToken()) {
    const decodedToken = jwtDecode(authToken.getAccessToken());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (decodedToken[authRole] === 'Admin') {
      isAuth = false;
    }
  }

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false);
  const [notificationMenuIsOpen, setNotificationMenuIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    profileState: {
      id,
      email,
    },
  } = useContext(ProfileContext);
  const { getProfile } = useProfile();
  const { getOffers } = useOffer();

  const { pathname } = useLocation();

  useEffect(() => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    }
    if (profileMenuIsOpen) {
      setProfileMenuIsOpen(false);
    }
    if (notificationMenuIsOpen) {
      setNotificationMenuIsOpen(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuth && !email) {
      setIsLoading(true);
      Promise.all([
        getProfile(),
        getOffers({ UserId: id }),
        getOffers({ UserId: id, OfferStatus: OfferStatusTypes.Draft }),
      ]).then(() => {
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [authToken.getAccessToken()]);

  const isNotGuides = pathname !== Paths.PrivacyPolicy
      && pathname !== Paths.TermsOfUse
      && pathname !== Paths.GuideCreateOffer
      && pathname !== Paths.GuideBuyCrypto
      && pathname !== Paths.GuideSellCrypto;

  const isGuides = pathname === Paths.GuideCreateOffer
      || pathname === Paths.GuideBuyCrypto
      || pathname === Paths.GuideSellCrypto;

  const isLanding = pathname === Paths.Home;
  const isFooter = pathname !== Paths.OfferCreate
      && pathname !== Paths.Trade
      && pathname !== Paths.OfferBuy
      && pathname !== Paths.OfferSell
      && pathname !== Paths.ProfileEdit;

  return (
    <div className={`relative min-h-screen w-full flex flex-col ${isAuth ? 'bg-black justify-center' : 'bg-landingLayout bg-black justify-end max-sm:justify-center'} bg-cover bg-auth ${menuIsOpen ? 'overflow-hidden' : 'overflow-x-hidden'} ${className}`}>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header
        category={HeaderCategory.App}
        menuIsOpen={menuIsOpen}
        toggleMenu={setMenuIsOpen}
        profileMenuIsOpen={profileMenuIsOpen}
        toggleProfileMenu={setProfileMenuIsOpen}
        notificationMenuIsOpen={notificationMenuIsOpen}
        toggleNotificationMenu={setNotificationMenuIsOpen}
        isAuth={isAuth}
      />
      {isLoading ? <Loader className="self-center justify-self-center" />
        : (
          <div className={`flex flex-col justify-center flex-1 ${menuIsOpen ? 'fixed' : ''} ${isNotGuides && !isLanding ? 'px-[7%] py-24' : 'pt-24'} h-fit ${!isAuth || isGuides || isLanding ? '' : 'max-lg:px-[3%]'} w-full z-10`}>
            {isGuides && (
            <div className={`flex flex-col bg-white text-black pt-14 pb-10  ${!isAuth ? 'px-[7%]' : 'px-[3%]'} [&_p]:text-[1.25rem] [&_p]:leading-[2.25rem] [&_p]:font-normal `}>
              <h2 className="font-semibold mb-2">Guides</h2>
              <p className="mb-6">Learn how to use the platform.</p>
              <div className={`flex gap-2 [&_a]:px-6 [&_a]:py-5 [&_a]:rounded-lg  [&_a]:font-semibold [&_a]:text-[1rem] [&_a]:whitespace-nowrap flex-nowrap overflow-y-hidden w-screen ${!isAuth ? '-ml-[7%] px-[7%]' : '-ml-[3%] px-[3%]'}`}>
                <NavLink className={`${pathname === Paths.GuideCreateOffer ? 'bg-blue text-white' : 'bg-[#f2f5f9] text-black'}`} to={Paths.GuideCreateOffer}>Creating Offers</NavLink>
                <NavLink className={`${pathname === Paths.GuideBuyCrypto ? 'bg-blue text-white' : 'bg-[#f2f5f9] text-black'}`} to={Paths.GuideBuyCrypto}>Buying Crypto</NavLink>
                <NavLink className={`${pathname === Paths.GuideSellCrypto ? 'bg-blue text-white' : 'bg-[#f2f5f9] text-black'}`} to={Paths.GuideSellCrypto}>Selling Crypto</NavLink>
              </div>
            </div>
            )}
            <>
              {!isAuth && pathname !== Paths.Offers && pathname !== Paths.Home && isNotGuides
                ? <Navigate to={Paths.Home} />
                : children}
            </>
          </div>
        )}
      {isFooter && <Footer isAuth={isAuth} />}
    </div>
  );
};
