import { viewLoaderInitialState } from './initialState';

import { ViewLoaderActionTypes } from './actions';
import { ViewLoaderInterface, ViewLoaderReducerInterface } from './interfaces';

export const viewLoaderReducer: ViewLoaderReducerInterface = (
  state = { ...viewLoaderInitialState },
  action,
): ViewLoaderInterface => {
  switch (action.type) {
    case ViewLoaderActionTypes.OpenLoader:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        message: action.payload.message ? action.payload.message : '',
      };

    case ViewLoaderActionTypes.UpdateLoader:
      return { ...state, isOpen: action.payload };

    case ViewLoaderActionTypes.UpdateMessage:
      return { ...state, message: action.payload.message };

    case ViewLoaderActionTypes.OpenWithoutText:
      return {
        ...state, message: '', isOpen: true,
      };

    default:
      return state;
  }
};
