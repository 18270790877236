import { RequestActionTypes } from './actions';
import { UseReducerType } from '../../interfaces';
import { RequestActionType, RequestInterface } from './interfaces';

export const requestReducer: UseReducerType<RequestInterface, RequestActionType> = (
  state,
  action,
): RequestInterface => {
  switch (action.type) {
    case RequestActionTypes.UpdateRequestStatus:
      return { ...state, status: action.payload.status };
    case RequestActionTypes.UpdateResponseState:
      return { ...state, responseData: action.payload.data };
    case RequestActionTypes.UpdateSuccessMessage:
      return { ...state, successMessage: action.payload.message };
    case RequestActionTypes.UpdateErrorMessage:
      return { ...state, errorMessage: action.payload.message };
    case RequestActionTypes.ClearErrorMessage:
      return { ...state, errorMessage: '' };
    default:
      return state;
  }
};
