import { OfferActionTypes } from './actions';
import { UseReducerType } from '../../interfaces';
import { OfferActionType, OffersInterface } from './interfaces';
import { offerInitialState } from './initialState';

export const offerReducer: UseReducerType<OffersInterface, OfferActionType> = (
  state,
  action,
): OffersInterface => {
  switch (action.type) {
    case OfferActionTypes.GetPublishedOffers:
      return { ...state, publishedOffers: action.payload };
    case OfferActionTypes.GetDraftOffers:
      return { ...state, draftOffers: action.payload };
    case OfferActionTypes.UpdatePublishedOffers:
      return { ...state, publishedOffers: [...state.publishedOffers, action.payload] };
    case OfferActionTypes.UpdateDraftOffers:
      return { ...state, draftOffers: [...state.draftOffers, action.payload] };
    case OfferActionTypes.UpdateOfferFilter:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case OfferActionTypes.UpdateCryptoCurrencyRates:
      return { ...state, currencyRate: action.payload };
    case OfferActionTypes.ClearOffers:
      return offerInitialState;

    default:
      return state;
  }
};
