import { AppElement, AppFC } from '../../../interfaces';
import { ButtonProps } from './types';
import { ButtonCategory } from '../../../constants';
import { Loader } from '../Loader';

export const Button: AppFC<ButtonProps> = ({
  className,
  category,
  isLoading,
  children,
  disabled,
  type,
  ...props
}): AppElement => {
  let classes = '';
  if (category === ButtonCategory.Outlined) {
    classes = 'bg-transparent border border-blue text-tertiary hover:bg-primary hover:text-white';
  }
  if (category === ButtonCategory.Filled) {
    classes = `bg-primary text-white ${disabled ? 'opacity-40' : 'hover:bg-primaryHover'}`;
  }
  if (category === ButtonCategory.Default) {
    classes = `bg-secondary text-white ${disabled ? 'opacity-40' : 'hover:bg-secondaryHover'}`;
  }

  return (
    <button {...props} type={type || 'button'} className={`${classes} ${className}`} disabled={isLoading || disabled}>
      {isLoading ? <Loader /> : children}
    </button>
  );
};
