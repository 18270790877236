import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const Card: AppFC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...rest
}): AppElement => (
  <div {...rest} className={`flex flex-col items-center justify-center bg-card rounded-2xl cursor-default ${className || ''} `}>
    {children}
  </div>
);
