import { HTMLAttributes, useState } from 'react';
import ReactSelect from 'react-select';

import { AppElement, AppFC } from '../../../interfaces';
import {
  BlockedIcon, CloseIcon, DropdownIcon, // SearchIcon,
} from '../icons';
import { SelectProps } from './types';

import { Button } from '../Button';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#0f1625', // bg-input
    borderRadius: '0.5rem',
    borderColor: '#B8C3FF14', // border-default
    height: '3rem',
    // margin: '0.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: 'white',
    cursor: 'text',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#B8C3FF3D', // border-checkbox
    },
    ':focus': {
      borderColor: '#B8C3FF3D', // border-checkbox
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#7B8BA5', // text-disabled
  }),
  container: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderRadius: '0.5rem',
    top: '-3rem',
  }),
  menu: (provided: any) => ({
    ...provided,
    // className: 'border border-[#B8C3FF3D]',
    position: 'relative',
    backgroundColor: '#0f1625',
    overflow: 'hidden',
    border: '1px #B8C3FF14 solid',
    // borderTop: '1px #B8C3FF3D solid', // checkbox color
    boxShadow: 'none',
    borderRadius: '0.5rem',
    width: '100%',
    // maxHeight: '200px',
    // top: '-3rem',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3D6EFF' : 'transparent', // bg-primary
    '&:hover': {
      backgroundColor: !state?.isSelected ? '#2955D6' : '#3D6EFF', // bg-primaryHover : bg-primary
    },
    fontSize: '14px',
    color: 'white',
    width: '100%',
    cursor: 'pointer',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    color: 'white',
    backgroundColor: 'transparent',
    fontSize: '14px',
    height: '100%',
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};
const Menu: AppFC = ({ children }): AppElement => (
  <div className="absolute w-full rounded-lg -t-[3rem] z-[20]">
    {children}
  </div>
);

export const Blanket: AppFC<HTMLAttributes<HTMLDivElement>> = (props): AppElement => (
  <div className="bottom-0 left-0 top-0 right-0 fixed z-[1]" {...props} />
);

export const SearchSelect: AppFC<SelectProps> = ({
  name,
  options,
  value,
  defaultValue,
  placeholder,
  disabled,
  blocked,
  onChange,
  error,
}): AppElement => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownIndicator = () => (isOpen ? <CloseIcon size="16" onClick={() => { setIsOpen(false); }} /> : <DropdownIcon />);

  return (
    <div className="w-full relative">
      <Button
        className={`
         h-12
         ${blocked || disabled ? '' : 'cursor-pointer'}
         ${error ? 'border-error bg-error focus:bg-error' : 'border-default focus:border-focus bg-input focus:bg-inputFocus'}
          border
          w-full
          flex items-center justify-between gap-2 
          rounded-lg
          px-4
        `}
        onClick={() => { setIsOpen(!isOpen); }}
        disabled={blocked || disabled}
      >
        <input name={name} type="hidden" value={value} />
        {value ? <span className="flex-1 text-left">{options.find((option) => option.value === value)?.label}</span> : <span className={placeholder?.includes('All ') ? '' : 'text-disabled'}>{placeholder}</span>}
        {blocked ? <BlockedIcon /> : <DropdownIcon />}
      </Button>
      {isOpen && (
        <>
          <Menu>
            <ReactSelect
              className="w-full"
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator: dropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuPlacement="auto"
              menuIsOpen
              onChange={(newValue) => {
                if (onChange) {
                  onChange(newValue!.value);
                }
                setIsOpen(false);
              }}
              defaultValue={options.find((option) => option.value === defaultValue)}
              value={options.find((option) => option.value === value)}
              options={options}
              placeholder="Type to search..."
              styles={customStyles}
              tabSelectsValue={false}
            />
          </Menu>
          <Blanket onClick={() => {
            setIsOpen(false);
          }}
          />
        </>
      )}
      {!!error && <p className="absolute w-full text-[10px] leading-6 text-end text-error">{error.message}</p>}
    </div>
  );
};
