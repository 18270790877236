import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { TableOne } from '../../components/Tables';
import { AppElement, AppFC, DisputeInterface } from '../../../../../interfaces';
import { useTrade } from '../../../../../stores/hooks';
import { DisputeStatusTypes } from '../../../../../constants';

const Disputes: AppFC = (): AppElement => {
  const [disputes, setDisputes] = useState<DisputeInterface[]>();
  const [currentDisputes, setCurrentDisputes] = useState<DisputeInterface[]>();
  const { getDisputes } = useTrade();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  useEffect(() => {
    getDisputes().then((res) => {
      setDisputes(res.sort((a: any, b: any) => (moment(a.disputeCreatedAt).isAfter(moment(b.disputeCreatedAt)) ? -1 : 1)));
    });
  }, []);

  useEffect(() => {
    if (disputes) {
      const filteredDisputes = status ? disputes.filter((dispute: DisputeInterface) => dispute.disputeStatus === status) : disputes;
      setCurrentDisputes(filteredDisputes);
    }
  }, [status, disputes]);

  const getStatusTitle = (disputeStatus: DisputeStatusTypes) => {
    switch (disputeStatus) {
      case DisputeStatusTypes.Open:
        return 'Open';
      case DisputeStatusTypes.Rejected:
        return 'Refunded';
      case DisputeStatusTypes.Approved:
        return 'Released';
      default:
        return '';
    }
  };

  return (
    <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
      <div className="col-span-12 xl:col-span-8">
        <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
          {getStatusTitle(status as DisputeStatusTypes)}
          {' '}
          Disputes
        </h4>
        <TableOne disputes={currentDisputes} />
      </div>
    </div>
  );
};

export default Disputes;
