import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Header from '../components/Header/index';
import Sidebar from '../components/Sidebar/index';
import { AuthToken } from '../../../../services';
import { AdminPaths, authRole } from '../../../../constants';
import { AppFC } from '../../../../interfaces';

export const DefaultLayout: AppFC = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken: AuthToken = new AuthToken();
  let isAuth = false;
  if (authToken.getAccessToken()) {
    const decodedToken = jwtDecode(authToken.getAccessToken());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (decodedToken[authRole] === 'Admin') {
      isAuth = true;
    }
  }

  const { pathname } = useLocation();

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        {isAuth && <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />}
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          {isAuth && <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />}
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main className="min-h-full">
            <div className="h-full flex flex-col gap-5 mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              {!isAuth
                ? (
                  <>
                    {pathname !== AdminPaths.SignIn
                      ? <Navigate to={AdminPaths.SignIn} />
                      : children}
                  </>
                )
                : children}
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};
