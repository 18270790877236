import { useContext } from 'react';

import { httpRequest } from '../../services';

import { PAYMENT_API, RequestTypes } from '../../constants';
import { PaymentActionTypes, PaymentContext } from '../contexts';

export const usePayment = () => {
  const { METHODS } = PAYMENT_API;

  const { updatePaymentState } = useContext(PaymentContext);

  const getPaymentMethods = async () => {
    const response = await httpRequest({
      url: METHODS,
      method: RequestTypes.Get,
      withoutToken: true,
    });

    updatePaymentState({
      type: PaymentActionTypes.UpdatePayment,
      payload: response.data,
    });

    return response.data;
  };

  return ({
    getPaymentMethods,
  });
};
