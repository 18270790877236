import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { AuthToken } from '../../../services';

export const ListItem: AppFC<HTMLAttributes<HTMLDivElement>> = ({
  children, className, ...props
}): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = authToken.getAccessToken() && authToken.getRefreshToken();

  return (
    <div {...props} className={`w-full flex max-md:flex-col items-center py-4 border-b border-listItem/40 ${isAuth ? 'hover:bg-card' : ''} px-5 ${className || ''}`}>
      {children}
    </div>
  );
};
