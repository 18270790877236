import { AppElement, AppFC } from '../../../../interfaces';
import { AuthToken } from '../../../../services';
import signUpImage from '../../../../assets/images/guides/1. Sign up.png';
import signUpCompleteImage from '../../../../assets/images/guides/2. Complete account.png';
import buySellImage from '../../../../assets/images/guides/11. Buy and Sell.png';
import buyOfferImage from '../../../../assets/images/guides/12. Select Buy in Offers.png';
import openTradeImage from '../../../../assets/images/guides/13. Trade Open.png';
import escrowImage from '../../../../assets/images/guides/14. Wait for escrow funding.png';
import paySellerImage from '../../../../assets/images/guides/15. Pay the Seller.png';
import releaseEscrowImage from '../../../../assets/images/guides/16. Wait for seller to release escrow.png';
import tradeCompleteImage from '../../../../assets/images/guides/17. Trade Completed.png';

export const GuideBuyCrypto: AppFC = (): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = !!authToken.getAccessToken() && !!authToken.getRefreshToken();

  return (
    <div className="flex flex-col flex-1 h-auto w-screen bg-white text-black [&_h3]:leading-[2rem] [&_p]:text-[1.25rem] [&_p]:leading-[2.25rem] [&_p]:font-normal [&_a]:text-[1.25rem] [&_a]:leading-[2.25rem] [&_a]:font-normal [&_a]:text-tertiary">
      <div className={`flex flex-col gap-10 pt-12 pb-16 bg-[#f2f5f9] ${!isAuth ? 'px-[7%]' : 'px-[3%]'} [&_img]:rounded-lg [&_img]:max-w-5xl [&_img]:w-full`}>
        <div className="">
          <h2 className="font-semibold mb-6">How to buy crypto</h2>
          <p>
            CryptoLocally is a platform designed for everyone, especially with our design overhaul. New users will find the transaction intuitive while experienced traders will appreciate the simple yet secure trade procedures. This guide will show you how to buy crypto, step-by-step.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">1. Sign Up</h3>
          <p>
            You must have an account for CryptoLocally to make an offer. Don’t worry, signing up on CryptoLocally is quick and simple.
          </p>
          <p>
            Input your email address and choose a CryptoLocally username and password. After verifying your email and entering some more basic information, you are ready to start trading on CryptoLocally!
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] mt-6 mb-12">
            <img src={signUpImage} alt="Sign up" />
            <span>
              Image 1. Creating an account
            </span>
          </div>
          <p>
            After verifying your email, you will be asked for some more basic information, like your name, country of residence, and phone number. Then, please enter the code we sent to your phone number. All verification helps build a basis of trust for new users.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={signUpCompleteImage} alt="Sign up complete" />
            <span>
              Image 2. Entering more basic information to sign up
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">2. Click on Buy & Sell</h3>
          <p>
            Click on “Buy & Sell” to view all the offers on the platform (see image 3).
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={buySellImage} alt="Buy & Sell" />
            <span>
              Image 3. Click “Buy & Sell”
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">3. Click on Buy and Select offer</h3>
          <p>
            Click on Buy on the left to view the list of users who want to sell cryptocurrency on the platform. You can filter offers by cryptocurrency, currency, location, and payment methods. Click on the “Buy” button on the offer to view more information about it (see image 4).
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={buyOfferImage} alt="Buy offer" />
            <span>
              Image 4. Show offers to buy
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">4. Open trade</h3>
          <p>
            In this example, let&apos;s assume you want to buy BTC, after you press the buy button you will be directed to the first step of the transaction: Open Trade (see image 5). During this step, you will see more information about the terms of the trade and trader information. If you do not agree with the terms, you can go back to the previous page and choose another offer.
          </p>
          <p>
            <b>To start the trade</b>
            , input the amount of BTC you want to buy and the wallet address you want it to be sent to. Once you have agreed to the seller&apos;s terms and confirmed that the wallet the crypto will be sent to is yours, click on Open Trade.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={openTradeImage} alt="Open trade" />
            <span>
              Image 5. Opening the trade
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">5. Funds escrow</h3>
          <p>
            On this step, you just have to wait for the seller to fund the escrow (see image 6). He has a one-hour time limit to do it. Please consider time zones if you are not trading with locals!
            Our unique smart contract escrow system protects all the trades on CryptoLocally until the end of each transaction The fund is held in the escrow in the name of the user temporarily. Then after the seller confirms that they have received the payment, the escrow is released and the tokens are sent to the buyer’s wallet. This process allows buyers and sellers to complete trades safely and trustfully.
          </p>
          <p>
            Keep in mind that the time of completing funding escrow depends mostly on the current network traffic.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={escrowImage} alt="Escrow funded" />
            <span>
              Image 6. Wait for escrow to be funded
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">6. Pay</h3>
          <p>
            At this stage, the seller has fully funded the escrow. It is now safe for you to pay the required amount to the seller. After completing the payment, click on
            {' '}
            <b>“Mark As Paid”</b>
            . Then, wait for him to release the escrow (see image 7). You have a time limit of 120 minutes to send the required amount.
          </p>
          <p>
            <b>DO NOT SEND</b>
            {' '}
            money outside the stipulated payment time.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={paySellerImage} alt="Pay the seller" />
            <span>
              Image 7. Pay the seller
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">7. Release escrow</h3>
          <p>
            Once the seller confirms that he/she received the payment, the seller will release the escrow so that you can receive your BTC. (see image 9).
          </p>
          <p>
            At this point, if the seller does not respond or does not release the escrow, you can open a dispute by clicking the “open dispute” button.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={releaseEscrowImage} alt="Release escrow" />
            <span>
              Image 8. Wait for seller to release escrow
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">8. Trade Completed</h3>
          <p>
            Congratulations! You just completed your first trade on CryptoLocally!
          </p>
          <p>
            You will now receive an email from CryptoLocally to confirm the transfer of BTC. Both parties are able to go back to check the status of the trade anytime by clicking on My Trades.
          </p>
          <p>
            Users are able to rate the completed trade by using the comment feature, to offer the other trader feedback. By rating other users and receiving ratings, you can grow your trust among other CryptoLocally users and build your reputation.
          </p>
          <p>
            We hope you enjoy trading on CryptoLocally! Check out our guides on how to sell crypto and create your offer.
          </p>
          <img className="self-center mt-4" src={tradeCompleteImage} alt="Trade complete" />
        </div>
      </div>
    </div>
  );
};
