import { deleteAxiosAuthorization, setAxiosAuthorization } from './utils';
import { TokenDataType } from './interfaces';

export class AuthToken {
  getAccessToken() { return localStorage.getItem('accessToken') || ''; }

  getRefreshToken() { return localStorage.getItem('refreshToken') || ''; }

  /** temporary * */
  setTokens(tokens: TokenDataType) {
    const { accessToken, refreshToken } = tokens;
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      setAxiosAuthorization(accessToken);
    }
  }

  clearTokens() {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    deleteAxiosAuthorization();
  }
}
