import { AppElement, AppFC } from '../../../interfaces';
import { AlertProps } from './types';
import { AlertCategory } from '../../../constants';

import { AlertErrorIcon, AlertSuccessIcon, AlertWarningIcon } from '../icons';
import closeIcon from '../../../assets/icons/close.svg';

export const Alert: AppFC<AlertProps> = ({
  className,
  category,
  title,
  description,
  isClosable,
  onClose,
  children,
}): AppElement => {
  const getIcon = () => {
    switch (category) {
      case AlertCategory.Error:
        return <AlertErrorIcon />;
      case AlertCategory.Warning:
        return <AlertWarningIcon />;
      case AlertCategory.Success:
        return <AlertSuccessIcon />;
      default:
        return <AlertErrorIcon />;
    }
  };

  return (
    <div className={`absolute top-24 max-w-[500px] flex-col items-center pl-3 pr-7 py-2 gap-3.5 bg-white rounded-xl ${className}`}>
      <div className="w-full flex gap-1.5 items-center">
        {isClosable && (
        <button
          onClick={(event) => {
            event.stopPropagation();
            if (onClose) onClose();
          }}
          className="absolute top-1 right-1 cursor-pointer p-0 h-auto"
        >
          <img src={closeIcon} alt="close" />
        </button>
        )}
        {getIcon()}
        <div className="flex flex-col text-black justify-center">
          <h6 className="font-semibold">{title}</h6>
          {description && <h6>{description}</h6>}
        </div>
      </div>
      {children}
    </div>
  );
};
