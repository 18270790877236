import { AppElement, AppFC } from '../../../interfaces';
import { ProgressBoxProps } from './types';

export const ProgressBox: AppFC<ProgressBoxProps> = ({
  className,
  totalAmount,
  currentAmount = 0,
  stars,
  persons,
}): AppElement => {
  const currentAmountPercent = ((currentAmount as number) * 100) / (totalAmount as number);

  return (
    <div className={`w-full flex items-center justify-between ${className || ''}`}>
      <div className="flex items-center gap-2">
        {stars && <span className="w-7 text-[10px] text-nowrap leading-none">{`${stars} star`}</span>}
        <div className="relative w-[150px] max-xsm:w-[100px] rounded-full h-1 overflow-hidden">
          <div className="absolute bottom-0 bg-[rgba(214,179,137,0.16)] h-1 w-full" />
          <div style={{ width: `${currentAmountPercent}%` }}>
            <div className="bg-progressGradient h-1 w-full rounded-full" />
          </div>
        </div>
      </div>
      {(persons || persons === 0) && <span className="text-[10px] text-nowrap leading-none">{`${persons} Persons`}</span>}
    </div>
  );
};
