import { AxiosHeaders } from 'axios';
import { httpRequest } from '../../services';
import { TradeCreateDataProps, DisputeCreateDataProps, DisputeResolveDataProps } from '../../services/httpRequest';

import { RequestTypes, TRADE_API } from '../../constants';

/** The hook returns trade handlers * */
export const useTrade = () => {
  const {
    CREATE,
    GET_TRADES,
    GET_DISPUTES,
  } = TRADE_API();

  const createTrade = async (data: TradeCreateDataProps) => {
    const response = await httpRequest({
      url: CREATE,
      method: RequestTypes.Post,
      data,
    });

    const tradeHeader = (response.headers as AxiosHeaders).get('Location');
    const headerArray = (tradeHeader as string)?.split('/');

    return headerArray[1]; // This is trade id
  };

  const getTrades = async () => {
    const response = await httpRequest({
      url: GET_TRADES,
      method: RequestTypes.Get,
    });
    return response.data;
  };

  const getTradeById = async (id: string) => httpRequest({
    url: TRADE_API(id).GET_TRADE,
    method: RequestTypes.Get,
  });

  const fundTrade = async (id: string) => httpRequest({
    url: TRADE_API(id).FUND_ESCROW,
    method: RequestTypes.Patch,
  });

  const markAsPaid = async (id: string) => httpRequest({
    url: TRADE_API(id).MARK_AS_PAID,
    method: RequestTypes.Patch,
  });

  const releaseEscrow = async (id: string) => httpRequest({
    url: TRADE_API(id).RELEASE_ESCROW,
    method: RequestTypes.Patch,
  });

  /** DISPUTES * */

  const createDispute = async (tradeId: string, data?: DisputeCreateDataProps) => {
    const response = await httpRequest({
      url: TRADE_API(tradeId).CREATE_DISPUTE,
      method: RequestTypes.Post,
      data: data || {},
    });

    const disputeHeader = (response.headers as AxiosHeaders).get('Location');
    const headerArray = (disputeHeader as string)?.split('/');
    return headerArray[headerArray.length - 1]; // This is trade id
  };

  const getTradeDisputes = async (tradeId: string) => {
    const response = await httpRequest({
      url: TRADE_API(tradeId).GET_TRADE_DISPUTES,
      method: RequestTypes.Get,
    });

    return response.data;
  };
  const getTradeDispute = async (tradeId: string) => {
    const response = await httpRequest({
      url: TRADE_API(tradeId).GET_TRADE_DISPUTE,
      method: RequestTypes.Get,
    });

    return response.data;
  };

  // only for admin
  const getDisputes = async () => {
    const response = await httpRequest({
      url: GET_DISPUTES,
      method: RequestTypes.Get,
    });

    return response.data;
  };

  const resolveDispute = async (data:DisputeResolveDataProps, tradeId: string) => httpRequest({
    url: TRADE_API(tradeId).RESOLVE_TRADE_DISPUTE,
    method: RequestTypes.Patch,
    data,
  });

  /** * */

  return ({
    createTrade,
    getTrades,
    getTradeById,
    fundTrade,
    markAsPaid,
    releaseEscrow,
    createDispute,
    getDisputes,
    getTradeDisputes,
    getTradeDispute,
    resolveDispute,
  });
};
