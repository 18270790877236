import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useWallet } from '../../../../../stores/hooks';
import { ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { WithdrawFundsDataProps } from './types';
import { ButtonCategory } from '../../../../../constants';

import { Button } from '../../../../Atoms';
import { FormInput } from '../../../../Molecules';

const validationSchema = yup.object()
  .shape({
    amount: yup
      .number().typeError('Amount must be a number.').min(0)
      .required('Please enter the amount you need to withdraw.'),
    address: yup
      .string()
      .required('Please enter your blockchain deposit address.'),
  });

export const WithdrawFunds: AppFC = ({ className }): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const { viewModal: { info }, updateViewModal } = useContext(ViewContext);

  const { wallet } = info || {};

  const { withdraw } = useWallet();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const onSubmit: SubmitHandler<WithdrawFundsDataProps> = (data) => {
    setIsLoading(true);

    if (wallet) {
      withdraw({
        address: data.address,
        amount: data.amount,
      }, wallet.id).then(() => {
        updateViewModal({
          type: ViewModalActionTypes.SuccessModal,
          payload: {
            title: 'Success',
            description: 'Funds successfully withdrawn',
          },
        });
      }).catch(() => {
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Funds Withdrawal Failed',
          },
        });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`flex flex-col gap-8 items-center px-20 py-14 max-[400px]:px-8 my-auto ${className}`}>
      <h4>Withdrawal</h4>
      <p className="mt-2 text-center max-w-md">Provide your wallet details to withdraw funds from Cryptolocally Portal</p>
      <div className="flex flex-col gap-8 w-full max-w-[500px] mt-8">
        <FormInput
          label="Amount"
          name="amount"
          // type="number"
          control={control}
          placeholder="0.00"
          error={errors.amount}
        />
        <FormInput
          label="Blockchain deposit address"
          name="address"
          control={control}
          placeholder="Deposit address"
          error={errors.address}
        />
      </div>
      <div className="w-full flex justify-center gap-2 mt-6 [&>button]:w-[180px] max-[400px]:flex-col max-[400px]:[&>button]:w-full">
        <Button type="submit" category={ButtonCategory.Filled} isLoading={isLoading}>Withdraw</Button>
        <Button category={ButtonCategory.Default} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Cancel</Button>
      </div>
    </form>
  );
};
