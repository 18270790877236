import axios from 'axios';

import { AUTH_API } from '../../../constants';
import { AuthToken } from './AuthToken';

export const initAxiosInterceptors = () => {
  const authToken: AuthToken = new AuthToken();

  axios.interceptors.request.use((config) => {
    const newConfig = { ...config };
    newConfig.url = `${config.url}`;
    if (authToken.getAccessToken() && authToken.getRefreshToken() && !newConfig.headers.Authorization) {
      newConfig.headers.Authorization = `Bearer ${authToken.getAccessToken()}`;
    }
    return newConfig;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;

      if (!error || !error?.response) {
        return { message: 'cancel request' };
      }

      if (error?.response?.status === 404) {
        return { message: error.response.data.detail };
      }

      if (error.response?.status === 401 && originalRequest.url === AUTH_API.POST_REFRESH_TOKEN) {
        if (error.response.data.detail === 'Invalid access token or refresh token') {
          authToken.clearTokens();
          window.location.reload();
        }
      }

      if (error.response?.status === 401
          && authToken.getAccessToken()
          && authToken.getRefreshToken()
          && originalRequest.url !== AUTH_API.POST_SIGN_IN) {
        return axios.post(
          AUTH_API.POST_REFRESH_TOKEN,
          {
            accessToken: authToken.getAccessToken(),
            refreshToken: authToken.getRefreshToken(),
          },
          { headers: { Accept: 'application/json' } },
        )
          .then((response) => {
            authToken.setTokens({
              accessToken: response.data.access,
              refreshToken: response.data.refresh,
            });

            const originalRequestWithNewToken = {
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: `Bearer ${authToken.getAccessToken()}`,
              },
            };

            return axios(originalRequestWithNewToken);
          });
      }

      const newError = { ...error };
      return Promise.reject(newError);
    },
  );
};
