import { CryptoCurrencyTypes } from './types';

export const API_ROOT = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL.toString() : '';

export enum RequestStatusTypes {
  Waiting = 'Waiting',
  // Loading = 'Loading',
  // Success = 'Success',
  // Error = 'Error',
  // NetworkError = 'NetworkError',
  // UserSignOut = 'UserSignOut',
  // Cancel = 'Cancel',
  // NoData = 'NoData',
}

export enum RequestTypes {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH',
}

export const AUTH_API = {
  POST_SIGN_UP: `${API_ROOT}/auth/signup`,
  POST_EMAIL_VERIFICATION: `${API_ROOT}/auth/signup-confirm`,
  POST_EMAIL_SEND: `${API_ROOT}/auth/signup-email`,
  POST_SIGN_IN: `${API_ROOT}/auth/login`,
  GET_SIGN_OUT: `${API_ROOT}/auth/logout`,
  GET_PROFILE: `${API_ROOT}/auth/profile`,
  PUT_PROFILE: `${API_ROOT}/auth/profile`,
  POST_PROFILE_IMAGE: `${API_ROOT}/auth/profile-image`,
  POST_RESET: `${API_ROOT}/auth/reset-password-email`,
  POST_RESET_CONFIRM: `${API_ROOT}/auth/reset-password`,
  POST_PASSWORD_CHANGE: `${API_ROOT}/auth/change-password`,
  POST_REFRESH_TOKEN: `${API_ROOT}/auth/refresh-token`,
};

export const PAYMENT_API = {
  METHODS: `${API_ROOT}/payment-methods`,
};
export const CHAT_API = {
  CREATE_FILE: `${API_ROOT}/chat-files`,
};

export const OFFER_API = {
  CREATE: `${API_ROOT}/offers`,
  GET: `${API_ROOT}/offers`,
  PUT: `${API_ROOT}/offers`,
  PATCH: `${API_ROOT}/offer`,
  DELETE: `${API_ROOT}/offers`,
};

export const USER_API = (userId?: string) => ({
  USERS: `${API_ROOT}/users/${userId}`,
  USER_RATINGS: `${API_ROOT}/users/${userId}/ratings`,
  RATINGS: `${API_ROOT}/ratings`,
});
export const CURRENCY_API = (currency?: CryptoCurrencyTypes) => ({
  CURRENCY_RATE: `${API_ROOT}/crypto-currencies/rates`,
  GET_GAS_FEE: `${API_ROOT}/gas-fees/${currency}`,
});

export const WALLET_API = (walletId?: string) => ({
  CREATE: `${API_ROOT}/wallets`,
  CREATE_WALLET_USER: `${API_ROOT}/wallet-users`,
  GET_WALLETS: `${API_ROOT}/wallets`,
  GET_WALLET: `${API_ROOT}/wallets/${walletId}`,
  GET_WALLET_BALANCE: `${API_ROOT}/wallets/${walletId}/balance`,
  WITHDRAW: `${API_ROOT}/wallets/${walletId}/withdraw-transactions`,
});

export const TRADE_API = (tradeId?: string) => ({
  CREATE: `${API_ROOT}/trades`,
  GET_TRADES: `${API_ROOT}/trades`,
  GET_TRADE: `${API_ROOT}/trades/${tradeId}`,
  // escrow
  FUND_ESCROW: `${API_ROOT}/trades/${tradeId}/fund-escrow`,
  MARK_AS_PAID: `${API_ROOT}/trades/${tradeId}/mark-as-paid`,
  RELEASE_ESCROW: `${API_ROOT}/trades/${tradeId}/release-escrow`,
  // disputes
  CREATE_DISPUTE: `${API_ROOT}/trades/${tradeId}/disputes`,
  GET_DISPUTES: `${API_ROOT}/trade-disputes`,
  GET_TRADE_DISPUTES: `${API_ROOT}/trades/${tradeId}/disputes`,
  GET_TRADE_DISPUTE: `${API_ROOT}/trades/${tradeId}/dispute`,
  RESOLVE_TRADE_DISPUTE: `${API_ROOT}/trades/${tradeId}/dispute-resolve`,
});
export const NOTIFICATION_API = (notificationId?: string) => ({
  GET_NOTIFICATIONS: `${API_ROOT}/notifications`,
  GET_NOTIFICATION: `${API_ROOT}/notifications/${notificationId}`,
  MARK_AS_READ: `${API_ROOT}/notifications/${notificationId}/mark-as-read`,
});

export const GRAPHQL_CHAT_API = {
  REGION: 'us-east-1',
  DEFAULT_AUTH_MODE: 'lambda',
};

export const availableCurrencies = [
  'USD',
  'CAD',
  'EUR',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ARS',
  'AUD',
  'AZN',
  'BAM',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BND',
  'BOB',
  'BRL',
  'BWP',
  'BYN',
  'BZD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ERN',
  'ETB',
  'GBP',
  'GEL',
  'GHS',
  'GNF',
  'GTQ',
  'HKD',
  'HNL',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KHR',
  'KMF',
  'KRW',
  'KWD',
  'KZT',
  'LBP',
  'LKR',
  'LTL',
  'LVL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MOP',
  'MUR',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SDG',
  'SEK',
  'SGD',
  'SOS',
  'SYP',
  'THB',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'XAF',
  'XOF',
  'YER',
  'ZAR',
  'ZMK',
  'ZWL',
];
