import { Controller } from 'react-hook-form';

import { AppElement, AppFC } from '../../../interfaces';
import { FormSelectProps } from './types';

import { Label, Select } from '../../Atoms';

export const FormSelect: AppFC<FormSelectProps> = ({
  className,
  label,
  name,
  control,
  error,
  options,
  children,
  ...props
}): AppElement => (
  <Label className={className}>
    {!!label && <h6 className={`${error ? 'text-error' : ''}`}>{label}</h6>}
    <Controller
      name={name}
      control={control}
      render={({
        field,
      }) => (
        <Select
          options={options}
          error={error}
          {...field}
          {...props}
        />
      )}
    />
  </Label>
);
