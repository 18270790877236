import { ReactElement } from 'react';
import { CryptoCurrencyTypes, OfferTypes } from './types';

import btcIcon from '../assets/icons/currencies/BTC.svg';
import wbtcIcon from '../assets/icons/currencies/WBTC.svg';
import ethIcon from '../assets/icons/currencies/ETH.svg';
import usdcIcon from '../assets/icons/currencies/USDC.svg';
import usdtIcon from '../assets/icons/currencies/USDT.svg';
import binanceIcon from '../assets/icons/currencies/Binance.svg';

export const currencyOptions: { value: CryptoCurrencyTypes, label: ReactElement }[] = [
  {
    value: CryptoCurrencyTypes.BTC,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={btcIcon} alt="" />
        {CryptoCurrencyTypes.BTC}
      </span>
    ),
  },
  {
    value: CryptoCurrencyTypes.WBTC,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={wbtcIcon} alt="" />
        {CryptoCurrencyTypes.WBTC}
      </span>
    ),
  },
  {
    value: CryptoCurrencyTypes.ETH,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={ethIcon} alt="" />
        ETH
      </span>
    ),
  },
  {
    value: CryptoCurrencyTypes.USDC,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={usdcIcon} alt="" />
        USDC
      </span>
    ),
  },
  {
    value: CryptoCurrencyTypes.USDT,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={usdtIcon} alt="" />
        USDT
      </span>
    ),
  },
];

export const getCurrencyOptions = ():{ value: CryptoCurrencyTypes, label: ReactElement }[] => ([
  {
    value: CryptoCurrencyTypes.BTC,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={btcIcon} alt="" />
        {CryptoCurrencyTypes.BTC}
      </span>
    ),
  },
  {
    value: CryptoCurrencyTypes.WBTC,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={wbtcIcon} alt="" />
        {CryptoCurrencyTypes.WBTC}
      </span>
    ),
  },
  {
    value: CryptoCurrencyTypes.ETH,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={ethIcon} alt="" />
        ETH
      </span>
    ),
  },
  {
    value: CryptoCurrencyTypes.USDC,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={usdcIcon} alt="" />
        USDC
      </span>
    ),
  },
  {
    value: CryptoCurrencyTypes.USDT,
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={usdtIcon} alt="" />
        USDT
      </span>
    ),
  },
]);

export const currencyMarketOptions: { value: string, label: ReactElement }[] = [
  {
    value: 'Coinbase',
    label: (
      <span className="flex h-full items-center gap-2 [&>img]:w-6">
        <img src={binanceIcon} alt="" />
        <div className="flex gap-2 items-end [&>span]:leading-[100%]">
          <span>Binance</span>
          <span className="text-disabled text-[12px]">BTC/USDT</span>
        </div>

      </span>
    ),
  },
];

export const createOfferOptions: { value: OfferTypes, label: string }[] = [{
  value: OfferTypes.Buy,
  label: 'Buy',
},
{
  value: OfferTypes.Sell,
  label: 'Sell',
}];

export const filterOfferOptions: { value: OfferTypes, label: string }[] = [
  {
    value: OfferTypes.Buy,
    label: 'Buy',
  },
  {
    value: OfferTypes.Sell,
    label: 'Sell',
  },
];

export const offerOptions: { value: OfferTypes, label: string }[] = [{
  value: OfferTypes.All,
  label: 'All',
},
{
  value: OfferTypes.Buy,
  label: 'Buy',
},
{
  value: OfferTypes.Sell,
  label: 'Sell',
}];

export const tradeTimes: { value: number, label: string }[] = [{
  value: 30,
  label: '30m',
},
{
  value: 60,
  label: '1h',
},
{
  value: 120,
  label: '2h',
}, {
  value: 180,
  label: '3h',
}];
