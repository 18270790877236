import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const DropdownIcon: AppFC<HTMLAttributes<HTMLDivElement>> = ({ className, ...rest }): AppElement => (
  <div className={`${className} cursor-pointer ml-2`} {...rest}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.27356 5.30038C3.44894 5.12364 3.68669 5.02437 3.93457 5.02437C4.18245 5.02437 4.42019 5.12364 4.59558 5.30038L7.98792 8.72317L11.3803 5.30038C11.4659 5.20766 11.5692 5.13329 11.6839 5.0817C11.7987 5.03012 11.9225 5.00238 12.0481 5.00015C12.1737 4.99791 12.2985 5.02122 12.4149 5.06869C12.5314 5.11616 12.6372 5.18681 12.726 5.27643C12.8149 5.36605 12.8849 5.4728 12.9319 5.59032C12.979 5.70783 13.0021 5.83371 12.9999 5.96043C12.9976 6.08715 12.9701 6.21213 12.919 6.3279C12.8679 6.44367 12.7942 6.54786 12.7023 6.63426L8.64893 10.724C8.47354 10.9007 8.2358 11 7.98792 11C7.74004 11 7.5023 10.9007 7.32691 10.724L3.27356 6.63426C3.09839 6.4573 3 6.21743 3 5.96732C3 5.71722 3.09839 5.47734 3.27356 5.30038Z" fill="currentColor" />
    </svg>
  </div>

);
