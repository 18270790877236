import { ViewModalInterface, ViewModalReducerInterface } from './interfaces';
import { viewModalInitialState } from './initialState';
import { ViewModalActionTypes } from './actions';
import { ModalTypes } from '../../../../constants';

export const viewModalReducer: ViewModalReducerInterface = (
  state = { ...viewModalInitialState },
  action,
): ViewModalInterface => {
  switch (action.type) {
    case ViewModalActionTypes.ImageUploadModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.ImageUploadModal,
      };

    case ViewModalActionTypes.WithdrawModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.WithdrawModal,
      };

    case ViewModalActionTypes.QrCodeModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.QrCodeModal,
      };

    case ViewModalActionTypes.DeleteModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.DeleteModal,
      };

    case ViewModalActionTypes.SuccessModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.SuccessModal,
      };

    case ViewModalActionTypes.FailModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.FailModal,
      };

    case ViewModalActionTypes.DefaultModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.DefaultModal,
      };

    case ViewModalActionTypes.Close:
      return {
        ...viewModalInitialState,
      };

    default:
      return state;
  }
};
