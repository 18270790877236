export enum OfferTypes {
  All = 'All',
  Buy = 'Buy',
  Sell = 'Sell',
}
export enum OfferStatusTypes {
  Open = 'Open',
  Draft = 'Draft',
  Closed = 'Closed',
}

export enum TradeStatusTypes {
  Open = 'Open',
  Funded = 'Funded',
  Paid = 'Paid',
  Released = 'Released',
  Disputed = 'Disputed',
  Refunded = 'Refunded',
  Completed = 'Completed',
}
export enum TradeUserTypes {
  Buyer = 'Buyer',
  Seller = 'Seller',
}

export enum DisputeStatusTypes {
  Open = 'Open',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
export enum CryptoCurrencyTypes {
  BTC = 'BTC',
  WBTC = 'WBTC',
  ETH = 'ETH',
  USDC = 'USDC',
  USDT = 'USDT',
}

export enum NetworkTypes {
  BITCOIN = 'BITCOIN',
  ETHEREUM = 'ETHEREUM',
  ARBITRUM = 'ARBITRUM',
}

export enum MarketTypes {
  Above = 'above',
  Below = 'below',
  None = '',
}

export enum RatingGradeTypes {
  One = 'One',
  Two = 'Two',
  Three = 'Three',
  Four = 'Four',
  Five = 'Five',
}

export enum PaymentMethodTypes {
  BankTransfer = 'Bank Transfer',
  CashPayment = 'Cash Payment',
  OnlineWallet = 'Online Wallet',
  PrepaidGiftCard = 'Prepaid Gift Card',
  RetailsGiftCard = 'Retails Gift Card',
}
