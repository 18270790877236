import { useContext } from 'react';

import { httpRequest } from '../../services';
import { HttpRequestHeaderTypes, ProfileUpdateDataProps } from '../../services/httpRequest';

import { ProfileActionTypes, ProfileContext } from '../contexts';

import { AUTH_API, RequestTypes } from '../../constants';

export const useProfile = () => {
  const {
    GET_PROFILE,
    PUT_PROFILE,
    POST_PROFILE_IMAGE,
  } = AUTH_API;

  const { updateProfileState } = useContext(ProfileContext);

  const getProfile = async () => {
    const response = await httpRequest({
      url: GET_PROFILE,
      method: RequestTypes.Get,
    });

    updateProfileState({
      type: ProfileActionTypes.UpdateProfile,
      payload: response.data,
    });

    return response.data;
  };

  const updateProfile = async (data: ProfileUpdateDataProps) => {
    const response = await httpRequest({
      url: PUT_PROFILE,
      method: RequestTypes.Put,
      data,
    });

    if (response.status === 200) {
      updateProfileState({
        type: ProfileActionTypes.UpdateProfile,
        payload: data,
      });
    }

    return response.data;
  };

  const updateProfileImage = async (data: FormData) => httpRequest({
    url: POST_PROFILE_IMAGE,
    method: RequestTypes.Patch,
    data,
    headerType: HttpRequestHeaderTypes.MultiPart,
  });

  const getIP = async () => {
    const response = await fetch('https://api.ipify.org/?format=json').then((res) => res.json());
    return response.ip;
  };

  return ({
    getProfile,
    updateProfile,
    updateProfileImage,
    getIP,
  });
};
