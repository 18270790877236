import { AppElement, AppFC } from '../../../interfaces';
import { CurrencyPriceBoxProps } from './types';

import infoIcon from '../../../assets/icons/info.svg';

export const CurrencyPriceBox: AppFC<CurrencyPriceBoxProps> = ({
  price,
  margin,
  marketType,
  info, // popup not implemented yet
  className,
  ...rest
}): AppElement => (
  <div {...rest} className={`flex flex-col gap-1 ${className || ''}`}>
    <h5 className="w-max">{price}</h5>
    <div className="flex items-center gap-1 text-secondary">
      <h6 className="leading-none">{`${margin}% ${marketType}`}</h6>
      <div className="relative group">
        <img src={infoIcon} alt="" />
        <div className="bottom-5 right-0 rounded-lg text-white text-[13px] scale-0 mt-2 group-hover:scale-100 transform transition-transform duration-100
             w-[230px] max-xsm:w-[200px] absolute z-50 bg-default border border-white/[0.08] p-4"
        >
          {info}
        </div>
      </div>
    </div>
  </div>
);
