import React, { ChangeEvent, useContext, useRef } from 'react';

import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../stores';

import { AppElement, AppFC } from '../../../interfaces';

import { Avatar } from '../../Atoms';

export const ProfileImage: AppFC = (): AppElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    profileState: {
      userName,
      profileImage,
    },
  } = useContext(ProfileContext);
  const { updateViewModal } = useContext(ViewContext);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      updateViewModal({
        type: ViewModalActionTypes.ImageUploadModal,
        payload: {
          isClosable: false,
          file,
        },
      });
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  return (
    <div className="flex items-center gap-6 w-fit self-start">
      {!profileImage ? (
        <h3 className="w-16 h-16 flex items-center justify-center bg-primary rounded-[50%]">
          {userName?.substring(0, 1).toUpperCase()}
        </h3>
      )
        : <Avatar src={profileImage} size={16} />}
      <div className="relative cursor-pointer" onClick={() => inputRef.current?.click()}>
        <span className="underline text-tertiary">Upload Photo</span>
        <input ref={inputRef} className="absolute bottom-0 opacity-0 w-full h-full -z-10 p-0 m-0" name="profileImage" type="file" accept="image/*" onChange={handleImageChange} />
      </div>
    </div>
  );
};
