import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const QrIcon: AppFC<HTMLAttributes<HTMLDivElement>> = ({ className, ...rest }): AppElement => (
  <div className={`cursor-pointer ${className || ''}`} {...rest}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.4375 3.47577C2.4375 2.90652 2.8995 2.44452 3.46875 2.44452H7.59375C8.163 2.44452 8.625 2.90652 8.625 3.47577V7.60077C8.625 8.17002 8.163 8.63202 7.59375 8.63202H3.46875C3.19525 8.63202 2.93294 8.52337 2.73955 8.32997C2.54615 8.13658 2.4375 7.87427 2.4375 7.60077V3.47577ZM2.4375 12.4133C2.4375 11.844 2.8995 11.382 3.46875 11.382H7.59375C8.163 11.382 8.625 11.844 8.625 12.4133V16.5383C8.625 17.1075 8.163 17.5695 7.59375 17.5695H3.46875C3.19525 17.5695 2.93294 17.4609 2.73955 17.2675C2.54615 17.0741 2.4375 16.8118 2.4375 16.5383V12.4133ZM11.375 3.47577C11.375 2.90652 11.837 2.44452 12.4063 2.44452H16.5313C17.1005 2.44452 17.5625 2.90652 17.5625 3.47577V7.60077C17.5625 8.17002 17.1005 8.63202 16.5313 8.63202H12.4063C12.1327 8.63202 11.8704 8.52337 11.677 8.32997C11.4836 8.13658 11.375 7.87427 11.375 7.60077V3.47577Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.1875 5.19452H5.875V5.88202H5.1875V5.19452ZM5.1875 14.132H5.875V14.8195H5.1875V14.132ZM14.125 5.19452H14.8125V5.88202H14.125V5.19452ZM11.375 11.382H12.0625V12.0695H11.375V11.382ZM11.375 16.882H12.0625V17.5695H11.375V16.882ZM16.875 11.382H17.5625V12.0695H16.875V11.382ZM16.875 16.882H17.5625V17.5695H16.875V16.882ZM14.125 14.132H14.8125V14.8195H14.125V14.132Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);
