import { useMemo, useReducer, createContext } from 'react';

import { paymentReducer } from './reducer';
import { AppElement, AppFC } from '../../../interfaces';
import {
  ContextProviderPropsInterface,
  UseReducerType,
} from '../../interfaces';
import {
  PaymentActionType,
  PaymentContextInterface,
  PaymentInterface,
} from './interfaces';

import { paymentInitialState } from './initialState';

export const PaymentContext = createContext<PaymentContextInterface>({} as PaymentContextInterface);

export const PaymentContextProvider: AppFC<ContextProviderPropsInterface> = ({ children }): AppElement => {
  const [paymentState, updatePaymentState] = useReducer<
  UseReducerType<PaymentInterface, PaymentActionType>
  >(paymentReducer, paymentInitialState);

  const value = useMemo(() => ({
    paymentState,
    updatePaymentState,
  }), [paymentState]);

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
};
