import React from 'react';
import { Controller } from 'react-hook-form';

import { AppElement, AppFC } from '../../../interfaces';
import { FormSwitchProps } from './types';

import { Switch } from '../../Atoms';

export const FormSwitch: AppFC<FormSwitchProps> = ({
  label,
  control,
  name,
  error,
  children,
  className,
  ...rest
}): AppElement => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <div className={`relative ${className || ''}`}>
        {!!label && <h6 className={`${error ? 'text-error' : ''}`}>{label}</h6>}
        <Switch
          error={error}
          {...field}
          {...rest}
        />
        {!!error && <p className="absolute w-full text-[10px] leading-7 text-error">{error.message}</p>}
      </div>

    )}
  />
);
