/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../ChatAPI";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listRooms = /* GraphQL */ `query ListRooms($limit: Int) {
  listRooms(limit: $limit) {
    items {
      id
      name
      userId
      messages {
        items {
          id
          userId
          createdAt
          updatedAt
          roomId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRoomsQueryVariables, APITypes.ListRoomsQuery>;
export const getRoom = /* GraphQL */ `query GetRoom($name: String!) {
  getRoom(name: $name) {
    id
    name
    userId
    messages {
      items {
        id
        content {
          text
          imageId
          __typename
        }
        userId
        createdAt
        updatedAt
        roomId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoomQueryVariables, APITypes.GetRoomQuery>;
export const listMessagesForRoom = /* GraphQL */ `query ListMessagesForRoom($roomId: ID!, $sortDirection: ModelSortDirection) {
  listMessagesForRoom(roomId: $roomId, sortDirection: $sortDirection) {
    items {
      id
      content {
        text
        imageId
        __typename
      }
      userId
      createdAt
      updatedAt
      roomId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesForRoomQueryVariables,
  APITypes.ListMessagesForRoomQuery
>;
