import { useContext } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { PaymentMethodBoxProps } from './types';
import { PaymentContext } from '../../../stores/contexts';

export const PaymentMethodBox: AppFC<PaymentMethodBoxProps> = ({
  className,
  methods,
  label,
  ...rest
}): AppElement => {
  const { paymentState: { methods: paymentMethods } } = useContext(PaymentContext);
  const currentMethod = paymentMethods.find(({ name }) => name === methods[0]);

  return (
    <div {...rest} className={`flex flex-col gap-2 ${className || ''}`}>
      {label && (
      <h6 className="text-secondary leading-none">
        {label}
      </h6>
      )}
      <div className="flex flex-wrap gap-1 text-black [&>span]:flex [&>span]:items-center [&>span]:rounded-[0.25rem] [&>span]:bg-white">
        <span className="px-2">{currentMethod?.value}</span>
        {methods[1] && <span className="px-2">{methods[1]}</span>}
        {methods.length > 2 && (
        <span className="px-1.5">
          +
          {methods.length - 2}
        </span>
        )}
      </div>
    </div>
  );
};
