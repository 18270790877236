import data from './countries.json';
import {
  availableCurrencies,
  currencyIcons,
  CryptoCurrencyTypes,
  TradeStatusTypes,
  TradeUserTypes,
} from '../constants';
import { AppElement } from '../interfaces';
import { numberWithCommas } from './main';

import btcIcon from '../assets/icons/currencies/BTC.svg';
import wbtcIcon from '../assets/icons/currencies/WBTC.svg';
import ethIcon from '../assets/icons/currencies/ETH.svg';
import usdcIcon from '../assets/icons/currencies/USDC.svg';
import usdtIcon from '../assets/icons/currencies/USDT.svg';

import { PaymentMethodInterface } from '../stores/contexts/PaymentContext/interfaces';

// filter(({ isoAlpha3 }) => isoAlpha3 !== 'USA')
export const getCountries = () => data.map(({ name, flag }) => ({
  value: name,
  label: (
    <div className="flex items-center gap-3 w-full">
      <img src={`data:image/png;base64,${flag}`} alt="" />
      <span>
        {name}
      </span>
    </div>
  ),
}));

export const getCountryFlag = (country: string, className?: string) => {
  const flag = data.find(({ name }) => country === name)?.flag;
  return <img className={className || ''} src={flag ? `data:image/png;base64,${flag}` : ''} alt="" />;
};

export const getCurrencies = () => (data
  .filter(({ name, currency: { code } }) => availableCurrencies.includes(code) && !((code === 'USD') && (name !== 'United States'))))
  .map(({ flag, name, currency: { code, name: currencyName } }) => ({
    value: code + currencyName + name,
    label: (
      <div className="flex items-center gap-3 w-full">
        {flag && <img src={`data:image/png;base64,${flag}`} alt="" />}
        <span>
          {`${code} - ${currencyName} (${name})`}
        </span>
      </div>
    ),
  }));

export const getCurrencyIcon = (currency: CryptoCurrencyTypes) => {
  switch (currency) {
    case CryptoCurrencyTypes.BTC:
      return <img src={btcIcon} alt="" />;
    case CryptoCurrencyTypes.WBTC:
      return <img src={wbtcIcon} alt="" />;
    case CryptoCurrencyTypes.ETH:
      return <img src={ethIcon} alt="" />;
    case CryptoCurrencyTypes.USDC:
      return <img src={usdcIcon} alt="" />;
    case CryptoCurrencyTypes.USDT:
      return <img src={usdtIcon} alt="" />;
    default: return <></>;
  }
};

export const generateOfferedCurrencyBox = (value: CryptoCurrencyTypes) => (
  <div className="flex items-center gap-1">
    <span className="w-4 h-4">{getCurrencyIcon(value)}</span>
    <h5 className="text-[12px] leading-none">{value}</h5>
  </div>
);

export const generateDemandedCurrencyBox = (value: string) => {
  const country = data.find(({ currency: { code } }) => value === code);
  const usaFlag = data.find(({ isoAlpha3 }) => isoAlpha3 === 'USA')?.flag;

  if (country) {
    return (
      <div className="flex items-center gap-1">
        <span className="w-4"><img src={`data:image/png;base64,${country.currency.code === 'USD' ? usaFlag : country.flag}`} alt="" /></span>
        <h5 className="text-[12px] leading-none">{value}</h5>
      </div>
    );
  }
  return <></>;
};

export const generateCountryBox = (value: string) => {
  const country = data.find(({ name }) => value === name);
  if (country) {
    return (
      <div className="flex items-center gap-2">
        <img className="h-3" src={`data:image/png;base64,${country.flag}`} alt="" />
        <span className="rounded-sm w-auto">{value}</span>
      </div>
    );
  }
  return <></>;
};

interface CurrencyOptionInterface {
  value: CryptoCurrencyTypes,
  label: AppElement,
}
export const getCurrencyOptions = (balances:{ [key: string]:number | null }[] = [{}]): CurrencyOptionInterface[] => balances.map((balance) => {
  const currency = Object.keys(balance)[0];
  const currencyBalance = Object.values(balance)[0];
  return {
    value: Object.keys(balance)[0] as CryptoCurrencyTypes,
    label: (
      <span className="flex h-full w-full p-3 items-center gap-2 text-white [&>img]:w-10">
        <img src={currencyIcons[currency]} alt="" />
        <div className={`h-full flex flex-col ${currencyBalance !== undefined ? 'justify-around' : 'justify-center'} flex-1`}>
          <h5 className="leading-none">{currency}</h5>
          {(currencyBalance !== undefined) && <span className="leading-none">{numberWithCommas(Number(currencyBalance), '', Number(currencyBalance) ? 5 : 2)}</span>}
        </div>
      </span>
    ),
  };
});

export const getPaymentMethodOptions = (methods: PaymentMethodInterface[]) => methods.map(({ name, value }) => ({
  value: name,
  label: value,
}));

export interface TradeStepInfoInterface {
  title: string,
  description: string,
  step?: number,
}
export const getTradeStepInfo = (
  tradeUserType: TradeUserTypes,
  tradeStatus: TradeStatusTypes,
  offeredAmount:string,
  userName?: string,
):TradeStepInfoInterface => {
  if (tradeUserType === TradeUserTypes.Buyer) {
    switch (tradeStatus) {
      case TradeStatusTypes.Open:
        return {
          title: 'Trade is open',
          description: 'Wait for the seller to fund the escrow',
          step: 1,
        };
      case TradeStatusTypes.Funded:
        return {
          title: 'Trade is Funded. Please pay now.',
          description: `${offeredAmount} are locked in the escrow. It is now safe to pay via the accepted payment methods.`,
          step: 2,
        };
      case TradeStatusTypes.Paid:
        return {
          title: 'You marked trade paid. Wait for the escrow to be released.',
          description: `The Seller is now verifying your payment. Once it’s confirmed, ${offeredAmount} will be sent to your wallet.`,
          step: 3,
        };

      case TradeStatusTypes.Released:
        return {
          title: 'Trade is Released',
          description: `${offeredAmount} was sent to your CL wallet. As soon as the transaction is complete, you will be able to rate your trade experience.`,
          step: 4,
        };

      case TradeStatusTypes.Disputed:
        return {
          title: 'This trade is now in dispute',
          description: 'Disputes are processed in a live queue and a moderator will join the dispute chat shortly.',
        };

      default:
        return {} as TradeStepInfoInterface;
    }
  }
  if (tradeUserType === TradeUserTypes.Seller) {
    switch (tradeStatus) {
      case TradeStatusTypes.Open:
        return {
          title: 'Please, fund the escrow now',
          description: 'Deposit the assets into the escrow contract. The system will detect the deposit automatically.',
          step: 1,
        };
      case TradeStatusTypes.Funded:
        return {
          title: 'Escrow funded.Please wait for the payment. ',
          description: `${offeredAmount} are locked in the escrow. The Buyer needs to send you payment via agreed methods.`,
          step: 2,

        };
      case TradeStatusTypes.Paid:
        return {
          title: 'Trade is Paid. You can release the funds now.',
          description: 'Before releasing the funds, make sure that you\'ve received your money. Once you\'ve released your funds, the transaction is final.',
          step: 3,
        };

      case TradeStatusTypes.Released:
        return {
          title: 'Trade is Released ',
          description: `${offeredAmount} was sent to ${userName || 'Buyer'}’s CL wallet. As soon as the transaction is complete, you will be able to rate your trade experience.`,
          step: 4,
        };

      case TradeStatusTypes.Disputed:
        return {
          title: 'This trade is now in dispute',
          description: 'Disputes are processed in a live queue and a moderator will join the dispute chat shortly.',
        };

      default:
        return {} as TradeStepInfoInterface;
    }
  }
  return {} as TradeStepInfoInterface;
};
