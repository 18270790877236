import React, { useContext, useState } from 'react';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useProfile } from '../../../../stores/hooks';
import { OfferContext } from '../../../../stores/contexts';
import { ProfileContext } from '../../../../stores';

import { AppElement, AppFC } from '../../../../interfaces';
import { PersonalInfoProps } from './types';
import {
  AlertCategory, AuthPaths,
  ButtonCategory,
  Paths,
} from '../../../../constants';

import {
  Alert,
  Button,
} from '../../../Atoms';
import { FormInput, FormSearchSelect } from '../../../Molecules';
import { generateErrorAlert } from '../../helpers';
import { AuthToken } from '../../../../services';
import { getCountries } from '../../../../utils';

const validationSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  country: yup.string().required(),
});

export const PersonalInfo: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();
  const {
    profileState: {
      email,
      userName,
      fullName,
      country,
      loginCount = 1,
    },
  } = useContext(ProfileContext);
  const { offerState: { filters } } = useContext(OfferContext);

  const navigate = useNavigate();

  const authToken: AuthToken = new AuthToken();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: fullName?.firstName || '',
      lastName: fullName?.lastName || '',
      country: country || filters.country,
    },
  });

  const { updateProfile } = useProfile();

  const onSubmit: SubmitHandler<PersonalInfoProps> = (data) => {
    setIsLoading(true);
    const { firstName = '', lastName = '' } = data;
    const newData = {
      email: email!,
      userName: userName!,
      fullName: { firstName, lastName },
      country: data.country!,
      loginCount,
    };

    updateProfile(newData)
      .then(() => {
        setIsLoading(false);
        setAlert(
          <Alert
            category={AlertCategory.Success}
            title="Your Personal Info updated."
          />,
        );
        navigate(Paths.Offers);
      })
      .catch((reason) => {
        setIsLoading(false);
        generateErrorAlert(reason, 'Personal info update failed!', setAlert);
      });
  };

  if (!(authToken.getAccessToken() && authToken.getRefreshToken())) {
    return <Navigate to={AuthPaths.SignIn} />;
  }

  if (loginCount > 1) {
    return <Navigate to={Paths.Offers} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 h-full justify-center">
      {alert}
      <h4>Personal Info</h4>
      <div className="flex flex-col gap-6 justify-center w-full">
        <FormInput
          label="First Name"
          name="firstName"
          control={control}
          placeholder="Enter first name"
          error={errors.firstName}
        />
        <FormInput
          label="Last Name"
          name="lastName"
          control={control}
          placeholder="Enter last name"
          error={errors.lastName}
        />
        <FormSearchSelect
          name="country"
          label="Country"
          className="relative"
          options={getCountries()}
          control={control}
          placeholder="Select"
          error={errors.country}
        />
      </div>
      <Button
        className="w-[200px]"
        type="submit"
        category={ButtonCategory.Filled}
        isLoading={isLoading}
      >
        Save
      </Button>
      <div className="flex items-center gap-2 text-disabled">
        <NavLink to={Paths.Offers} className="underline text-tertiary">
          Skip for Now
        </NavLink>
      </div>
    </form>

  );
};
