import React from 'react';

import { AlertCategory } from '../../constants';
import { AppElement } from '../../interfaces';

import { Alert } from '../Atoms';

export const generateErrorAlert = (reason: any, title: string, setAlert: React.Dispatch<React.SetStateAction<AppElement | undefined>>) => {
  const { data: errorData } = reason.response;
  const errorObject = Object.values(errorData);
  const stringError = typeof errorObject?.[0] === 'string' ? errorObject[0] : '';
  const objectError = typeof errorObject?.[0] === 'object' ? Object.values(errorObject[0] || {})?.[0] : '';
  const errorMessage = errorData?.details?.[0]?.message || errorData.detail || errorData?.title || objectError || stringError || errorData?.message || '';
  setAlert(
    <Alert
      category={AlertCategory.Error}
      title={title}
      description={errorMessage}
    />,
  );
  setTimeout(() => {
    setAlert(null);
  }, 7000);
};
