import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useLocation, useNavigate } from 'react-router-dom';
import { AuthToken, OfferParamsType } from '../../../services/httpRequest';
import { useCurrency, useOffer } from '../../../stores/hooks';
import {
  OfferActionTypes, OfferContext, OfferFilterInterface, PaymentContext,
} from '../../../stores/contexts';

import { AppElement, AppFC } from '../../../interfaces';
import { OfferFiltersProps } from './types';
import {
  filterOfferOptions,
  ButtonCategory,
  Paths,
  CryptoCurrencyTypes,
  OfferStatusTypes,
  OfferTypes,
  getCurrencyOptions,
} from '../../../constants';
import {
  getCountries, getCurrencies, isEmpty, numberWithCommas,
} from '../../../utils';

import { FormSearchSelect, FormSelect, FormSwitch } from '../../Molecules';
import { Button, Card } from '../../Atoms';

import searchIcon from '../../../assets/icons/search.svg';
import growIcon from '../../../assets/icons/grow.svg';

import allCountries from '../../../utils/countries.json';

const validationSchema = yup.object().shape({
  offerType: yup.string().oneOf(Object.values(OfferTypes)),
  cryptoCurrency: yup.string()
    .oneOf([...Object.values(CryptoCurrencyTypes), '' as CryptoCurrencyTypes]),
  currencyCode: yup.string(),
  country: yup.string(),
  paymentMethodTypes: yup.array(),
});

export const OfferFilters: AppFC<OfferFiltersProps> = ({ className }): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = authToken.getAccessToken() && authToken.getRefreshToken();

  const [isLoading, setIsLoading] = useState(false);

  const { paymentState: { methods: allMethods = [] } } = useContext(PaymentContext);
  const { offerState: { filters, currencyRate: defaultRate }, updateOfferState } = useContext(OfferContext);
  const { getOffers } = useOffer();
  const { getCryptocurrencyRate } = useCurrency();
  const [currencyRate, setCurrencyRate] = useState(defaultRate);

  const {
    control,
    handleSubmit,
    formState: {
      errors,
      touchedFields,
      isDirty,
    },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...filters,
    },
  });

  const code = watch('currencyCode');
  const filterOfferType = watch('offerType');

  const currentCurrency = allCountries.find(({ name, currency }) => currency.code + currency.name + name === code)?.currency.code || '';

  const currentCryptoCurrency = watch('cryptoCurrency');

  // const isDirty = !isEmpty(dirtyFields);
  const isTouched = !isEmpty(touchedFields);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
    getCryptocurrencyRate(currentCurrency, currentCryptoCurrency).then((response) => {
      if (response) {
        updateOfferState({
          type: OfferActionTypes.UpdateCryptoCurrencyRates,
          payload: response,
        });
        setCurrencyRate(response);
      }
      setIsLoading(false);
    })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  console.log('filters', filters);
  const onSubmit: SubmitHandler<OfferFilterInterface> = (data) => {
    updateOfferState({
      type: OfferActionTypes.UpdateOfferFilter,
      payload: data,
    });
    if (pathname !== Paths.Offers) {
      navigate(Paths.Offers);
    } else {
      setIsLoading(true);
      const {
        offerType,
        cryptoCurrency,
        currencyCode,
        country,
        paymentMethodTypes,
      } = data;

      console.log('paymentMethodTypes', paymentMethodTypes);

      const currency = allCountries.find(({ name, currency: { name: currencyName, code: currentCurrencyCode } }) => currentCurrencyCode + currencyName + name === currencyCode)?.currency.code || '';
      const filterParams: OfferParamsType = {
        OfferType: offerType,
        OfferStatus: OfferStatusTypes.Open,
        CryptoCurrencyType: cryptoCurrency,
        CurrencyCodeType: currency,
        Country: country,
        PaymentMethodTypes: paymentMethodTypes,
      };

      console.log('currency, cryptoCurrency', currency, cryptoCurrency);

      Promise.all([
        getOffers(filterParams),
        getCryptocurrencyRate(currency, cryptoCurrency),
      ])
        .then((response) => {
          if (response[1]) {
            updateOfferState({
              type: OfferActionTypes.UpdateOfferFilter,
              payload: data,
            });
            updateOfferState({
              type: OfferActionTypes.UpdateCryptoCurrencyRates,
              payload: response[1],
            });
            setCurrencyRate(response[1]);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          // error handler
        });
    }
  };

  useEffect(() => {
    if ((isDirty || isTouched) && currentCurrency && currentCryptoCurrency) {
      getCryptocurrencyRate(currentCurrency, currentCryptoCurrency).then((res) => {
        setCurrencyRate(res);
      });
    } else {
      setCurrencyRate(defaultRate);
    }
  }, [code, currentCurrency, currentCryptoCurrency]);

  const methods = allMethods.map(({ name, value }) => ({ label: value, value: name }));
  const countries = getCountries();
  const currencies = getCurrencies();
  const cryptocurrencies = getCurrencyOptions();

  currencies.unshift({
    value: '',
    label: <span>All currencies</span>,
  });
  cryptocurrencies.unshift({
    value: '' as CryptoCurrencyTypes,
    label: <span>All cryptos</span>,
  });
  countries.unshift({
    value: '',
    label: <span>All countries</span>,
  });
  methods.unshift({
    value: '',
    label: 'All',
  });

  return (
    <Card className={`flex flex-col gap-6 p-6 h-fit w-full max-w-[420px] ${!isAuth ? 'bg-card/40 border border-card/20' : ''} ${className || ''}`}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col self-center items-center gap-8 w-full [&>button]:w-full">
        <FormSwitch
          className="w-full max-sm:w-[220px] [&>div]:w-full"
          name="offerType"
          control={control}
          items={filterOfferOptions}
          itemClass="font-semibold"
          setValue={(value) => {
            setValue('offerType', value as OfferTypes, { shouldValidate: true, shouldDirty: true });
          }}
          height="h-12"
          width="w-[50%]"
        />
        <div className="flex flex-col gap-3 w-full min-w-[280px]">
          <FormSelect
            name="paymentMethodTypes"
            className="relative"
            label={`What would you like to ${filterOfferType === OfferTypes.Buy ? 'buy' : 'sell'}?`}
            options={methods}
            onChange={(value) => setValue('paymentMethodTypes', [value], { shouldValidate: true, shouldDirty: true })}
            control={control}
            placeholder="All"
          />
          <FormSearchSelect
            name="currencyCode"
            className="relative"
            label="With what currency?"
            options={currencies}
            control={control}
            placeholder="All currencies"
            error={errors.currencyCode}
          />
          <FormSearchSelect
            name="country"
            label="From where?"
            className="relative"
            options={countries}
            control={control}
            placeholder="All Countries"
            error={errors.country}
          />
          <FormSearchSelect
            name="cryptoCurrency"
            className="relative"
            label={`What crypto ${filterOfferType === OfferTypes.Buy ? 'will you provide' : 'would you like to receive'}?`}
            options={cryptocurrencies}
            control={control}
            placeholder="All cryptos"
            error={errors.cryptoCurrency}
          />
          {currentCurrency && currentCryptoCurrency && (
          <div className="flex items-center gap-2">
            <h6 className="text-secondary">{`1 ${currentCryptoCurrency} = ${numberWithCommas(Number(currencyRate.rate), currencyRate.currency, 2)}`}</h6>
            <img src={growIcon} alt="" />
          </div>
          )}
        </div>

        <Button
          category={ButtonCategory.Filled}
          type="submit"
          name="publish"
          isLoading={isLoading}
        >
          <div className="w-full flex items-center justify-center gap-3">
            <img src={searchIcon} alt="" />
            <span>Search offers</span>
          </div>
        </Button>
      </form>
    </Card>
  );
};
