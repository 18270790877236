export enum ButtonCategory {
  Filled = 'filled',
  Outlined = 'Outlined',
  Default = 'Default',
}

export enum AlertCategory {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export enum InfoCategory {
  Error = 'error',
  Success = 'success',
}

export enum HeaderCategory {
  Auth = 'auth',
  App = 'app',
}

export enum ModalTypes {
  ImageUploadModal = 'ImageUploadModal',
  QrCodeModal = 'QrCodeModal',
  DeleteModal = 'DeleteModal',
  WithdrawModal = 'WithdrawModal',
  SuccessModal = 'SuccessModal',
  FailModal = 'FailModal',
  DefaultModal = 'DefaultModal',
}

export const cryptoCurrencyNames = {
  BTC: 'Bitcoin’s',
  WBTC: 'Wrapped Bitcoin’s',
  ETH: 'Ethereum’s',
  USDC: 'USDC',
  USDT: 'Tether’s',
};

export const buyerDisputeTime = 10800;
export const sellerDisputeTime = 3600;
export const minNotificationCount = 10;
export const authRole = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
