import {
  useContext,
  useState,
} from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { CopyBoxProps } from './types';

import { CopyIcon, QrIcon } from '../../Atoms/icons';
import { useCopyToClipboard } from '../../../utils';
import { ViewContext, ViewModalActionTypes } from '../../../stores';

export const CopyBox: AppFC<CopyBoxProps> = ({
  label,
  value,
  isQrCode,
  className,
  ...rest

}): AppElement => {
  const [copiedOpen, setCopiedOpen] = useState(false);
  const { updateViewModal } = useContext(ViewContext);

  const [copy] = useCopyToClipboard();
  const handleCopyClick = async () => {
    await copy(value as string).then((result) => {
      if (result) {
        setCopiedOpen(true);
        setTimeout(() => setCopiedOpen(false), 2000);
      }
    });
  };

  const handleQrClick = () => {
    updateViewModal({
      type: ViewModalActionTypes.QrCodeModal,
      payload: {
        info: { blockchainAddress: value.toString() },
        isClosable: false,
      },
    });
  };

  return (
    <div className={`relative w-full h-16 flex ${copiedOpen ? 'justify-center bg-inputFocus' : 'justify-between bg-input'} items-center gap-2 px-4 py-3 w-full h-12 rounded-lg border border-default ${className || ''}`} {...rest}>
      {
          copiedOpen ? <span className="justify-self-center text-lightGreen">Copied!</span>
            : (
              <>
                <div className="flex flex-col gap-3 overflow-hidden">
                  {!!label && <h6 className="text-default leading-none">{label}</h6>}
                  <span className="leading-none overflow-hidden overflow-ellipsis whitespace-nowrap">{value}</span>
                </div>
                <div className="flex items-center gap-4 text-lightGreen">
                  {isQrCode && <QrIcon onClick={handleQrClick} className="text-coldBrown" />}
                  <CopyIcon onClick={handleCopyClick} />
                </div>
              </>
            )
      }
    </div>
  );
};
