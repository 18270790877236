import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import {
  AppElement, AppFC, OfferInterface, UserInterface, UserRatingInterface,
} from '../../../interfaces';
import { TradeListItemProps } from './types';

import {
  ListItem, Loader, Status,
} from '../../Atoms';
import { CountryBox, OfferTypeBox, UsernameBox } from '../../Atoms/offerItems';
import { useOffer, useUser } from '../../../stores/hooks';
import {
  buyerDisputeTime,
  sellerDisputeTime,
  OfferStatusTypes,
  OfferTypes,
  TradeStatusTypes,
} from '../../../constants';
import {
  formattedDate, getDurationBySeconds, getRatingDetails, tradeIsCompleted,
} from '../../../utils';
import { ProfileContext } from '../../../stores';
import { StepTimer, UserRatingCard } from '../../Molecules';

export const TradeBox: AppFC<TradeListItemProps> = ({
  trade,
}): AppElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserInterface>();
  const [ratings, setRatings] = useState<UserRatingInterface[]>([]);
  const [offer, setOffer] = useState<OfferInterface>();
  const [disputeTime, setDisputeTime] = useState<number>();
  const { getOfferById } = useOffer();
  const { getUserById, getUserRatingById } = useUser();
  const { profileState: { id } } = useContext(ProfileContext);

  const {
    offerId,
    createdAt,
    endedAt,
    tradeStatus,
    buyerUserId,
    sellerUserId,
    offeredAmount,
    offeredCurrency,
    demandedAmount,
    demandedCurrency,
  } = trade || {};

  const currentTime = new Date().toISOString();
  const currentTimeBySeconds = getDurationBySeconds(createdAt, currentTime);

  const duration = getDurationBySeconds(createdAt, endedAt);
  const isExpired = moment(currentTime).isAfter(moment(endedAt));
  const currentDisputeTimeBySeconds = getDurationBySeconds(endedAt, currentTime);

  const { averageRate } = getRatingDetails(ratings);

  useEffect(() => {
    let userId = '';
    if (buyerUserId === id) {
      userId = sellerUserId;
    }
    if (sellerUserId === id) {
      userId = buyerUserId;
    }
    Promise.all([
      getOfferById(offerId, { OfferStatus: OfferStatusTypes.Open }),
      getUserById(userId),
      getUserRatingById(userId),
    ]).then((res) => {
      setOffer(res[0]);
      setUser(res[1]);
      setRatings(res[2]);
      setDisputeTime(res[1]?.id === buyerUserId ? sellerDisputeTime : buyerDisputeTime);

      setIsLoading(false);
    }).catch(() => {
      setOffer({} as OfferInterface);
      setUser({} as UserInterface);
      setIsLoading(false);
    });
  }, []);

  return offerId ? (
    <ListItem className={`grid ${tradeIsCompleted(tradeStatus) ? 'grid-cols-5' : 'grid-cols-6'}  max-lg:grid-cols-3 max-md:grid-cols-2 gap-10`}>
      <OfferTypeBox
        offerType={trade.buyerUserId === id ? OfferTypes.Buy : OfferTypes.Sell}
        offeredCurrency={`${offeredAmount} ${offeredCurrency}`}
        demandedCurrency={`${demandedAmount} ${demandedCurrency}`}
      />

      {isLoading ? <Loader />
        : (
          <div className="relative group">
            <UsernameBox
              username={user?.userName || 'Anonymous user'}
              rate={Number(averageRate)}
            />
            <UserRatingCard
              className="scale-0 mt-2 max-sm:-right-5 group-hover:scale-100 transform transition-transform duration-100"
              ratings={ratings}
            />
          </div>
        )}

      <CountryBox
        offer={offer!}
        isLimit={false}
      />
      {formattedDate(trade.createdAt, 'DD MMMM, YYYY')}
      {!isExpired && (
        <div>
          <StepTimer className="justify-start" duration={duration} currentTimeBySeconds={currentTimeBySeconds} />
          <span className="text-secondary">remaining time</span>
        </div>
      )}
      {isExpired && disputeTime && currentDisputeTimeBySeconds <= disputeTime
          && tradeStatus === TradeStatusTypes.Paid ? (
            <div>
              <StepTimer
                className="justify-start"
                duration={disputeTime}
                currentTimeBySeconds={currentDisputeTimeBySeconds}
              />
              <span className="text-secondary">left to open dispute</span>
            </div>
        ) : (
          <>
            {!tradeIsCompleted(tradeStatus) && isExpired && <div />}
          </>
        )}
      <div className="flex lg:justify-end">
        <Status status={tradeStatus}>{tradeStatus === TradeStatusTypes.Disputed ? 'In dispute' : tradeStatus }</Status>
      </div>
    </ListItem>
  ) : null;
};
