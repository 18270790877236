import React, { useContext, useState } from 'react';

import {
  ProfileActionTypes, ProfileContext, ViewContext, ViewModalActionTypes,
} from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { ButtonCategory } from '../../../../../constants';

import { Button } from '../../../../Atoms';
import { useProfile } from '../../../../../stores/hooks';

export const ImageUpload: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const { viewModal: { file }, updateViewModal } = useContext(ViewContext);
  const { updateProfileState } = useContext(ProfileContext);

  const profileImageURL = URL.createObjectURL(file as File);

  const { updateProfileImage } = useProfile();

  const handleSaveClick = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('profileImage', file as File);
    updateProfileImage(formData)
      .then(() => {
        updateProfileState({
          type: ProfileActionTypes.UpdateProfile,
          payload: {
            profileImage: profileImageURL,
          },
        });
        updateViewModal({
          type: ViewModalActionTypes.SuccessModal,
          payload: {
            description: 'Profile image saved!',
          },
        });
      })
      .catch((reason) => {
        let description = '';
        const messages: string[] = reason.response.data?.errors?.profileImage;
        if (messages) {
          messages.forEach((message) => {
            description += `${message} `;
          });
        } else {
          description = reason.response.data.detail;
        }
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Profile image update failed!',
            description,
          },
        });
      });
  };

  return (
    <div className="relative h-full w-full flex flex-col items-center justify-center gap-10 p-5">
      <div className="relative bg-[rgba(0, 0, 0, 0.5)] overflow-hidden">
        <img className="h-[400px] min-w-[400px] max-sm:h-[300px] max-sm:min-w-[300px] object-cover opacity-40" src={profileImageURL} alt="avatar" />
        <img className="w-[400px] h-[400px] max-sm:h-[300px] max-sm:w-[300px] object-cover absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[50%]" src={profileImageURL} alt="avatar" />
      </div>
      <div className="w-full flex justify-center gap-2 mb-4">
        <Button className="w-[160px]" category={ButtonCategory.Default} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Cancel</Button>
        <Button className="w-[160px]" category={ButtonCategory.Filled} isLoading={isLoading} onClick={handleSaveClick}>Save</Button>
      </div>
    </div>
  );
};
