import { AppFC, AppElement } from '../../../interfaces';

export const BlockedIcon:AppFC = (props): AppElement => (
  <div {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 7V4.5C11 3.70435 10.6839 2.94129 10.1213 2.37868C9.55871 1.81607 8.79565 1.5 8 1.5C7.20435 1.5 6.44129 1.81607 5.87868 2.37868C5.31607 2.94129 5 3.70435 5 4.5V7M4.5 14.5H11.5C11.8978 14.5 12.2794 14.342 12.5607 14.0607C12.842 13.7794 13 13.3978 13 13V8.5C13 8.10218 12.842 7.72064 12.5607 7.43934C12.2794 7.15804 11.8978 7 11.5 7H4.5C4.10218 7 3.72064 7.15804 3.43934 7.43934C3.15804 7.72064 3 8.10218 3 8.5V13C3 13.3978 3.15804 13.7794 3.43934 14.0607C3.72064 14.342 4.10218 14.5 4.5 14.5Z" stroke="#7B8BA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  </div>

);
