import axios from 'axios';

export const getCancelToken = () => axios.CancelToken.source();

// export const axiosIsCancel = (error: unknown) => axios.isCancel(error);

export const setAxiosAuthorization = (accessToken: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const deleteAxiosAuthorization = () => {
  delete axios.defaults.headers.common.Authorization;
};
