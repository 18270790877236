import React, { useContext } from 'react';
import { QRCodeSVG } from 'qrcode.react';

import { ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { Button } from '../../../../Atoms';
import { ButtonCategory } from '../../../../../constants';

export const QrCode: AppFC = ({ className }): AppElement => {
  const { viewModal: { info }, updateViewModal } = useContext(ViewContext);
  const { blockchainAddress = '' } = info || {};
  return (
    <div className={`flex flex-col items-center my-auto gap-14 ${className}`}>
      <div className="flex flex-col gap-2 items-center">
        <h4>Scan QR</h4>
        <p className="mt-2 text-center max-w-md">Open your desired wallet and scan QR Code to send funds to your Cryptolocally Portal Account</p>
      </div>
      <div className="rounded-lg bg-white p-2 h-[148px]">
        <QRCodeSVG
          className="h-full w-full"
          value={blockchainAddress}
          size={300}
        />
      </div>

      <Button className="w-[180px]" category={ButtonCategory.Filled} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Close</Button>
    </div>
  );
};
