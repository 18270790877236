import axios from 'axios';
import {
  httpHeader,
  HTTPRequestDataType,
  HTTPRequestParamsType,
  HttpRequestProps,
} from './axios';

export const httpRequest = async ({
  data,
  params,
  url,
  method,
  withoutToken,
  cancelToken,
  headerType,
}: HttpRequestProps<HTTPRequestDataType, HTTPRequestParamsType>) => {
  try {
    return await axios({
      url,
      data: data || null,
      params: params || null,
      method,
      headers: {
        ...httpHeader({ withoutToken, headerType }),
      },
      cancelToken: cancelToken || undefined,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
