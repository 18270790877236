import { SVGAttributes } from 'react';
import { AppElement, AppFC } from '../../../../interfaces';

export const HalfFilledStarIcon: AppFC<SVGAttributes<SVGElement>> = ({ height = 18, width = 18, ...props }): AppElement => (
  <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 1.12978L11.1967 5.58071L11.313 5.81643L11.5731 5.85423L16.485 6.56797L12.9307 10.0325L12.7425 10.216L12.787 10.4751L13.626 15.3671L9.23267 13.0574L9 12.9351L8.76733 13.0574L4.374 15.3671L5.21305 10.4751L5.25749 10.216L5.06925 10.0325L1.51497 6.56797L6.42687 5.85423L6.687 5.81643L6.80333 5.58071L9 1.12978Z" fill="url(#paint0_linear_1763_1010)" />
    <path d="M9 1.12978L11.1967 5.58071L11.313 5.81643L11.5731 5.85423L16.485 6.56797L12.9307 10.0325L12.7425 10.216L12.787 10.4751L13.626 15.3671L9.23267 13.0574L9 12.9351L8.76733 13.0574L4.374 15.3671L5.21305 10.4751L5.25749 10.216L5.06925 10.0325L1.51497 6.56797L6.42687 5.85423L6.687 5.81643L6.80333 5.58071L9 1.12978Z" fill="url(#paint1_linear_1763_1010)" />
    <path d="M9 1.12978L11.1967 5.58071L11.313 5.81643L11.5731 5.85423L16.485 6.56797L12.9307 10.0325L12.7425 10.216L12.787 10.4751L13.626 15.3671L9.23267 13.0574L9 12.9351L8.76733 13.0574L4.374 15.3671L5.21305 10.4751L5.25749 10.216L5.06925 10.0325L1.51497 6.56797L6.42687 5.85423L6.687 5.81643L6.80333 5.58071L9 1.12978Z" stroke="#FF9D0A" />
    <defs>
      <linearGradient id="paint0_linear_1763_1010" x1="9" y1="0" x2="9" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F7CF00" />
        <stop offset="1" stopColor="#F9A400" />
      </linearGradient>
      <linearGradient id="paint1_linear_1763_1010" x1="18" y1="9" x2="-3.72527e-09" y2="9" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1A1C23" />
        <stop offset="0.493013" stopColor="#1A1C23" />
        <stop offset="0.505931" stopColor="#FAD03A" />
        <stop offset="1" stopColor="#F8C201" />
      </linearGradient>
    </defs>
  </svg>
);
