import moment from 'moment/moment';
import {
  CryptoCurrencyTypes, RatingGradeTypes, TradeStatusTypes,
} from '../constants';
import { OfferInterface, UserRatingInterface } from '../interfaces';

export const getNumberValue = (value: string, min?: number | string, max?: number | string) => {
  if (min?.toString() && (Number(value) < Number(min))) {
    return min.toString();
  }

  if (max?.toString() && (Number(value) > Number(max))) {
    return max.toString();
  }

  if (value[0] === '0' && value.length > 1 && Number(value) > 1) {
    return value.substring(1);
  }
  return value;
};

export const isEmpty = (obj = {}) => Object.keys(obj).length === 0;

export const numberWithCommas = (num?: number | string | null, currency?: CryptoCurrencyTypes | string, decimals: number = 2) : string => {
  if (num || num === 0) {
    const value = decimals ? Number(num).toFixed(decimals) : num.toString();

    const valueWithDecimals = decimals && decimals > 2 ? value.replace(/\.?0+$/, '') : value;

    const parts = valueWithDecimals.split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `${parts.join('.')} ${currency || ''}`;
  }

  return '-';
};

export const getTradeLimitMessage = (offer: OfferInterface) => {
  const {
    minTrade, maxTrade, // offeredCurrency, demandedCurrency, offerType,
  } = offer || {};
  // const currency = offerType === OfferTypes.Buy ? demandedCurrency : offeredCurrency;
  if (minTrade && !maxTrade) {
    return {
      title: 'Min. limit:',
      value: `$${minTrade}`,
    };
  }
  if (!minTrade && maxTrade) {
    return {
      title: 'Max. limit:',
      value: `$${maxTrade}`,
    };
  }
  if (minTrade && maxTrade) {
    return {
      title: 'Limits:',
      value: `$${minTrade} - $${maxTrade}`,
    };
  }
  return {
    title: '',
    value: '',
  };
};

export const tradeIsCompleted = (status: TradeStatusTypes) => status === TradeStatusTypes.Completed || status === TradeStatusTypes.Refunded;
export const tradeIsExpired = (date?: string) => moment(date).isBefore(new Date().toISOString());

export const getGradeType = (rating: number) => {
  switch (rating) {
    case 1:
      return RatingGradeTypes.One;
    case 2:
      return RatingGradeTypes.Two;
    case 3:
      return RatingGradeTypes.Three;
    case 4:
      return RatingGradeTypes.Four;
    case 5:
      return RatingGradeTypes.Five;
    default:
      return null;
  }
};

export const getRatingDetails = (ratings: UserRatingInterface[]) => {
  const totalAmount = ratings.length || 1;
  const fiveStarAmount = ratings.filter(({ ratingGrade }) => ratingGrade === 5).length;
  const fourStarAmount = ratings.filter(({ ratingGrade }) => ratingGrade === 4).length;
  const threeStarAmount = ratings.filter(({ ratingGrade }) => ratingGrade === 3).length;
  const twoStarAmount = ratings.filter(({ ratingGrade }) => ratingGrade === 2).length;
  const oneStarAmount = ratings.filter(({ ratingGrade }) => ratingGrade === 1).length;

  const averageRate = totalAmount === 0.0 ? totalAmount : Math.round((10 * (5 * fiveStarAmount + 4 * fourStarAmount + 3 * threeStarAmount + 2 * twoStarAmount + oneStarAmount)) / totalAmount) / 10;

  return {
    totalAmount,
    fiveStarAmount,
    fourStarAmount,
    threeStarAmount,
    twoStarAmount,
    oneStarAmount,
    averageRate: averageRate.toFixed(1),
  };
};
