import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const CopyIcon: AppFC<HTMLAttributes<HTMLDivElement>> = ({ className, ...rest }): AppElement => (
  <div className={`cursor-pointer ${className || ''}`} {...rest}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6665 1.6712H8.33317C7.414 1.6712 6.6665 2.4187 6.6665 3.33787V6.6712H3.33317C2.414 6.6712 1.6665 7.4187 1.6665 8.33787V16.6712C1.6665 17.5904 2.414 18.3379 3.33317 18.3379H11.6665C12.5857 18.3379 13.3332 17.5904 13.3332 16.6712V13.3379H16.6665C17.5857 13.3379 18.3332 12.5904 18.3332 11.6712V3.33787C18.3332 2.4187 17.5857 1.6712 16.6665 1.6712ZM3.33317 16.6712V8.33787H11.6665L11.6682 16.6712H3.33317ZM16.6665 11.6712H13.3332V8.33787C13.3332 7.4187 12.5857 6.6712 11.6665 6.6712H8.33317V3.33787H16.6665V11.6712Z" fill="currentColor" />
    </svg>
  </div>
);
