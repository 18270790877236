import { WalletActionTypes } from './actions';
import { UseReducerType } from '../../interfaces';
import {
  WalletActionType,
  WalletBalanceInterface,
  WalletInterface,
  WalletsInterface,
} from './interfaces';

const updateWalletBalance = (
  wallets: WalletInterface[],
  balance: WalletBalanceInterface,
) => wallets.map(
  (wallet) => (balance.id === wallet.id ? { ...wallet, ...balance } : wallet),
);
export const walletReducer: UseReducerType<WalletsInterface, WalletActionType> = (
  state,
  action,
): WalletsInterface => {
  switch (action.type) {
    case WalletActionTypes.UpdateWallets:
      return { ...state, wallets: action.payload };
    case WalletActionTypes.UpdateWalletBalance:
      return { ...state, wallets: updateWalletBalance(state.wallets, action.payload) };
    default:
      return state;
  }
};
