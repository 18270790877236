import { useMemo, useReducer, createContext } from 'react';

import { profileReducer } from './reducer';
import { AppElement, AppFC } from '../../../interfaces';
import {
  ContextProviderPropsInterface,
  UseReducerType,
} from '../../interfaces';
import {
  ProfileActionType,
  ProfileContextInterface,
  ProfileInterface,
} from './interfaces';

import { profileInitialState } from './initialState';

export const ProfileContext = createContext<ProfileContextInterface>({} as ProfileContextInterface);

export const ProfileContextProvider: AppFC<ContextProviderPropsInterface> = ({ children }): AppElement => {
  const [profileState, updateProfileState] = useReducer<
  UseReducerType<ProfileInterface, ProfileActionType>
  >(profileReducer, profileInitialState);

  const value = useMemo(() => ({
    profileState,
    updateProfileState,
  }), [profileState]);

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};
