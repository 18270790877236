import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';

import { createRoom } from '../../../../graphql/mutations';
import { AuthToken } from '../../../../services';

import {
  OfferContext, ProfileContext, ViewContext, ViewModalActionTypes,
} from '../../../../stores/contexts';
import { useCurrency, useTrade, useOffer } from '../../../../stores/hooks';

import { AppElement, AppFC, OfferInterface } from '../../../../interfaces';
import { OfferTradeDataProps } from './types';
import {
  ButtonCategory, GRAPHQL_CHAT_API, OfferStatusTypes, OfferTypes, Paths,
} from '../../../../constants';
import { getTradeLimitMessage, numberWithCommas } from '../../../../utils';

import { OfferTradeDetails } from '../../../Organisms';
import { FormInput, UserInfo } from '../../../Molecules';
import {
  Button, Card, Input, Loader,
} from '../../../Atoms';
import { ExclamationIcon } from '../../../Atoms/icons';

const validationSchema = yup.object().shape({
  offeredAmount: yup.number().required(),
});

const { REGION } = GRAPHQL_CHAT_API;

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_GRAPHQL_CHAT_API_URL as string,
      region: REGION,
      defaultAuthMode: 'lambda',
    },
  },
});

export const CreateTrade: AppFC<{ tradeType: OfferTypes }> = ({ tradeType }): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [offer, setOffer] = useState<OfferInterface>();

  const client = generateClient();

  const tokens = new AuthToken();
  const accessToken = tokens.getAccessToken();

  const { profileState: { id } } = useContext(ProfileContext);
  const { offerState: { currencyRate: defaultRate } } = useContext(OfferContext);
  const { updateViewModal } = useContext(ViewContext);

  const isSeller = tradeType === OfferTypes.Buy;
  const isBuyer = tradeType === OfferTypes.Sell;

  const { title: limitsTitle, value: limitsValue } = getTradeLimitMessage(offer as OfferInterface);
  const { offerId } = useParams();
  const navigate = useNavigate();

  const { getCryptocurrencyRate } = useCurrency();
  const {
    createTrade,
    getTrades,
  } = useTrade();
  const { getOfferById } = useOffer();
  const [currencyRate, setCurrencyRate] = useState(defaultRate);

  const {
    control,
    handleSubmit,
    formState: {
      errors,
    },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const price = ((Number(currencyRate?.rate || 0) * (100 + (offer?.margin || 0))) / 100);

  const currentCurrencyAmount = watch('offeredAmount') || 0;

  useEffect(() => {
    if (offerId) {
      Promise.all([getOfferById(offerId, { OfferStatus: OfferStatusTypes.Open }), getTrades(),
      ]).then((res) => {
        setOffer(res[0]);
      }).catch(() => {
        setOffer({} as OfferInterface);
      });
    }
  }, [offerId]);

  useEffect(() => {
    if (offer?.id) {
      getCryptocurrencyRate(offer?.demandedCurrency, offer?.offeredCurrency)
        .then((res) => {
          setCurrencyRate(res);
        });
    }
  }, [offer]);

  const onSubmit: SubmitHandler<OfferTradeDataProps> = (data) => {
    if (offer) {
      setIsLoading(true);
      createTrade({
        offerId: offer.id,
        paymentMethodType: offer.paymentMethodTypes[0],
        ...data,
      })
        .then((tradeId) => {
          client.graphql({
            authToken: accessToken,
            query: createRoom,
            variables: {
              input: {
                name: tradeId,
                userId: id as string,
              },
            },
          }).then(() => {
            setIsLoading(false);
            if (tradeId) {
              navigate(`/trades/${tradeId}`);
            }
          }).catch(() => {
            updateViewModal({
              type: ViewModalActionTypes.FailModal,
              payload: {
                title: 'Trade chat room create failed!',
              },
            });
            setIsLoading(false);
          });
        }).catch((reason) => {
          updateViewModal({
            type: ViewModalActionTypes.FailModal,
            payload: {
              title: 'Trade create failed!',
              description: reason.response?.data?.detail || '',
            },
          });
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {!offer ? <Loader className="absolute self-center justify-self-center top-0 h-screen" />
        : (
          <div className="flex flex-col gap-12 py-5 w-full max-w-[1120px] self-center">
            <Card className="py-5 px-6">
              <div className="flex items-center w-full gap-2 text-secondary">
                <ExclamationIcon className="w-6 h-6" />
                <p className="flex-1">The escrow is there to protect both sides. For a safe transaction, never pay before the escrow is funded.</p>
              </div>
            </Card>
            <div className="flex justify-between gap-[12%] max-lg:gap-[7%] max-md:flex-col max-md:items-center">
              <OfferTradeDetails
                offer={offer}
                amount={currentCurrencyAmount}
                isBuyer={isBuyer}
                isSeller={isSeller}
              />
              <div className="flex-1 max-w-[500px]">
                <UserInfo offer={offer} className="mb-6" />
                <Card className="p-6 flex-1 max-w-[500px] w-full h-fit">
                  <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col self-center gap-6 w-full">
                    <div className="flex flex-col gap-4">
                      <div>
                        <div className="flex justify-between items-center gap-3 mb-2">
                          <h6>Amount</h6>
                          {limitsValue && (
                          <div className="flex gap-4 flex-wrap">
                            <div className="flex gap-2 flex-wrap text-secondary">
                              <span>{limitsTitle}</span>
                              <span className="text-blue">{limitsValue}</span>
                            </div>
                          </div>
                          )}
                        </div>

                        <FormInput
                          name="offeredAmount"
                          control={control}
                          // min={offer.minTrade}
                          // max={offer.maxTrade}
                          type="number"
                          step="0.0000001"
                          placeholder="0.00"
                          suffixIcon={offer.offeredCurrency}
                          error={errors.offeredAmount}
                        />
                      </div>
                      <div>
                        <h6 className="mb-2">Payment Method</h6>
                        <Input
                          placeholder="0.00"
                          value={currentCurrencyAmount ? numberWithCommas(Number(currentCurrencyAmount) * price, offer.demandedCurrency, 2) : ''}
                          blocked
                        />
                      </div>
                    </div>
                    <p className="text-[14px] [&_a]:underline [&_a]:text-tertiary text-secondary">
                      By opening this trade you agree to the
                      {' '}
                      <NavLink to={Paths.TermsOfUse}>Terms of Use</NavLink>
                      {' '}
                      and
                      {' '}
                      <NavLink to={Paths.PrivacyPolicy}>Privacy Policy</NavLink>
                      .
                    </p>
                    <Button
                      category={ButtonCategory.Filled}
                      type="submit"
                      name="publish"
                      isLoading={isLoading}
                    >
                      Open Trade
                    </Button>
                  </form>
                </Card>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
