import { useContext } from 'react';

import { WalletCreateDataProps, WalletWithdrawDataProps } from '../../services/httpRequest';
import { httpRequest } from '../../services';

import {
  ProfileContext,
  WalletContext,
  ProfileActionTypes,
  WalletActionTypes,
} from '../contexts';

import { RequestTypes, WALLET_API } from '../../constants';

export const useWallet = () => {
  const {
    CREATE,
    CREATE_WALLET_USER,
    GET_WALLETS,
  } = WALLET_API();

  const { updateWalletState } = useContext(WalletContext);
  const { profileState: { walletUserExist }, updateProfileState } = useContext(ProfileContext);

  const createWallet = async (data: WalletCreateDataProps) => {
    const response = await httpRequest({
      url: CREATE,
      method: RequestTypes.Post,
      data,
    });
    return response.data;
  };
  const createWalletUser = async () => {
    const response = await httpRequest({
      url: CREATE_WALLET_USER,
      method: RequestTypes.Post,
    });
    if (!walletUserExist) {
      updateProfileState({ type: ProfileActionTypes.UpdateProfile, payload: { walletUserExist: true } });
    }

    return response.data;
  };
  const getWallets = async () => {
    const response = await httpRequest({
      url: GET_WALLETS,
      method: RequestTypes.Get,
    });

    updateWalletState({
      type: WalletActionTypes.UpdateWallets,
      payload: response.data,
    });

    return response.data;
  };

  const getWalletById = async (id: string) => {
    const response = await httpRequest({
      url: WALLET_API(id).GET_WALLET,
      method: RequestTypes.Get,
    });

    return response.data;
  };
  const getWalletBalance = async (id: string) => {
    const response = await httpRequest({
      url: WALLET_API(id).GET_WALLET_BALANCE,
      method: RequestTypes.Put,
    });

    updateWalletState({
      type: WalletActionTypes.UpdateWalletBalance,
      payload: { id, ...response.data },
    });

    return response.data;
  };

  const withdraw = async (data: WalletWithdrawDataProps, id: string) => {
    const response = await httpRequest({
      url: WALLET_API(id).WITHDRAW,
      method: RequestTypes.Post,
      data,
    });

    return response.data;
  };

  return ({
    createWalletUser,
    createWallet,
    getWallets,
    getWalletById,
    getWalletBalance,
    withdraw,
  });
};
