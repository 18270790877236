import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import {
  AppElement, AppFC, UserInterface, UserRatingInterface,
} from '../../../interfaces';
import { OfferBoxProps } from './types';
import {
  AuthPaths, ButtonCategory, cryptoCurrencyNames, MarketTypes, // OfferTypes,
} from '../../../constants';

import { Button, ListItem, Loader } from '../../Atoms';
import {
  CountryBox, CurrencyPriceBox, PaymentMethodBox, UsernameBox,
} from '../../Atoms/offerItems';
import { useCurrency, useUser } from '../../../stores/hooks';
import { getRatingDetails, numberWithCommas } from '../../../utils';
import { UserRatingCard } from '../UserRatingCard';
import { CurrencyRateInterface } from '../../../stores/contexts/OfferContext/interfaces';
import { AuthToken } from '../../../services';

export const OfferBox: AppFC<OfferBoxProps> = ({ offer }): AppElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [ratings, setRatings] = useState<UserRatingInterface[]>([]);
  const [user, setUser] = useState<UserInterface>();
  const [currencyRate, setCurrencyRate] = useState<CurrencyRateInterface>();

  const authToken: AuthToken = new AuthToken();
  const isAuth = authToken.getAccessToken() && authToken.getRefreshToken();

  // const { offerState: { currencyRate } } = useContext(OfferContext);

  const { getUserById, getUserRatingById } = useUser();
  const { getCryptocurrencyRate } = useCurrency();

  const { rate, currency } = currencyRate || {};
  const {
    id: offerId,
    offerType,
    margin,
    paymentMethodTypes,
    offeredCurrency,
    demandedCurrency,
    userId,
  } = offer;
  // const currentOfferType = offerType === OfferTypes.Buy ? OfferTypes.Sell : OfferTypes.Buy;
  const price = numberWithCommas((Number(rate || 0) * (100 + margin)) / 100, currency, 2);
  const { averageRate } = getRatingDetails(ratings);

  useEffect(() => {
    Promise.all([getUserById(userId), getUserRatingById(userId), getCryptocurrencyRate(demandedCurrency, offeredCurrency)]).then((response) => {
      setUser(response[0]);
      setRatings(response[1]);
      setCurrencyRate(response[2]);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      // error handler
    });
  }, [offer]);

  const marketType = (margin > 0 && MarketTypes.Above) as MarketTypes
      || (margin < 0 && MarketTypes.Below) as MarketTypes
      || (margin === 0 && MarketTypes.None) as MarketTypes;

  return offerId ? (
    <ListItem className="max-lg:flex-col lg:gap-6 max-lg:gap-4 justify-between">
      {isLoading ? <Loader /> : (
        <CurrencyPriceBox
          className="flex-1 max-lg:flex-row max-lg:justify-between max-lg:w-full"
          price={price}
          margin={Math.abs(margin)}
          marketType={marketType}
          info={`This offer is ${margin === 0 ? 'equal to' : `${margin}% ${marketType}`} ${cryptoCurrencyNames[offeredCurrency]} current market price.`}
        />
      )}
      <div className="flex lg:flex-1 items-center max-lg:justify-between max-lg:w-full">
        {isLoading ? <Loader /> : (
          <>
            <div className="group relative flex-1 max-lg:items-start">
              <UsernameBox
                username={user?.userName || 'Anonymous user'}
                rate={Number(averageRate)}
              />
              <UserRatingCard
                className="scale-0 mt-2 group-hover:scale-100 transform transition-transform duration-100"
                ratings={ratings}
              />
            </div>
            <CountryBox
              className="max-lg:items-end lg:hidden"
              offer={offer}
            />
          </>
        )}
      </div>
      <CountryBox
        className="flex-[1.5] max-lg:hidden"
        offer={offer}
      />
      <PaymentMethodBox
        className="flex-1 max-lg:items-end max-lg:hidden lg:justify-center"
        methods={paymentMethodTypes}
      />
      <div className="flex items-center max-lg:justify-between max-lg:w-full lg:justify-end">
        <PaymentMethodBox
          className="flex-1 lg:hidden"
          methods={paymentMethodTypes}
        />
        <NavLink to={!isAuth ? AuthPaths.SignIn : `/offers/${offerType.toLowerCase()}/${offerId}`}><Button category={ButtonCategory.Outlined} className="w-20 h-10 p-0"><span>{offerType}</span></Button></NavLink>
      </div>
    </ListItem>
  ) : null;
};
