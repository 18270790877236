import { useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

import { AuthToken, httpRequest } from '../../services';
import {
  ChangePasswordDataProps,
  ResetPasswordConfirmDataProps,
  SendEmailDataProps,
  SignInDataProps,
  SignUpDataProps,
  VerifyEmailConfirmDataProps,
} from '../../services/httpRequest';

import { AUTH_API, authRole, RequestTypes } from '../../constants';

import {
  OfferContext,
  ProfileContext,
  RequestContext,
  ViewContext,
  OfferActionTypes,
  ProfileActionTypes,
  ViewLoaderActionTypes,
} from '../contexts';

export const useAuth = () => {
  const { updateViewLoader } = useContext(ViewContext);
  const { requestState } = useContext(RequestContext);
  const { updateProfileState } = useContext(ProfileContext);
  const { updateOfferState } = useContext(OfferContext);

  const myLS = new AuthToken();
  const signUp = async (data: SignUpDataProps) => httpRequest({
    url: AUTH_API.POST_SIGN_UP,
    method: RequestTypes.Post,
    data,
    withoutToken: true,
  });

  const sendEmail = async (data: SendEmailDataProps) => httpRequest({
    url: AUTH_API.POST_EMAIL_SEND,
    method: RequestTypes.Post,
    data,
    withoutToken: true,
  });

  const signIn = async (data: SignInDataProps, isAdmin?:boolean) => {
    const response = await httpRequest({
      url: AUTH_API.POST_SIGN_IN,
      method: RequestTypes.Post,
      data,
      withoutToken: true,
    });
    if (response.data) {
      const decodedToken = jwtDecode(response.data.accessToken);
      if (isAdmin) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (decodedToken[authRole] === 'Admin') {
          myLS.setTokens({
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
          });
        } else {
          return null;
        }
      } else {
        myLS.setTokens({
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        });
      }
    }
    return response;
  };

  const signOut = async () => {
    const response = await httpRequest({
      url: AUTH_API.GET_SIGN_OUT,
      method: RequestTypes.Patch,
      data: {
        refreshToken: myLS.getRefreshToken(),
        accessToken: myLS.getAccessToken(),
      },
    });

    updateProfileState({ type: ProfileActionTypes.ClearProfile });
    updateOfferState({ type: OfferActionTypes.ClearOffers });

    myLS.clearTokens();

    return response;
  };
  const passwordReset = async (data: SendEmailDataProps) => httpRequest({
    url: AUTH_API.POST_RESET,
    method: RequestTypes.Post,
    data,
    withoutToken: true,
  });
  const passwordResetConfirm = async (data: ResetPasswordConfirmDataProps) => httpRequest({
    url: AUTH_API.POST_RESET_CONFIRM,
    method: RequestTypes.Post,
    data,
    withoutToken: true,
  });

  const passwordChange = async (data: ChangePasswordDataProps) => {
    const response = await httpRequest({
      url: AUTH_API.POST_PASSWORD_CHANGE,
      method: RequestTypes.Post,
      data,
    });

    return response.data;
  };

  const emailVerification = async (data: VerifyEmailConfirmDataProps) => httpRequest({
    url: AUTH_API.POST_EMAIL_VERIFICATION,
    method: RequestTypes.Post,
    data,
    withoutToken: true,
  });

  useEffect(() => () => {
    updateViewLoader({ type: ViewLoaderActionTypes.UpdateLoader, payload: false });
    requestState.cancelTokenSource.cancel();
  }, [requestState.cancelTokenSource, updateViewLoader]);

  return ({
    authRequestStatus: requestState.status,
    authResponseData: requestState.responseData,
    authSuccessMessage: requestState.successMessage,
    authRequestError: requestState.errorMessage,
    signIn,
    signUp,
    signOut,
    passwordReset,
    passwordResetConfirm,
    passwordChange,
    emailVerification,
    sendEmail,
  });
};
