import React, {
  useEffect, useRef, useState,
} from 'react';

// import { ProfileContext } from '../../../../stores';
// import { useAuth } from '../../../../stores/hooks';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AuthToken } from '../../../../services';
import { AppElement, AppFC } from '../../../../interfaces';
import { NotificationMenuProps } from './types';

import {
  HeaderCategory, minNotificationCount, Paths,
} from '../../../../constants';

import { useNotification } from '../../../../stores/hooks/useNotification';
import { Button } from '../../../Atoms';

export const NotificationMenu: AppFC<NotificationMenuProps> = ({
  category = HeaderCategory.App, toggleMenu, menuIsOpen, notifications,
}): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = authToken.getAccessToken() && authToken.getRefreshToken();
  const notificationMenuRef = useRef<HTMLDivElement | null>(null);
  const [notificationCount, setNotificationCount] = useState(minNotificationCount);

  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);

  const { markAsRead } = useNotification();

  const handleOutsideClick = (e: MouseEvent) => {
    if (notificationMenuRef.current && !notificationMenuRef.current.contains(e.target as Node)) {
      if (menuIsOpen) {
        toggleMenu(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuIsOpen]);

  useEffect(() => {
    if (notificationCount < notifications.length) {
      notificationMenuRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [notificationCount]);

  const handleNotificationClick = (tradeId: string, id: string) => {
    markAsRead(id).then(() => {
      navigate(`${Paths.Trades}/${tradeId}`);
    });
  };

  if (!notifications.length) {
    return null;
  }

  return (
    <div ref={notificationMenuRef} className={`absolute flex flex-col items-center bg-default overflow-x-hidden max-h-[50vh] top-20 ${isAuth && category === HeaderCategory.App ? 'right-[calc(3%_+_64px)] max-lg:right-[calc(3%_+_100px)]' : 'right-[calc(7%_+_100px)] max-lg:right-[calc(7%_+_100px)]'} flex flex-col items-end min-w-[200px] rounded-2xl z-40`}>
      {isAuth && category === HeaderCategory.App && (
      <>
        {notifications.map(({
          tradeId, id, message, createdAt,
        }, index) => {
          const momentDate = moment(createdAt);
          const now = moment();
          const timeAgo = momentDate.from(now);

          if (index > notificationCount - 1) {
            return null;
          }

          return (
            <Button
              onClick={() => {
                handleNotificationClick(tradeId, id);
              }}
              className="flex items-center border-b border-b-default text-left w-full hover:bg-header p-4 rounded-none"
            >
              <div className="flex gap-5 w-full items-center justify-between [&>span]:text-[11px] [&>span]:font-light">
                <span>{message}</span>
                <span className="text-default text-end">{timeAgo}</span>
              </div>
            </Button>
          );
        })}
        {notifications.length > minNotificationCount && (
        <Button
          onClick={() => {
            if (notificationCount < notifications.length) {
              setNotificationCount(notificationCount + 2);
            } else {
              setNotificationCount(minNotificationCount);
            }
          }}
          className="underline text-tertiary text-[10px] min-h-12"
        >
          {notificationCount < notifications.length ? 'Show more' : 'Show less'}
        </Button>
        )}
      </>
      )}
    </div>
  );
};
