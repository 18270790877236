import { AppElement, AppFC } from '../../../interfaces';
import { SwitchProps } from './types';

export const Switch: AppFC<SwitchProps> = ({
  items,
  seperated = false,
  inOneLine = true,
  direction = 'horizontal',
  setValue,
  value: activeItem,
  width,
  height,
  itemClass,
  activeItemClass,
}): AppElement => {
  if (seperated) {
    return (
      <div className={`w-fit h-fit flex items-center gap-2 ${!inOneLine ? 'flex-wrap' : ''}`}>
        {items?.map(({ value, label }, index) => (
          <span
            key={`${value}-${index}`}
            className={`
            ${itemClass || ''}
            ${activeItem === value ? ` ${activeItemClass || 'text-primary bg-primary'} border border-blue` : 'text-grey bg-input hover:bg-inputHover border border-default'} 
            transition-all duration-100 ease-in
            rounded-[0.25rem]
            flex items-center justify-center
            ${width || 'w-20'}
            ${height || 'h-10'}
            cursor-pointer
            `}
            onClick={() => { setValue(value); }}
          >
            {label}
          </span>
        ))}
      </div>
    );
  }

  if (direction === 'vertical') {
    return (
      <div className="w-fit h-fit rounded-[0.25rem] overflow-hidden flex flex-col items-center cursor-pointer">
        {items?.map(({ value, label }, index) => (
          <span
            key={`${value}-${index}`}
            className={`
              ${activeItem === value ? ` ${activeItemClass || 'text-primary bg-primary'} border border-blue` : 'text-grey bg-input hover:bg-inputHover border border-default'} 
              transition-all duration-200 ease-in 
              flex items-center justify-center
              ${width || 'w-20'}
              ${height || 'h-10'}
            `}
            onClick={() => { setValue(value); }}
          >
            {label}
          </span>
        ))}
      </div>
    );
  }

  return (
    <div className="w-fit h-fit rounded-xl overflow-hidden border border-blue flex items-center cursor-pointer">
      {items?.map(({ value, label }, index) => (
        <span
          key={`${value}-${index}`}
          className={`
            ${activeItem === value ? 'text-primary bg-primary' : 'text-tertiary bg-card hover:bg-inputHover '}
            ${items.length > 2 && index > 0 && index < items.length - 1 ? 'border-l border-r border-blue' : ''}
            transition-all duration-200 ease-in 
            flex items-center justify-center
            ${width || 'w-20'}
            ${height || 'h-10'}
            font-semibold
          `}
          onClick={() => { setValue(value); }}
        >
          {label}
        </span>
      ))}
    </div>
  );
};
