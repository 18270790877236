import { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AppElement, AppFC } from '../../../interfaces';
import { HeaderCategory, Paths } from '../../../constants';

import { AuthToken } from '../../../services';
import { Header } from './Header';
import { useProfile } from '../../../stores/hooks';
import { Loader } from '../../Atoms';
import { ProfileContext } from '../../../stores';

export const AuthLayout: AppFC = ({ children }): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const accessToken = authToken.getAccessToken();
  const refreshToken = authToken.getRefreshToken();
  const isAuth = accessToken && refreshToken;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false);
  const [notificationMenuIsOpen, setNotificationMenuIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getProfile } = useProfile();
  const {
    profileState: {
      loginCount = 1,
    },
  } = useContext(ProfileContext);

  const { pathname } = useLocation();

  useEffect(() => {
    if (isAuth) {
      setIsLoading(true);
      getProfile().then(() => {
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    }
    if (profileMenuIsOpen) {
      setProfileMenuIsOpen(false);
    }
    if (notificationMenuIsOpen) {
      setNotificationMenuIsOpen(false);
    }
  }, [pathname]);

  if (authToken.getAccessToken() && authToken.getRefreshToken() && loginCount > 1) {
    return <Navigate to={Paths.Offers} />;
  }

  return (
    <div className={`relative h-screen w-full flex justify-end max-sm:justify-center bg-authLayout bg-cover bg-auth ${menuIsOpen ? 'overflow-hidden' : 'overflow-x-hidden'}`}>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header
        category={HeaderCategory.Auth}
        menuIsOpen={menuIsOpen}
        toggleMenu={setMenuIsOpen}
        profileMenuIsOpen={profileMenuIsOpen}
        toggleProfileMenu={setProfileMenuIsOpen}
        notificationMenuIsOpen={notificationMenuIsOpen}
        toggleNotificationMenu={setNotificationMenuIsOpen}
      />
      {isLoading ? <Loader className="self-center max-sm:mr-0 mr-[50%]" />
        : (
          <div className="w-[calc(20%+220px)] max-w-[360px] mr-[10%] max-sm:mr-0 min-h-screen max-md:min-h-fit flex flex-col">
            {children}
          </div>
        )}
    </div>
  );
};
