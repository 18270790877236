import { Controller } from 'react-hook-form';

import { AppElement, AppFC } from '../../../interfaces';
import { FormSelectProps } from './types';

import { Label, SearchSelect } from '../../Atoms';

export const FormSearchSelect: AppFC<FormSelectProps> = ({
  className,
  label,
  control,
  name,
  error,
  children,
  ...rest
}): AppElement => (
  <Label className={className}>
    {!!label && <h6 className={`${error ? 'text-error' : ''}`}>{label}</h6>}
    <Controller
      name={name}
      control={control}
      render={({
        field,
      }) => (
        <SearchSelect
          error={error}
          {...field}
          {...rest}
        />
      )}
    />
  </Label>
);
