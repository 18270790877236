import React, { useContext } from 'react';

import { ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { ButtonCategory } from '../../../../../constants';

import { Button } from '../../../../Atoms';

export const Delete: AppFC = (): AppElement => {
  const { viewModal: { title, description, onDelete }, updateViewModal } = useContext(ViewContext);

  return (
    <div className="flex flex-col gap-14 items-center justify-center">
      <div className="text-center">
        {title && <h4>{title}</h4>}
        {description && <p className="mt-2">{description}</p>}
      </div>
      <div className="flex gap-2 w-full [&>button]:w-[180px] max-[400px]:flex-col max-[400px]:[&>button]:w-full">
        <Button
          className="bg-[#D44444] hover:bg-[#D44444]"
          onClick={() => {
            if (onDelete) {
              onDelete();
            }
          }}
        >
          Delete
        </Button>
        <Button
          category={ButtonCategory.Default}
          onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
