import { NavLink } from 'react-router-dom';

import { AppElement, AppFC } from '../../../../interfaces';
import { FooterProps } from './types';

import { AuthPaths, Paths } from '../../../../constants';

import { AppLogo } from '../../../Atoms/icons';

import telegramIcon from '../../../../assets/icons/media/telegram.svg';
import twitterIcon from '../../../../assets/icons/media/twitter.svg';
import youtubeIcon from '../../../../assets/icons/media/youtube.svg';

export const Footer: AppFC<FooterProps> = ({
  className,
  isAuth,
}): AppElement => (
  <footer className={`w-full py-16 shadow-header bg-header border-header border-t ${isAuth ? 'px-[3%]' : 'px-[7%]'} ${className || ''}`}>
    <div className="flex justify-between gap-16 max-md:flex-col mb-8">
      <div className="flex flex-col gap-8 flex-1 max-w-sm">
        <div className="flex flex-col items-start gap-3">
          <NavLink to={isAuth ? Paths.Offers : Paths.Home}>
            <AppLogo />
          </NavLink>
          <span className="text-[15px]">Buy & Sell crypto safely with your local currency</span>
        </div>
        <div className="flex gap-6">
          <img src={telegramIcon} alt="telegram" />
          <img src={twitterIcon} alt="twitter" />
          <img src={youtubeIcon} alt="youtube" />
        </div>
      </div>
      <div className="flex max-[425px]:flex-col max-md:flex-wrap gap-8 min-[425px]:justify-between flex-[2] max-w-4xl [&_a:hover]:underline">
        <div className="flex flex-col items-start gap-4">
          <h5>Platform</h5>
          <NavLink to={Paths.Offers}><span>Browse Offers</span></NavLink>
          <NavLink to={isAuth ? Paths.OfferCreate : AuthPaths.SignUp}><span>Create an Offer</span></NavLink>
        </div>
        <div className="flex flex-col items-start gap-4">
          <h5>Support</h5>
          <NavLink to={Paths.GuideCreateOffer}><span>Guide</span></NavLink>
          {window.location.pathname === Paths.Home
            ? <a href="#faq"><span>FAQ</span></a>
            : <NavLink to={Paths.Home}><span>FAQ</span></NavLink>}
          <a href="mailto:admin@cryptolocally.com"><span>Contact</span></a>
        </div>
        <div className="flex flex-col items-start gap-4">
          <h5>Legal</h5>
          <NavLink to={Paths.TermsOfUse}><span>Terms of Use</span></NavLink>
          <NavLink to={Paths.PrivacyPolicy}><span>Privacy Policy</span></NavLink>
        </div>
        <div className="flex flex-col items-start gap-4">
          <h5>About</h5>
          <span>Blog</span>
        </div>
      </div>
    </div>
    <span className="text-[12px]">© 2019 - 2024 Cryptolocally.com. All rights reserved.</span>
  </footer>
);
