import { SVGAttributes } from 'react';
import { AppElement, AppFC } from '../../../../interfaces';

export const FilledStarIcon: AppFC<SVGAttributes<SVGElement>> = ({ height = 18, width = 18, ...props }): AppElement => (
  <svg width={width} height={height} viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18 1.12978L22.8417 10.9401L22.958 11.1759L23.2182 11.2137L34.0445 12.7868L26.2105 20.4231L26.0223 20.6066L26.0667 20.8657L27.9161 31.6483L18.2327 26.5574L18 26.4351L17.7673 26.5574L8.08393 31.6483L9.9333 20.8657L9.97773 20.6066L9.7895 20.4231L1.95546 12.7868L12.7818 11.2137L13.042 11.1759L13.1583 10.9401L18 1.12978Z" fill="url(#paint0_linear_1717_888)" stroke="#FF9D0A" />
    <defs>
      <linearGradient id="paint0_linear_1717_888" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F7CF00" />
        <stop offset="1" stopColor="#F9A400" />
      </linearGradient>
    </defs>
  </svg>
);
