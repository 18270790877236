import React from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { AvatarProps } from './types';

export const Avatar: AppFC<AvatarProps> = ({
  src,
  className,
  size,
  ...rest
}): AppElement => <img className={`w-${size} h-${size} object-cover border-[0.5px] border-grey rounded-full`} src={src} alt="img" {...rest} />;
