import { useMemo, useReducer, createContext } from 'react';

import { walletReducer } from './reducer';
import { AppElement, AppFC } from '../../../interfaces';
import {
  ContextProviderPropsInterface,
  UseReducerType,
} from '../../interfaces';
import {
  WalletActionType,
  WalletContextInterface,
  WalletsInterface,
} from './interfaces';

import { walletInitialState } from './initialState';

export const WalletContext = createContext<WalletContextInterface>({} as WalletContextInterface);

export const WalletContextProvider: AppFC<ContextProviderPropsInterface> = ({ children }): AppElement => {
  const [walletState, updateWalletState] = useReducer<
  UseReducerType<WalletsInterface, WalletActionType>
  >(walletReducer, walletInitialState);

  const value = useMemo(() => ({
    walletState,
    updateWalletState,
  }), [walletState]);

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
};
