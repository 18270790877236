import { AppElement, AppFC } from '../../../../interfaces';
import { AuthToken } from '../../../../services';
import signUpImage from '../../../../assets/images/guides/1. Sign up.png';
import signUpCompleteImage from '../../../../assets/images/guides/2. Complete account.png';
import buySellImage from '../../../../assets/images/guides/11. Buy and Sell.png';
import sellOfferImage from '../../../../assets/images/guides/18. Select Sell in Offers .png';
import tradeImage from '../../../../assets/images/guides/19. Opening the trade (sell).png';
import fundEscrowImage from '../../../../assets/images/guides/20. Fund Escrow (Sell).png';
import proceedPaymentImage from '../../../../assets/images/guides/21. Wait for buyer to proceed with payment .png';
import releaseEscrowImage from '../../../../assets/images/guides/22. Release the escrow and complete trade .png';

export const GuideSellCrypto: AppFC = (): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = !!authToken.getAccessToken() && !!authToken.getRefreshToken();

  return (
    <div className="flex flex-col flex-1 h-auto w-screen bg-white text-black [&_h3]:leading-[2rem] [&_p]:text-[1.25rem] [&_p]:leading-[2.25rem] [&_p]:font-normal [&_a]:text-[1.25rem] [&_a]:leading-[2.25rem] [&_a]:font-normal [&_a]:text-tertiary">
      <div className={`flex flex-col gap-10 pt-12 pb-16 bg-[#f2f5f9] ${!isAuth ? 'px-[7%]' : 'px-[3%]'} [&_img]:rounded-lg [&_img]:max-w-5xl [&_img]:w-full`}>
        <div className="">
          <h2 className="font-semibold mb-6">How to sell crypto</h2>
          <p>
            CryptoLocally is a platform designed for everyone, especially with our design overhaul. New users will find the transaction intuitive while experienced traders will appreciate the simple yet secure trade procedures. This guide will show you how to sell crypto, step-by-step.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">1. Sign Up</h3>
          <p>
            You must have an account for CryptoLocally to make an offer. Don’t worry, signing up on CryptoLocally is quick and simple.
          </p>
          <p>
            Input your email address and choose a CryptoLocally username and password. After verifying your email and entering some more basic information, you are ready to start trading on CryptoLocally!
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] mt-6 mb-12">
            <img src={signUpImage} alt="Sign up" />
            <span>
              Image 1. Creating an account
            </span>
          </div>
          <p>
            After verifying your email, you will be asked for some more basic information, like your name, country of residence, and phone number. Then, please enter the code we sent to your phone number. All verification helps build a basis of trust for new users.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={signUpCompleteImage} alt="Sign up complete" />
            <span>
              Image 2. Entering more basic information to sign up
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">2. Click on Buy & Sell</h3>
          <p>
            Click on “Buy & Sell” to view all the offers on the platform (see image 3).
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={buySellImage} alt="Buy & Sell" />
            <span>
              Image 3. Click “Buy & Sell”
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">3. Click on Sell and Select offer</h3>
          <p>
            Click on Sell on the left to view the list of users who want to sell cryptocurrency on the platform. You can filter offers by cryptocurrency, currency, location, and payment methods. Click on the “Sell” button on the offer to view more information about it (see image 4).
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={sellOfferImage} alt="Sell offer" />
            <span>
              Image 4. Show offers to sell
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">4. Open trade</h3>
          <p>
            In this example, let&apos;s assume you want to sell BTC, after you press the sell button you will be directed to the first step of the transaction: Open Trade (see image 5). During this step, you will see more information about the terms of the trade and trader information. If you do not agree with the terms, you can go back to the previous page and choose another offer.
          </p>
          <p>
            <b>To start the trade</b>
            , input the amount of BTC you want to sell. click on “Open Trade.”
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={tradeImage} alt="Trade" />
            <span>
              Image 5. Opening the trade
            </span>
          </div>
        </div>
        {/* <div className="flex flex-col"> */}
        {/*   <h3 className="mb-8">5. Discuss payment details</h3> */}
        {/*   <p> */}
        {/*     Discuss and finalize payment terms with the buyer (see image 6). Once you have discussed payment details with the buyer, click on “Yes we agreed”. If you are not happy with the terms, you can cancel the trade. */}
        {/*   </p> */}
        {/*   <div className="flex flex-col items-center [&>span]:text-[1rem] my-6"> */}
        {/*     <img src={tradeImage} alt="Trade" /> */}
        {/*     <span> */}
        {/*       Image 6. Confirm the agreement on terms */}
        {/*     </span> */}
        {/*   </div> */}
        {/* </div> */}
        <div className="flex flex-col">
          <h3 className="mb-8">5. Fund escrow</h3>
          <p>
            On this step, you will be required to deposit the BTC to the smart escrow (see image 7). The deposit can be made from either your balance on the platform or your external wallet.
          </p>
          <p>
            NOTICE: Both Addresses, MEMO (if selling EOS, BNB etc…), and the correct amount of crypto (here BTC) are required to successfully fund the escrow!
          </p>
          <p>
            Our unique smart contract escrow system protects all the trades on CryptoLocally until the end of each transaction. The fund is held in the escrow in the name of the user temporarily. Then, after the seller confirms that they have received the payment, the escrow is released and the tokens are sent to the buyer’s wallet. This process allows buyers and sellers to complete trades safely and trustfully.
          </p>
          <p>
            Keep in mind that you, as a seller, need to consider the transaction fee, while transferring BTC, to make sure that the smart contract receives the exact amount needed to move on to the next step.
          </p>
          <p>
            Once you have fully deposited the BTC, the buyer will be alerted.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={fundEscrowImage} alt="Fund escrow" />
            <span>
              Image 6. Fund escrow
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">6. Receive Payment</h3>
          <p>
            You have successfully funded the escrow. After you have given the buyer your payment information, you must wait for him to pay the required amount before releasing the escrow to complete the trade. The buyer will have a time limit of 120 minutes to send the payment.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={proceedPaymentImage} alt="Proceed with payment" />
            <span>
              Image 7. Wait for buyer to proceed with payment
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">7. Release escrow</h3>
          <p>
            Once you have received the payment from the buyer, you can release the escrow to complete the transaction by clicking on “Release escrow” (see image 9).
          </p>
          <p>
            <b>You should NEVER release escrow before you have received payment!</b>
          </p>
          <p>
            At this point, if the seller does not respond or does not release the escrow, you can open a dispute and an admin will have a look at the transaction.
          </p>
          <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
            <img src={releaseEscrowImage} alt="Release escrow" />
            <span>
              Image 8. Release the escrow and complete trade
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-8">8. Trade Completed</h3>
          <p>
            Congratulations! You just completed your first trade on CryptoLocally!
          </p>
          <p>
            Both parties are able to go back to review the status of the trade anytime by clicking on My Trades.
          </p>
          <p>
            Users are able to rate the completed trade by using the comment feature, to offer the other trader feedback. By rating other users and receiving ratings, you can grow your trust among other CryptoLocally users and build your reputation.
          </p>
          <p>
            We hope you enjoy trading on CryptoLocally! Check out our guides on how to sell crypto and create your offer.
          </p>
        </div>
        <p>
          If for some reason you have trouble with selling crypto, please contact support:
          {' '}
          <a href="mailto:support@cryptolocally.com" target="_blank" rel="noreferrer">support@cryptolocally.com</a>
        </p>
      </div>
    </div>
  );
};
