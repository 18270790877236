import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const EyeSlashIcon: AppFC<HTMLAttributes<HTMLDivElement> & { error:boolean }> = ({ error, ...rest }): AppElement => (
  <div {...rest}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.52985 2.47009C3.38767 2.33761 3.19963 2.26549 3.00532 2.26892C2.81102 2.27234 2.62564 2.35106 2.48822 2.48847C2.35081 2.62588 2.2721 2.81127 2.26867 3.00557C2.26524 3.19987 2.33737 3.38792 2.46985 3.53009L20.4698 21.5301C20.5385 21.6038 20.6213 21.6629 20.7133 21.7039C20.8053 21.7449 20.9046 21.7669 21.0053 21.7687C21.106 21.7705 21.2061 21.7519 21.2994 21.7142C21.3928 21.6765 21.4777 21.6203 21.5489 21.5491C21.6201 21.4779 21.6762 21.3931 21.714 21.2997C21.7517 21.2063 21.7702 21.1063 21.7684 21.0056C21.7667 20.9049 21.7446 20.8056 21.7036 20.7136C21.6626 20.6216 21.6035 20.5388 21.5298 20.4701L3.52985 2.47009ZM22.6758 12.5531C22.1368 14.1706 21.2371 15.6444 20.0448 16.8631L16.9458 13.7641C17.2802 12.828 17.3419 11.8163 17.1239 10.8465C16.9058 9.87669 16.4169 8.98874 15.7141 8.28588C15.0112 7.58301 14.1232 7.09413 13.1535 6.87607C12.1837 6.65802 11.1719 6.71975 10.2358 7.05409L7.75885 4.57709C9.10587 4.02905 10.5466 3.74817 12.0008 3.75009C16.9708 3.75009 21.1858 6.97309 22.6758 11.4401C22.7958 11.8021 22.7958 12.1921 22.6758 12.5531Z" fill={error ? 'rgba(255, 40, 40, 0.8)' : '#7B8BA5'} />
      <path d="M15.7498 12.0001C15.7498 12.1801 15.7368 12.3571 15.7128 12.5301L11.4688 8.28705C12.0008 8.21096 12.5429 8.25009 13.0584 8.4018C13.5739 8.5535 14.0508 8.81424 14.4568 9.16635C14.8628 9.51846 15.1883 9.95371 15.4114 10.4426C15.6345 10.9315 15.7499 11.4627 15.7498 12.0001ZM12.5298 15.7131L8.28677 11.4691C8.20437 12.0454 8.25731 12.6331 8.4414 13.1855C8.62548 13.7378 8.93567 14.2397 9.34737 14.6515C9.75908 15.0632 10.261 15.3733 10.8134 15.5574C11.3657 15.7415 11.9534 15.7945 12.5298 15.7121V15.7131Z" fill={error ? 'rgba(255, 40, 40, 0.8)' : '#7B8BA5'} />
      <path d="M6.75037 12C6.75037 11.381 6.85737 10.787 7.05437 10.236L3.95438 7.13599C2.76248 8.35483 1.86316 9.82861 1.32437 11.446C1.20437 11.808 1.20437 12.198 1.32437 12.56C2.81337 17.027 7.02837 20.25 11.9994 20.25C13.4994 20.25 14.9324 19.956 16.2414 19.423L13.7644 16.946C12.972 17.2286 12.1234 17.3168 11.2898 17.2029C10.4563 17.0891 9.66237 16.7766 8.97485 16.2917C8.28734 15.8069 7.7264 15.164 7.33927 14.4171C6.95215 13.6702 6.75018 12.8412 6.75037 12Z" fill={error ? 'rgba(255, 40, 40, 0.8)' : '#7B8BA5'} />
    </svg>
  </div>
);
