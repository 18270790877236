import { useState } from 'react';

import { AppElement, AppFC } from '../../../interfaces';
import { InputProps } from './types';
import { getNumberValue } from '../../../utils';

import {
  EyeIcon, EyeSlashIcon, BlockedIcon, CopyIcon,
} from '../icons';

export const Input: AppFC<InputProps> = ({
  className,
  prefixIcon,
  suffixIcon,
  disabled,
  blocked,
  copyable,
  type = 'text',
  error,
  min,
  max,
  onChange,
  ...rest
}): AppElement => {
  const [inputType, setInputType] = useState(type);
  const handleIconClick = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  const hasSuffixIcon = suffixIcon || copyable || blocked || type === 'password';

  return (
    <div className={`relative ${blocked || disabled ? 'text-disabled' : 'text-primary'} ${className}`}>
      {prefixIcon && <div className="absolute z-[5] translate-y-[-50%] top-[50%] left-4">{prefixIcon}</div>}
      <input
        className={`relative px-4 py-3 ${hasSuffixIcon ? 'pr-10' : ''} ${prefixIcon ? 'pl-10' : ''} w-full h-12 rounded-lg focus:outline-none placeholder-red ${blocked || disabled ? 'text-disabled pointer-events-none focus:border-default pr-10' : 'text-primary border'} ${error ? 'border-error bg-error focus:bg-error' : 'border-default focus:border-focus bg-input focus:bg-inputFocus'} ${className}`}
        type={inputType === 'number' ? 'text' : inputType}
        disabled={blocked || disabled}
        onChange={(event) => {
          const { value } = event.target;
          if (inputType === 'number') {
            let formattedValue = value.replace(/,/g, '.');

            // Remove all characters except digits and one period
            formattedValue = formattedValue.replace(/[^0-9.]/g, '');

            // Replace multiple periods with a single one (e.g., "0..5" becomes "0.5")
            formattedValue = formattedValue.replace(/(\..*)\./g, '$1');

            if (value !== '' && value !== '0') {
              formattedValue = formattedValue.replace(/^0+([0-9])/, '$1');
            }

            if (formattedValue === '') {
              // eslint-disable-next-line no-param-reassign
              event.target.value = '';
            } else {
              // eslint-disable-next-line no-param-reassign
              event.target.value = getNumberValue(formattedValue, min, max);
            }
          }
          if (onChange) {
            onChange(event);
          }
        }}
        {...rest}
      />
      <div className="flex items-center absolute z-10 translate-y-[-50%] top-[50%] gap-2 right-4">
        {type === 'password' && (
          <>
            {
                  inputType === 'password'
                    ? (
                      <EyeSlashIcon
                        onClick={handleIconClick}
                        error={!!error}
                      />
                    )
                    : (
                      <EyeIcon
                        onClick={handleIconClick}
                        error={!!error}
                      />
                    )
                }
          </>
        )}
        {suffixIcon || null}
        {blocked && <BlockedIcon />}
        {copyable && <CopyIcon />}
      </div>
      {!!error && <p className="absolute w-full text-[10px] leading-6 text-end text-error">{error.message}</p>}
    </div>
  );
};
