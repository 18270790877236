import React, { useContext } from 'react';

import { ViewContext } from '../../../stores';

import { AppElement, AppFC } from '../../../interfaces';
import { InfoCategory, ModalTypes } from '../../../constants';

import { Modal } from '../../Molecules';
import {
  ImageUpload, Info, WithdrawFunds, Delete, QrCode,
} from './contents';

export const AppModal:AppFC = (): AppElement => {
  const { viewModal } = useContext(ViewContext);
  const getModal = () => {
    switch (viewModal.modalType) {
      case ModalTypes.ImageUploadModal:
        return (
          <Modal className="h-fit bg-default self-center justify-self-center max-w-[700px] overflow-hidden">
            <ImageUpload />
          </Modal>
        );

      case ModalTypes.WithdrawModal:
        return (
          <Modal className="h-fit bg-default self-center justify-self-center max-w-[700px] overflow-hidden">
            <WithdrawFunds />
          </Modal>
        );

      case ModalTypes.QrCodeModal:
        return (
          <Modal className="h-fit bg-default my-auto py-14 px-20 max-md:px-6 max-md:h-full">
            <QrCode />
          </Modal>
        );

      case ModalTypes.DeleteModal:
        return (
          <Modal className="h-fit bg-default my-auto py-14 px-14 max-md:px-6 max-md:h-full">
            <Delete />
          </Modal>
        );

      case ModalTypes.SuccessModal:
        return (
          <Modal className="h-fit bg-default self-center max-w-[500px] max-md:h-full max-md:max-w-full p-8 max-md:px-6">
            <Info category={InfoCategory.Success} />
          </Modal>
        );

      case ModalTypes.FailModal:
        return (
          <Modal className="h-fit bg-default self-center max-w-[500px] max-md:h-full max-md:max-w-full p-8 max-md:px-6">
            <Info category={InfoCategory.Error} />
          </Modal>
        );

      case ModalTypes.DefaultModal:
        return (
          <Modal className="h-fit self-center" />
        );

      default:
        return <Modal />;
    }
  };
  if (!viewModal?.isOpen) {
    return null;
  }

  return (
    <div className="flex justify-center overflow-x-hidden fixed bg-black bg-opacity-70 top-0 w-full h-full z-50">
      {getModal()}
    </div>
  );
};
