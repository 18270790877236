import { useContext } from 'react';

import { httpRequest } from '../../services';
import { OfferParamsType, OffersUpdateDataProps } from '../../services/httpRequest';

import { OfferActionTypes, OfferContext, OfferFilterInterface } from '../contexts';

import { OFFER_API, OfferStatusTypes, RequestTypes } from '../../constants';
import countries from '../../utils/countries.json';

export const useOffer = () => {
  const {
    CREATE,
    GET,
    DELETE,
    PUT,
    PATCH,
  } = OFFER_API;

  const { updateOfferState } = useContext(OfferContext);

  const getOffers = async (params?: OfferParamsType) => {
    const search: string[] = [];
    if (params) {
      Object.keys(params).forEach((key) => {
        const typedKey = key as keyof OfferParamsType;
        const value = params[typedKey];
        if (Array.isArray(value)) {
          (value as Array<string>).forEach((item) => {
            if (item) {
              search.push(`${key}=${item}`);
            }
          });
        } else if (value) {
          search.push(`${key}=${value}`);
        }
      });
    }
    const queryParams = search.length ? `?${search.join('&')}` : '';

    const response = await httpRequest({
      url: `${GET}${queryParams}`,
      method: RequestTypes.Get,
      withoutToken: true,
    });

    if (params?.OfferStatus === OfferStatusTypes.Draft) {
      updateOfferState({
        type: OfferActionTypes.GetDraftOffers,
        payload: response.data,
      });
    } else if (params?.OfferStatus === OfferStatusTypes.Open) {
      updateOfferState({
        type: OfferActionTypes.GetPublishedOffers,
        payload: response.data,
      });
    }

    return response.data;
  };

  const getOfferById = async (id: string, params?: OfferParamsType) => {
    const response = await httpRequest({
      url: `${GET}/${id}`,
      params,
      method: RequestTypes.Get,
    });

    return response.data;
  };

  const deleteOffer = async (id: string) => {
    const response = await httpRequest({
      url: `${DELETE}/${id}`,
      method: RequestTypes.Delete,
    });

    return response.data;
  };

  const createOffer = async (data: OffersUpdateDataProps) => {
    const response = await httpRequest({
      url: CREATE,
      method: RequestTypes.Post,
      data,
    });

    if (response.status === 200) {
      data.offerStatus === OfferStatusTypes.Draft ? updateOfferState({
        type: OfferActionTypes.UpdateDraftOffers,
        payload: response.data,
      }) : updateOfferState({
        type: OfferActionTypes.UpdatePublishedOffers,
        payload: response.data,
      });
    }

    return response.data;
  };

  const updateDraftOffer = async (data: OffersUpdateDataProps, offerId: string) => {
    const response = await httpRequest({
      url: `${PUT}/${offerId}`,
      method: RequestTypes.Put,
      data,
    });

    if (response.status === 200) {
      updateOfferState({
        type: OfferActionTypes.UpdateDraftOffers,
        payload: response.data,
      });
    }

    return response.data;
  };

  const publishDraftOffer = async (offerId: string) => {
    const response = await httpRequest({
      url: `${PATCH}/${offerId}/publish`,
      method: RequestTypes.Patch,
    });

    return response.data;
  };

  const getCountryCodeByIP = async (ipAddress?: string) => {
    const currentCountry = await fetch(`https://ipapi.co/${ipAddress}/country/?format=json`).then((res) => res.text());
    if (currentCountry) {
      const countryByIP = countries.find((country) => country.isoAlpha2 === currentCountry);
      // countryByIP?.isoAlpha3 !== 'USA' ?
      updateOfferState({
        type: OfferActionTypes.UpdateOfferFilter,
        payload: {
          country: countryByIP?.name,
          currencyCode: 'USDDollarUnited States', // countryByIP ? countryByIP.currency.code + countryByIP.currency.name + countryByIP.name : '',
        } as OfferFilterInterface,
      });
    }
  };

  return ({
    getOffers,
    getOfferById,
    createOffer,
    updateDraftOffer,
    publishDraftOffer,
    deleteOffer,
    getCountryCodeByIP,
  });
};
