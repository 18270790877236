import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { ProfileContext } from '../../../../stores';
import { useAuth } from '../../../../stores/hooks';

import { AuthToken } from '../../../../services';
import { AppElement, AppFC } from '../../../../interfaces';
import { MenuProps } from './types';

import {
  ButtonCategory, HeaderCategory, Paths,
} from '../../../../constants';
import { Button, Loader, Avatar } from '../../../Atoms';

import offersIcon from '../../../../assets/icons/offers.svg';
import walletsIcon from '../../../../assets/icons/wallets.svg';

export const ProfileMenu: AppFC<MenuProps> = ({
  category = HeaderCategory.App, toggleMenu, menuIsOpen,
}): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = authToken.getAccessToken() && authToken.getRefreshToken();
  const profileMenuRef = useRef<HTMLDivElement | null>(null);

  const { profileState: { userName, profileImage } } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { signOut } = useAuth();

  const handleOutsideClick = (e: MouseEvent) => {
    if (profileMenuRef.current && !profileMenuRef.current.contains(e.target as Node)) {
      if (menuIsOpen) {
        toggleMenu(false);
      }
    }
  };
  const handleLogoutClick = () => {
    setIsLoading(true);
    signOut().then(() => {
      navigate(Paths.Home);
      setIsLoading(false);
      toggleMenu(!menuIsOpen);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuIsOpen]);

  return (
    <div ref={profileMenuRef} className={`absolute bg-default p-4 top-20 ${isAuth && category === HeaderCategory.App ? 'right-[3%] max-lg:right-[calc(3%_+_46px)]' : 'right-[7%] max-lg:right-[calc(7%_+_46px)]'} flex flex-col items-end gap-6 min-w-[200px] rounded-2xl z-40`}>
      {isAuth && category === HeaderCategory.App && (
      <>
        <div className="flex items-center gap-4 h-10 self-start">
          <Button
            className={`w-10 h-10 p-0 rounded-[50%] overflow-hidden ${profileImage ? 'bg-black' : ''}`}
            category={profileImage ? ButtonCategory.Default : ButtonCategory.Filled}
          >
            {!profileImage ? userName?.substring(0, 1).toUpperCase()
              : <Avatar src={profileImage} size={10} />}
          </Button>
          <div className="h-full flex flex-col justify-between">
            <h5 className="leading-[100%]">{userName}</h5>
            <NavLink to={Paths.ProfileEdit} className="leading-[100%] text-tertiary underline">
              View Profile
            </NavLink>
          </div>
        </div>
        <div className="flex flex-col gap-3 w-full [&>a]:h-9 [&>a]:flex [&>a]:gap-2 [&>a]:items-center">
          <NavLink to={Paths.MyOffers}>
            <img src={offersIcon} alt="" />
            My Offers
          </NavLink>
          <NavLink to={Paths.Trades}>
            <img src={walletsIcon} alt="" />
            Trades
          </NavLink>
          <NavLink to={Paths.Wallets}>
            <img src={walletsIcon} alt="" />
            Wallets
          </NavLink>
        </div>

        <button
          className="h-10 w-full text-tertiary self-center"
          onClick={handleLogoutClick}
        >
          {isLoading ? <Loader /> : 'Log Out'}
        </button>
      </>
      )}
    </div>
  );
};
