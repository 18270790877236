import React, { useContext, useEffect, useState } from 'react';

import { AppElement, AppFC } from '../../../interfaces';
import { MyOfferBoxProps } from './types';
import { cryptoCurrencyNames, MarketTypes } from '../../../constants';

import {
  generateDemandedCurrencyBox,
  generateOfferedCurrencyBox, numberWithCommas,
} from '../../../utils';

import { Button, ListItem, Loader } from '../../Atoms';
import {
  CountryBox, CurrencyPriceBox, OfferTypeBox, PaymentMethodBox,
} from '../../Atoms/offerItems';
import { ViewContext, ViewModalActionTypes } from '../../../stores';
import { useCurrency } from '../../../stores/hooks';

export const MyOfferBox: AppFC<MyOfferBoxProps> = ({
  offer,
  onDeleteClick,
  isDeleting,
}): AppElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [rate, setRate] = useState<{ rate: string, currency: string }>();

  const { updateViewModal } = useContext(ViewContext);

  const { getCryptocurrencyRate } = useCurrency();
  const {
    id: offerId,
    offerType,
    offeredCurrency,
    demandedCurrency,
    margin,
    paymentMethodTypes,
  } = offer;

  const price = numberWithCommas((Number(rate?.rate) * (100 + margin)) / 100, demandedCurrency, 2);

  const handleDelete = () => {
    updateViewModal({
      type: ViewModalActionTypes.DeleteModal,
      payload: {
        title: 'Delete offer',
        description: 'Are you sure you want to delete this offer?',
        isClosable: false,
        onDelete: () => onDeleteClick(offerId),
      },
    });
  };

  useEffect(() => {
    getCryptocurrencyRate(demandedCurrency, offeredCurrency).then((res) => {
      setRate(res);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, []);

  const marketType = (margin > 0 && MarketTypes.Above) as MarketTypes
      || (margin < 0 && MarketTypes.Below) as MarketTypes
      || (margin === 0 && MarketTypes.None) as MarketTypes;

  return offerId ? (
    <ListItem>
      <div className="w-full flex gap-8 items-center justify-between flex-[1.25]">
        <OfferTypeBox
          className="md:flex-1"
          offerType={offerType}
          offeredCurrency={generateOfferedCurrencyBox(offeredCurrency)}
          demandedCurrency={generateDemandedCurrencyBox(demandedCurrency)}
        />
        {!isLoading ? (
          <CurrencyPriceBox
            className="md:flex-1 max-md:items-end"
            price={price}
            margin={Math.abs(margin)}
            marketType={marketType}
            info={`This offer is ${margin === 0 ? 'equal to' : `${margin}% ${marketType}`} ${cryptoCurrencyNames[offeredCurrency]} current market price.`}
          />
        ) : <div className="flex-1"><Loader /></div>}
      </div>
      <div className="w-full flex gap-8 items-center justify-between flex-1 lg:flex-[1.75]">
        <PaymentMethodBox
          className="md:flex-1"
          methods={paymentMethodTypes}
        />
        <CountryBox
          className="md:flex-1 max-md:items-end"
          offer={offer}
        />
      </div>
      <Button className="w-20 h-10 text-red-800 max-md:self-end underline p-0" onClick={handleDelete} isLoading={isDeleting}><span>Delete</span></Button>
    </ListItem>
  ) : null;
};
