import { AppElement, AppFC } from '../../../interfaces';

import dotIcon from '../../../assets/icons/dot.svg';
import { UsernameBoxProps } from './types';
import filledStarIcon from '../../../assets/icons/stars/filledStar.svg';

export const UsernameBox: AppFC<UsernameBoxProps> = ({
  username,
  tradeCount,
  percentage,
  className,
  rate,
  ...rest
}): AppElement => (
  <div {...rest} className={`flex flex-col gap-2 ${className || ''}`}>
    <h5 className="leading-none text-tertiary">{username}</h5>
    <h6 className="flex items-center leading-none text-secondary">
      {percentage
      && (
      <>
        {`${percentage}%`}
        <img className="h-1 w-1 mx-2" src={dotIcon} alt="" />
      </>
      )}
      {(tradeCount || tradeCount === 0) && `${tradeCount} trades`}
      {(rate || rate === 0) && (
      <div className="flex items-start gap-1">
        <img
          src={filledStarIcon}
          height={12}
          width={12}
          alt="filled"
        />
        <span className="leading-none text-white text-[13px]">{rate.toFixed(1)}</span>
      </div>
      )}
    </h6>
  </div>
);
