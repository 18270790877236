import { useMemo, useReducer, createContext } from 'react';

import { offerReducer } from './reducer';
import { AppElement, AppFC } from '../../../interfaces';
import {
  ContextProviderPropsInterface,
  UseReducerType,
} from '../../interfaces';
import {
  OfferActionType,
  OfferContextInterface,
  OffersInterface,
} from './interfaces';

import { offerInitialState } from './initialState';

export const OfferContext = createContext<OfferContextInterface>({} as OfferContextInterface);

export const OfferContextProvider: AppFC<ContextProviderPropsInterface> = ({ children }): AppElement => {
  const [offerState, updateOfferState] = useReducer<
  UseReducerType<OffersInterface, OfferActionType>
  >(offerReducer, offerInitialState);

  const value = useMemo(() => ({
    offerState,
    updateOfferState,
  }), [offerState]);

  return (
    <OfferContext.Provider value={value}>{children}</OfferContext.Provider>
  );
};
