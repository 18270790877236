import { AppElement, AppFC } from '../../../../interfaces';
import { AuthToken } from '../../../../services';

export const PrivacyPolicy: AppFC = (): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = !!authToken.getAccessToken() && !!authToken.getRefreshToken();

  return (
    <div className="flex flex-col flex-1 h-auto w-screen bg-white text-black [&_h3]:leading-[2rem] [&_p]:text-[1.25rem] [&_p]:leading-[2.25rem] [&_p]:font-normal [&_a]:text-[1.25rem] [&_a]:leading-[2.25rem] [&_a]:font-normal [&_a]:text-tertiary">
      <div className={`pt-14 pb-10  ${!isAuth ? 'px-[7%]' : 'px-[3%]'}`}>
        <h2 className="font-semibold pb-2">Privacy Policy</h2>
        <p>Everything you need to know.</p>
      </div>
      <div className={`flex flex-col gap-10 pt-8 pb-24 bg-[#f2f5f9] ${!isAuth ? 'px-[7%]' : 'px-[3%]'}`}>
        <p>
          This policy sets out the basis on which CryptoLocally of Hong Kong (together with our subsidiaries, our holding company, subsidiaries of our holding company from time to time, collectively called “CryptoLocally” or “we”) collects personal data from you and how we process such data.
          By visiting our website cryptolocally.com (our “Site”) or any other applications or software we provide from time to time (collectively our “App”), you accept and consent to the practices set out below.
        </p>
        <div className="flex flex-col">
          <h3 className="mb-4">1 Collection of information</h3>
          <p>
            1.1 For the purposes outlined in Clause 2, we may collect and process the following information about you:
          </p>
          <p>
            (a) Information you give us - information that you provide us (which may include your name, address, email address, telephone number, credit card information and other personal description) by filling in forms on our Site or our App, or by corresponding with us (by phone, email or otherwise), for example:
          </p>
          <p>
            (i) when you report any problem to us;
          </p>
          <p>
            (ii) when you use certain features on our Site or our App;
          </p>
          <p>
            (iii) when you request any support from us; or
          </p>
          <p>
            (iv) when you complete any survey or questionnaire we send you.
          </p>
          <p>
            (b) Information we collect about you - information automatically collected when you visit our Site or use our App, for example:
          </p>
          <p>
            (i) technical information, including the Internet protocol (IP) address used to connect your computer to the Internet and your log-in information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;
          </p>
          <p>
            (ii) information about your visit, including the full Uniform Resource Locators (URLs), clickstream to, through and from our site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer service number.
          </p>
          <p>
            1.2 We only retain personal data for so long as it is necessary. Data may be archived as long as the purpose for which the data was used still exists.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-4">2 Uses made of the information</h3>
          <p>
            We use information held about you for the following purposes:
          </p>
          <p>
            (a) providing, improving and developing our services;
          </p>
          <p>
            (b) researching, designing and launching new features or products;
          </p>
          <p>
            (c) presenting content and information in our Site and our App in the most effective manner for you and for the device you use;
          </p>
          <p>
            (d) providing you with alerts, updates, materials or information about our services or other types of information that you requested or signed up to;
          </p>
          <p>
            (e) collecting overdue amounts;
          </p>
          <p>
            (f) in any part of the world;
          </p>
          <p>
            (g) responding or taking part in legal proceedings, including seeking professional advice;
          </p>
          <p>
            (h) communicating with you and responding to your questions or requests; and
          </p>
          <p>
            (i) purposes directly related or incidental to the above.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-4">3 Disclosure of your information</h3>
          <p>
            We will keep your personal data we hold confidential but you agree we may provide information to:
          </p>
          <p>
            (a) any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in Schedule 1 of the Securities and Futures Ordinance (Cap. 571) of the Law of Hong Kong;
          </p>
          <p>
            (b) personnel, agents, advisers, auditors, contractors, financial institutions, and service providers in connection with our operations or services (for example staff engaged in the fulfilment of your order, the processing of your payment and the provision of support services);
          </p>
          <p>
            (c) our overseas offices, affiliates, business partners and counterparts (on a need-to-know basis only);
          </p>
          <p>
            (d) persons under a duty of confidentiality to us;
          </p>
          <p>
            (e) persons to whom we are required to make disclosure under applicable laws and regulations in any part of the world; or
          </p>
          <p>
            (f) actual or proposed transferees of our operations (or a substantial part thereof) in any part of the world.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-4">4 Cookies</h3>
          <p>
            4.1 Our Site uses cookies to distinguish you from other users of the Site. This helps us to provide you with a good experience when you browse our Site and also allows us to improve our Site.
          </p>
          <p>
            4.2 A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree to the use of cookies. Cookies contain information that is transferred to your computer`&quot;s hard drive.
          </p>
          <p>
            4.3 We use persistent cookies and session cookies. A persistent cookie stays in your browser and will be read by us when you return to our Site or a partner site that uses our services. Session cookies only last for as long as the session (usually the current visit to a website or a browser session).
          </p>
          <p>
            4.4 We use the following cookies:
          </p>
          <p>
            (a) Strictly necessary cookies – These are cookies that are required for the operation of our Site. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.
          </p>
          <p>
            (b) Analytical/performance cookies – They allow us to recognise and count the number of visitors and to see how visitors move around our Site when they are using it. This helps us to improve the way our Site works, for example, by ensuring that users are finding what they are looking for easily.
          </p>
          <p>
            (c) Functionality cookies – These are used to recognise you when you return to our Site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
          </p>
          <p>
            4.5 You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you do so, you may not be able to access all or parts of our Site.
          </p>
          <p>
            4.6 Google provides ways to manage or opt out certain of its
            {' '}
            <a href="https://adssettings.google.com" target="_blank" rel="noreferrer">advertising cookies</a>
            {' '}
            and
            {' '}
            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">analytics cookies</a>
            . You may read Google’s Privacy Policy
            {' '}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">here</a>
            .
          </p>
          <p>
            4.7 You may also visit our sites in your browser’s &quot;private&quot; or &quot;incognito&quot; mode, in which case cookies will be set, but deleted when you close the browser.
          </p>
          <p>
            4.8 We may use third-party web services on our Site. The service providers that administer these services use technologies such as cookies (which are likely to be analytical/performance cookies or targeting cookies), web server logs and web beacons to help us analyse how visitors use our Site and make the information displayed on it more relevant to your interests. The information collected through these means (including IP addresses) is disclosed to these service providers.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-4">5 Third-party sites</h3>
          <p>
            Our Site, our App or our communication with you may from time to time contain links to third-party websites over which we have no control. If you follow a link to any of these websites, please note that they have their own practices and policies. We encourage you to read the privacy policies or statements of these websites understand your rights. We accept no responsibility or liability for any practices of third-party websites.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-4">6 Security</h3>
          <p>
            6.1 All information you provide to us is stored on our secure servers.
          </p>
          <p>
            6.2 Where we have given you (or where you have chosen) a password that enables you to access certain parts of the Site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
          </p>
          <p>
            6.3 We restrict access to personal information to our employees, service providers and contractors on a strictly need-to-know basis and ensure that those persons are subject to contractual confidentiality obligations.
          </p>
          <p>
            6.4 We review our information collection, storage and processing practices from time to time to guard against unauthorised access, processing or use.
          </p>
          <p>
            6.5 Please note, however, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Site; any transmission is at your own risk.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-4">7 Data transfer</h3>
          <p>
            7.1 The data that we collect from you may be transferred to, and stored at, a destination outside Hong Kong. It may also be processed by persons referred to in Clause 3 above who operate outside Hong Kong.
          </p>
          <p>
            7.2 By submitting your personal data you agree to this transfer, storing or processing of data outside Hong Kong. We will take all steps reasonably necessary to ensure that your data is treated securely in accordance with this privacy policy.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-4">8 Your rights</h3>
          <p>
            8.1 You have the right to:
          </p>
          <p>
            (a) check whether we hold personal data about you;
          </p>
          <p>
            (b) access any personal data we hold about you; and
          </p>
          <p>
            (c) require us to correct any inaccuracy or error in any personal data we hold about you.
          </p>
          <p>
            8.2 In accordance with the terms of the Ordinance, we have the right to charge a reasonable fee for processing a data access request (8.1). Requests for access to personal data, correction of personal data, information relating to the kind of data held and to stop receiving direct marketing, may be made by sending an email to us at
            {' '}
            <a href="mailto:admin@cryptolocally.com" target="_blank" rel="noreferrer">admin@cryptolocally.com</a>
            . Any such request should also clearly state the details of personal data in respect of which the request is being made.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-4">9 Changes to our privacy policy</h3>
          <p>
            We may amend this policy from time to time by posting the updated policy on our Site. By continuing to use our Site and our App after the changes come into effect means that you agree to be bound by the revised policy.
          </p>
          <p>
            10 Contact us
          </p>
          <p>
            If you have any questions, comments or requests regarding personal data, please address them to:
            {' '}
            <a href="mailto:admin@cryptolocally.com" target="_blank" rel="noreferrer">admin@cryptolocally.com</a>
          </p>
        </div>
        <h3>
          Last updated: 4 July 2024
        </h3>
      </div>
    </div>
  );
};
