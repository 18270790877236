import { AppElement, AppFC } from '../../../interfaces';
import { StatusProps } from './types';
import { TradeStatusTypes } from '../../../constants';

export const Status: AppFC<StatusProps> = ({
  status,
  children,
  className,
  ...props
}): AppElement => {
  let classes = '';
  if (status === TradeStatusTypes.Open) {
    classes = 'text-opened border-opened';
  }
  if (status === TradeStatusTypes.Funded) {
    classes = 'text-funded border-funded';
  }
  if (status === TradeStatusTypes.Paid) {
    classes = 'text-paid border-paid';
  }
  if (status === TradeStatusTypes.Disputed) {
    classes = 'text-disputed border-disputed';
  }
  if (status === TradeStatusTypes.Released) {
    classes = 'text-released border-released';
  }
  if (status === TradeStatusTypes.Refunded) {
    classes = 'text-refunded border-refunded';
  }
  if (status === TradeStatusTypes.Completed) {
    classes = 'text-completed border-completed';
  }

  return (
    <div {...props} className={`w-fit px-5 py-1 border rounded-lg  ${classes} ${className}`}>
      {children}
    </div>
  );
};
