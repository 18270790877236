import { ProfileInterface } from './interfaces';

export const profileInitialState: ProfileInterface = {
  fullName: {
    firstName: '',
    lastName: '',
  },
  userName: '',
  email: '',
  country: '',
};
