import { httpRequest } from '../../services';

import { CryptoCurrencyTypes, CURRENCY_API, RequestTypes } from '../../constants';

export const useCurrency = () => {
  const {
    CURRENCY_RATE,
  } = CURRENCY_API();

  const getCryptocurrencyRate = async (currency?: string, cryptocurrency?: CryptoCurrencyTypes | string) => {
    if (!currency || !cryptocurrency) return null;

    const response = await httpRequest({
      url: `${CURRENCY_RATE}/${cryptocurrency}/${currency}`,
      method: RequestTypes.Get,
    });

    return response.data;
  };

  const getCurrencyGasFee = async (currency: CryptoCurrencyTypes) => {
    const response = await httpRequest({
      url: CURRENCY_API(currency).GET_GAS_FEE,
      method: RequestTypes.Get,
    });

    return response.data;
  };

  return ({
    getCryptocurrencyRate,
    getCurrencyGasFee,
  });
};
