import { httpRequest } from '../../services';
import { UserRatingDataProps } from '../../services/httpRequest';

import { USER_API, RequestTypes } from '../../constants';

export const useUser = () => {
  const getUserById = async (id: string) => {
    const response = await httpRequest({
      url: USER_API(id).USERS,
      method: RequestTypes.Get,
    });

    return response.data;
  };
  const getUserRatingById = async (id: string) => {
    const response = await httpRequest({
      url: USER_API(id).USER_RATINGS,
      method: RequestTypes.Get,
    });

    return response.data;
  };
  const rateUser = async (data: UserRatingDataProps) => {
    const response = await httpRequest({
      url: USER_API().RATINGS,
      method: RequestTypes.Post,
      data,
    });

    return response.data;
  };

  return ({
    getUserById,
    getUserRatingById,
    rateUser,
  });
};
