import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';

import { useWallet } from '../../../../stores/hooks';
import {
  ViewContext,
  WalletContext,
  ViewModalActionTypes,
  WalletInterface,
} from '../../../../stores/contexts';

import { AppElement, AppFC } from '../../../../interfaces';
import {
  ButtonCategory,
  CryptoCurrencyTypes,
  currencies,
} from '../../../../constants';
import { getCurrencyOptions, getNetwork } from '../../../../utils';

import { CopyBox } from '../../../Molecules';
import { Button, Loader, Switch } from '../../../Atoms';

export const Wallets: AppFC = memo((): AppElement => {
  const [activeCurrency, setActiveCurrency] = useState(CryptoCurrencyTypes.BTC);
  const [isLoading, setIsLoading] = useState(true);
  const [isWalletLoading, setWalletIsLoading] = useState(false);

  const { updateViewModal } = useContext(ViewContext);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { walletState: { wallets } } = useContext(WalletContext);
  const { getWallets, createWallet, getWalletBalance } = useWallet();

  const activeWallet = wallets.find((wallet) => wallet.cryptoCurrencyType === activeCurrency);

  const getCurrencyBalances = () => currencies.map((currency) => {
    const existingWallet = wallets.find(({ cryptoCurrencyType }) => cryptoCurrencyType === currency);
    return { [currency]: existingWallet?.balance || 0 };
  });

  const handleWithdrawClick = () => {
    updateViewModal({
      type: ViewModalActionTypes.WithdrawModal,
      payload: {
        isClosable: false,
        ref: modalRef,
        info: {
          wallet: activeWallet,
        },
      },
    });
  };

  const handleWalletClick = (value: CryptoCurrencyTypes) => {
    setActiveCurrency(value);
    const currentWallet = wallets.find((wallet) => wallet.cryptoCurrencyType === value);
    if (!currentWallet) {
      setWalletIsLoading(true);
      createWallet({
        cryptoCurrencyType: value,
        walletSecretType: getNetwork(value),
      }).then(() => {
        getWallets().then(() => {
          setWalletIsLoading(false);
        }).catch(() => {
          setWalletIsLoading(false);
        });
      });
    }
  };

  useEffect(() => {
    getWallets().then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (wallets.length) {
      const promises = wallets.map((wallet: WalletInterface) => getWalletBalance(wallet.id));
      Promise.all(promises).then(() => {
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }, [wallets.length]);

  return (
    <>
      {isLoading
        ? <Loader className="self-center justify-self-center flex-1" />
        : (
          <div className={`flex flex-col ${!activeWallet ? 'h-full' : 'h-auto min-h-full'} gap-9 w-full lg:w-fit self-center py-20 px-[3%] lg:px-[7%]`}>
            <div>
              <h3>Wallets</h3>
              <p className="mt-2">Use the details of each currency wallet to deposit funds.</p>
            </div>
            <div className="relative max-lg:overflow-y-hidden max-lg:ml-[-6%] max-lg:w-screen max-lg:px-[6%]">
              <Switch
                items={getCurrencyOptions(getCurrencyBalances())}
                width="w-[160px]"
                height="h-16"
                itemClass="font-semibold"
                activeItemClass="bg-[#3D6EFF33]"
                setValue={(value) => { handleWalletClick(value as CryptoCurrencyTypes); }}
                value={activeCurrency}
                seperated
              />
            </div>
            {activeWallet && !isWalletLoading && (
              <>
                <div className="flex flex-col justify-center gap-4 w-full">
                  <CopyBox label="Currency" value={activeWallet.cryptoCurrencyType} />
                  <CopyBox className="whitespace-nowrap overflow-ellipsis overflow-hidden" label="Blockchain deposit address" value={activeWallet.address} isQrCode />
                  <CopyBox label="Blockchain network" value={activeWallet.secretType} />
                </div>
                <Button className="max-w-[200px]" category={ButtonCategory.Filled} onClick={handleWithdrawClick}>Withdraw Funds</Button>
              </>
            )}
            {isWalletLoading && <Loader className="self-center justify-self-center flex-1" />}
          </div>
        )}
    </>
  );
});
