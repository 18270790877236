import { AppElement, AppFC } from '../../../interfaces';
import { StarsProps } from './types';

import { FilledStarIcon, HalfFilledStarIcon, NoFilledStarIcon } from '../icons';

export const Stars: AppFC<StarsProps> = ({
  rating,
  setRating = () => {},
  isClickable,
  className,
  gap = 0.5,
  size = 36,
  ...rest
}): AppElement => {
  const filledStarsCount = isClickable ? rating : Math.trunc(rating);
  const halfFilledStarsCount = (rating - filledStarsCount) > 0 && !isClickable ? 1 : 0;
  const nonFilledStarsCount = 5 - filledStarsCount - halfFilledStarsCount;

  const handleFilledStarClick = (index: number) => {
    (index + 1) === rating
      ? setRating(index)
      : setRating(index + 1);
  };

  return (
    <div className={`flex items-center gap-${gap} [&>button]:p-0 ${!isClickable ? '[&>button]:cursor-auto' : ''} ${className || ''}`} {...rest}>
      {[...Array(filledStarsCount)].map((item, index) => (
        <FilledStarIcon
          key={index}
          className={isClickable ? 'cursor-pointer' : ''}
          onClick={() => isClickable && handleFilledStarClick(index)}
          height={size}
          width={size}
        />
      ))}

      {!!halfFilledStarsCount && (
      <HalfFilledStarIcon
        height={size}
        width={size}
      />
      )}

      {[...Array(nonFilledStarsCount)].map((item, index) => (
        <NoFilledStarIcon
          key={index}
          className={isClickable ? 'cursor-pointer' : ''}
          onClick={() => isClickable && setRating(filledStarsCount + index + 1)}
          height={size}
          width={size}
        />
      ))}
    </div>
  );
};
