import { AppElement, AppFC } from '../../../interfaces';
import { CountryBoxProps } from './types';
import { generateCountryBox, getTradeLimitMessage } from '../../../utils';

export const CountryBox: AppFC<CountryBoxProps> = ({
  className,
  offer,
  isLimit = true,
  ...rest
}): AppElement => (
  <div {...rest} className={`flex flex-col gap-1.5 ${className || ''}`}>
    <div className="flex items-center gap-2">
      <span className="leading-none">{generateCountryBox(offer?.country)}</span>
    </div>
    {isLimit && getTradeLimitMessage(offer).value
    && (
    <h6 className="text-secondary leading-none">
      {!offer?.maxTrade || !offer?.minTrade ? `${getTradeLimitMessage(offer).title} ` : ''}
      {getTradeLimitMessage(offer).value}
    </h6>
    )}
  </div>
);
