import React, { useContext } from 'react';

import { ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { ButtonCategory, InfoCategory } from '../../../../../constants';

import { FailIcon, SuccessIcon } from '../../../../Atoms/icons';
import { Button } from '../../../../Atoms';
import { InfoProps } from './types';

export const Info: AppFC<InfoProps> = ({ category }): AppElement => {
  const { viewModal: { title, description }, updateViewModal } = useContext(ViewContext);
  const getIcon = () => {
    switch (category) {
      case InfoCategory.Error:
        return <FailIcon />;
      case InfoCategory.Success:
        return <SuccessIcon />;
      default:
        return null;
    }
  };
  return (
    <div className="flex flex-col items-center justify-center">
      {getIcon()}
      {title && <h4 className="mt-4">{title}</h4>}
      {description && <p className="mt-4 text-center">{description}</p>}
      <Button
        className="px-1 mt-8 w-60"
        category={ButtonCategory.Filled}
        onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}
      >
        OK
      </Button>
    </div>
  );
};
