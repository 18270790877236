export enum ViewModalActionTypes {
  WithdrawModal = 'WithdrawModal',
  ImageUploadModal = 'ImageUploadModal',
  QrCodeModal = 'QrCodeModal',
  DeleteModal = 'DeleteModal',
  SuccessModal = 'SuccessModal',
  FailModal = 'FailModal',
  DefaultModal = 'DefaultModal',
  Close = 'Close',
}
