import React, {
  ChangeEvent,
  useContext, useEffect, useRef, useState,
} from 'react';
import { generateClient } from 'aws-amplify/api';

import { AppElement, AppFC, UserInterface } from '../../../interfaces';
import { ChatProps } from './types';

import {
  Avatar, Button, Card, Input,
} from '../../Atoms';

import { listMessagesForRoom, getRoom } from '../../../graphql/queries';
import { createMessage } from '../../../graphql/mutations';
import { onCreateMessageByRoomId } from '../../../graphql/subscriptions';
import { Message, ModelSortDirection, Room } from '../../../ChatAPI';
import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../stores';
import { AuthToken } from '../../../services';
import { AttachmentIcon, SendIcon } from '../../Atoms/icons';
import { Paths, TradeStatusTypes } from '../../../constants';
import { useChat } from '../../../stores/hooks';

const client = generateClient();

export const Chat: AppFC<ChatProps> = ({
  className, trade, user,
}): AppElement => {
  const endOfDivRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const tokens = new AuthToken();
  const accessToken = tokens.getAccessToken();
  const inDispute = trade.tradeStatus === TradeStatusTypes.Disputed;

  const { userName, profileImage } = user as UserInterface || {};
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentRoom, setCurrentRoom] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string | File>('');
  const { profileState: { id } } = useContext(ProfileContext);
  const { updateViewModal } = useContext(ViewContext);

  const { createChatFile } = useChat();

  const handleMessageSend = (newMessage: string, fileUrl?: string) => {
    const content = { text: newMessage, imageId: fileUrl };
    if (newMessage) {
      client.graphql({
        query: createMessage,
        authToken: accessToken,
        variables: {
          input: {
            content,
            roomId: currentRoom?.id || '',
            userId: id || '',
          },
        },
      }).then(({ data }) => {
        setMessages((msg) => [...msg, data.createMessage]);
      }).catch((e) => {
        console.log('error', e);
      });
    }
  };

  // const handleRoomChange = (roomID) => {
  //   const newRoom = rooms.find((room) => room.id === roomID);
  //   setCurrentRoom(newRoom);
  //   router.push(`/rooms/${roomID}`);
  // };

  useEffect(() => {
    if (endOfDivRef.current) {
      endOfDivRef.current.scrollTop = endOfDivRef.current.scrollHeight;

      // endOfDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages.length]);

  useEffect(() => {
    client.graphql({
      query: getRoom,
      variables: {
        name: inDispute ? `${trade.id}+${id}` : trade.id,
      },
      authToken: accessToken,
    }).then(({ data }) => {
      console.log('data', data);
      // if (data.getRoom) {
      setCurrentRoom(data.getRoom);
      // }
    }).catch((e) => {
      console.log('message list error', e);
    });
  }, [user, inDispute]);

  useEffect(() => {
    if (currentRoom) {
      client.graphql({
        query: listMessagesForRoom,
        authToken: accessToken,
        variables: {
          roomId: currentRoom?.id,
          sortDirection: ModelSortDirection.ASC,
        },
      })
        .then(({ data }) => {
          setMessages(data.listMessagesForRoom?.items as Message[]);
        });

      const subscription = client.graphql({
        query: onCreateMessageByRoomId,
        authToken: accessToken,
        variables: { roomId: (currentRoom as Room).id },
      })
        .subscribe({
          next: (value) => {
            if (value.data.onCreateMessageByRoomId.userId !== id) {
              setMessages((msg) => [...msg, value.data.onCreateMessageByRoomId]);
            }
          },
        });

      return () => subscription.unsubscribe();
    }
    return () => {};
  }, [currentRoom]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const currentFile = event.target.files[0];
      setInputValue(currentFile);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handleSendClick = () => {
    // const profileImageURL = URL.createObjectURL(file as File);
    if (inputValue instanceof File) {
      const formData = new FormData();
      formData.append('chatFile', inputValue);
      setIsLoading(true);
      createChatFile(formData).then((res) => {
        console.log('data', res.headers.location);
        handleMessageSend(inputValue.name, res.headers.location as string);
        setInputValue('');
        setIsLoading(false);
      }).catch((reason) => {
        console.log('reason', reason);
        let description = '';
        const texts: string[] = reason.response.data?.errors?.file;
        if (texts) {
          messages.forEach((message) => {
            description += `${message} `;
          });
        } else {
          description = reason.response.data.detail || '';
        }
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Chat file upload failed!',
            description,
          },
        });
        setIsLoading(false);
      });
    } else {
      handleMessageSend(inputValue as string);
      setInputValue('');
    }
  };

  return (
    <div className={`flex flex-col rounded-3xl h-fit bg-lightBlack ${className || ''} `}>
      <Card className={`rounded-b-none border-chat border-b-2 w-full ${inDispute ? 'bg-[#1B1414]' : ''}`}>
        <div className="flex justify-between items-center p-6 w-full">
          <span className="flex justify-between gap-1 text-[18px] text-secondary leading-none items-center">
            {inDispute ? 'Dispute Chat with' : 'Chat with'}
            <p className="text-tertiary">{inDispute ? 'Admin' : (userName || 'Anonymous user')}</p>
          </span>
          <span className="bg-green w-3 h-3 rounded-full" />
        </div>
      </Card>
      <div ref={endOfDivRef} className={`relative flex-1 py-6 px-4 overflow-x-hidden overflow-y-auto h-[450px] max-h-[450px] ${inDispute ? 'bg-[#120D0C]' : ''}`}>
        <Card className={`p-4 ${inDispute ? 'bg-[#261D1D] text-[#5E5B5B]' : 'text-secondary '}`}>
          {!inDispute && <h5 className="text-[14px] w-full">Remember</h5>}
          {inDispute ? (
            <ul className="text-[14px] list-disc ml-8 pr-4 w-full">
              <li>
                Decisions for escrowed cryptocurrency depend on offer terms, trading activity, proof of payment, and moderator requests.
              </li>
              <li>
                While waiting, summarize the situation and provide evidence (video or PDF of payment account, transaction history).
              </li>
              <li>
                Include support recordings with account name, number, date, amount, and status.
              </li>
              <li>
                Disputes may take up to 48 hours to resolve.
              </li>
            </ul>
          ) : (
            <ul className="text-[14px] list-disc ml-8 pr-4 w-full">
              <li>
                Never pay before escrow is funded.
              </li>
              <li>
                Discuss terms and payment details this chat only
              </li>
              <li>
                Your first time? Dont hesitate to
                {' '}
                <a className="underline text-tertiary" href={Paths.Guides}>check out our guide</a>
              </li>
            </ul>
          )}
        </Card>
        <p className={`leading-none text-[12px] w-full text-center ${inDispute ? 'text-[#5E5B5B]' : 'text-secondary '} my-8`}>Your conversation starts from here.</p>
        <div className="relative flex flex-col gap-8">
          {messages.map((message) => {
            const fileName = message.content.text;
            const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.flv', '.wmv', '.webm', '.m4v', '.mpeg', '.mpg'];
            const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp', '.ico'];
            const fileExtension = fileName?.substring(fileName.lastIndexOf('.')).toLowerCase() || '';

            if (message.userId === id) {
              return (
                <div className="flex flex-col items-end gap-2" key={message.id}>
                  {message.content.imageId
                    ? (
                      <span className="underline w-[80%] flex items-center gap-2 bg-transparent rounded-xl justify-end">
                        <a href={message.content.imageId} target="_blank" className="cursor-pointer" rel="noreferrer">
                          <div className="flex flex-col gap-2 items-end">
                            {videoExtensions.includes(fileExtension)
                              && (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                              <video className="rounded-lg ring-1 ring-white/10" controls>
                                <source src={message.content.imageId} type="video/mp4" />
                              </video>
                              )}
                            {imageExtensions.includes(fileExtension) && (
                            <img
                              className="rounded-lg ring-1 ring-white/10"
                              src={message.content.imageId}
                              alt="img"
                            />
                            )}
                            <div className="flex gap-2 items-center">
                              <AttachmentIcon className="h-4 min-w-4" />
                              {message.content.text}
                            </div>
                          </div>
                        </a>
                      </span>
                    )
                    : <span className={`py-2 px-4 max-w-[300px] break-words ${inDispute ? 'bg-[#D44444]/40' : 'bg-blue'} rounded-xl`}>{message.content.text}</span>}
                </div>
              );
            }
            return (
              <div className="flex items-center gap-4">
                {!profileImage
                  ? <div className="flex items-center justify-center rounded-full h-6 w-6 bg-blue text-[12px] font-semibold">{(userName || 'Anonymous user')?.substring(0, 1).toUpperCase()}</div>
                  : <Avatar src={profileImage} size={6} />}
                <div className="flex flex-col gap-1">
                  {message.content.imageId
                    ? (
                      <span className="underline w-[50%] flex items-center gap-2 bg-transparent rounded-xl">
                        <a href={message.content.imageId} target="_blank" className="cursor-pointer" rel="noreferrer">
                          <div className="flex flex-col gap-2">
                            {videoExtensions.includes(fileExtension)
                                && (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video className="rounded-lg ring-1 ring-white/10" controls>
                                  <source src={message.content.imageId} type="video/mp4" />
                                </video>
                                )}
                            {imageExtensions.includes(fileExtension) && (
                            <img
                              className="rounded-lg ring-1 ring-white/10"
                              src={message.content.imageId}
                              alt="img"
                            />
                            )}
                            <div className="flex gap-2 items-center">
                              <AttachmentIcon className="h-4 min-w-4" />
                              {message.content.text}
                            </div>
                          </div>
                        </a>
                      </span>
                    )
                    : <span className={`py-2 px-4 rounded-xl ${inDispute ? 'bg-[#261D1D]' : 'bg-secondary'} rounded-xl`}>{message.content.text}</span>}
                  {/* <p className="text-[10px] text-secondary">Feb 2, 2023 11:42</p> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Card className={`border-chat overflow-hidden border-t-2 rounded-t-none w-full ${inDispute ? 'bg-[#1B1514]' : ''}`}>
        <div className={`flex justify-between items-center gap-6 py-4 px-6 w-full ${inDispute ? 'bg-[#120D0C]' : ''}`}>
          <Button
            className={`relative p-0 w-7 ${inDispute ? 'text-[#5E5B5B]' : 'text-[#596983]'}`}
            onClick={() => inputRef.current?.click()}
          >
            <AttachmentIcon className="h-7" />
            <input ref={inputRef} className="absolute bottom-0 opacity-0 w-full h-full -z-10 p-0 m-0" name="chatFile" type="file" accept="" onChange={handleImageChange} />
          </Button>
          <Input
            className={`flex-1 rounded-lg ${inDispute ? 'bg-[#3B292999]' : ''}`}
            placeholder="Message"
            value={(inputValue instanceof File) ? inputValue.name : inputValue}
            onChange={(event) => { setInputValue(event.target.value as string); }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSendClick();
              }
            }}
            disabled={!currentRoom}
          />
          <Button
            className={`p-0 ${inDispute ? 'text-[#5E5B5B]' : 'text-[#596983]'}`}
            onClick={() => { handleSendClick(); }}
            isLoading={isLoading}
          >
            <SendIcon />
          </Button>
        </div>
      </Card>
    </div>
  );
};
