import { Controller } from 'react-hook-form';

import { AppElement, AppFC } from '../../../interfaces';
import { FormTextAreaProps } from './types';

import { TextArea, Label } from '../../Atoms';

export const FormTextArea: AppFC<FormTextAreaProps> = ({
  label,
  control,
  error,
  name,
  className,
  children,
  ...rest
}): AppElement => (
  <Label className={className}>
    {!!label && <h6 className={`${error ? 'text-error' : ''}`}>{label}</h6>}
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextArea
          error={error}
          {...field}
          {...rest}
        />
      )}
    />
  </Label>
);
