import moment from 'moment';

export const padZero = (value: number) => (value < 10 ? `0${value}` : value);

export const convertSecondsToHMS = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = padZero(hours);
  const formattedMinutes = padZero(minutes);
  const formattedSeconds = padZero(Math.floor(remainingSeconds));

  if (seconds <= 0) {
    return '00:00';
  }
  if (hours === 0) {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const getDurationBySeconds = (startDate: string, endDate: string) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return Math.round(moment.duration(end.diff(start)).as('seconds'));
};

export const formattedDate = (date: string, pattern?: string) => moment(date).format(pattern || 'MMMM, YYYY');
