import { PaymentActionTypes } from './actions';
import { UseReducerType } from '../../interfaces';
import { PaymentActionType, PaymentInterface } from './interfaces';

export const paymentReducer: UseReducerType<PaymentInterface, PaymentActionType> = (
  state,
  action,
): PaymentInterface => {
  switch (action.type) {
    case PaymentActionTypes.UpdatePayment:
      return { ...state, methods: action.payload };
    default:
      return state;
  }
};
